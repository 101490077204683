import { Space } from "antd";
import * as React from "react";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../filter-item/FilterItem";
import type { DraggableMeasure } from "../measure-item/DraggableMeasureItem";
import { DraggableMeasureItem } from "../measure-item/DraggableMeasureItem";
import type {
  MeasureDropZone,
  MeasureDropZoneAccept,
} from "../measure-picker/DroppableMeasureZone";
import { DroppableMeasureZone } from "../measure-picker/DroppableMeasureZone";
import MeasurePicker from "../measure-picker/MeasurePicker";
import type { MeasureType } from "../measure-table/MeasureTable";

interface IMeasureListSelectorProps {
  zone: MeasureDropZone;
  accept: MeasureDropZoneAccept;
  measures?: Array<AvailableMetric | AvailableDimension>;
  max?: number;
  value?: Array<string>;
  showTimeAgg?: boolean;
  onChange?: (value: Array<string>) => any;
  maxHeight?: number;
}

const MeasureListSelector: React.FunctionComponent<
  IMeasureListSelectorProps
> = (props) => {
  const { onChange, zone, accept, max, maxHeight } = props;
  const measures = props.measures ? props.measures : [];
  const value = props.value ? props.value : [];

  const disabled = typeof max === "number" && value.length >= max;

  const availableMeasures = measures.filter(
    (measure) => !value.includes(measure.key)
  );

  const getMeasureType = (
    m: AvailableMetric | AvailableDimension
  ): MeasureType => {
    if (m?.["type"]) return "dimension";
    if (m?.["domain"]) return "dimension";
    if (m?.["formatter"]) return "metric";
    return "metric";
  };

  const isMeasureAvailable = (key: string): boolean => {
    return !!measures.find((m) => m.key === key);
  };

  const onMeasureDelete = (key: string) => {
    if (!onChange) return;
    const newValue = [...value.filter((v) => v !== key)];
    onChange(newValue);
  };

  const onMeasureAdd = (key: string, position?: number) => {
    if (!isMeasureAvailable(key) || !onChange) return;
    const newValue = [...value];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, key);
    onChange(newValue);
  };

  const onMeasureDrop = (key: string, position: number) => {
    // we prevent adding new measures if we've already reached max
    if (!value.find((v) => v === key)) {
      if (disabled) return;
    }
    if (typeof position !== "number" || !onChange) return;
    const newValue = [...value.filter((v) => v !== key)];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, key);
    onChange(newValue);
  };

  const onTimeAggChange = (timeAgg: string, key: string) => {
    // we prevent adding new measures if we've already reached max
    if (!value.find((v) => v === key)) {
      if (disabled) return;
    }
    const position = value.findIndex((v) => v === key);
    if (typeof position !== "number" || !onChange) return;
    const newValue = [...value.filter((v) => v !== key)];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(
      dropIndex,
      0,
      key
        .replace(".day", "")
        .replace(".week", "")
        .replace(".month", "")
        .replace(".year", "") + timeAgg
    );
    onChange(newValue);
  };

  return (
    <DroppableMeasureZone
      zone={zone}
      accept={accept}
      availableMeasures={disabled ? [] : availableMeasures}
      onAddMeasure={(i) => onMeasureAdd(i)}
    >
      <div
        style={
          maxHeight
            ? {
                maxHeight: maxHeight,
                overflowX: "hidden",
                overflowY: "auto",
                paddingLeft: 2,
                paddingRight: 6,
              }
            : {}
        }
      >
        <Space direction="vertical" style={{ width: "100%" }} size={2}>
          {value.map((sm, i) => {
            const measure = measures.find((measure) => {
              return (
                measure.key === sm ||
                measure.key === sm + ".day" ||
                measure.key === sm + ".week" ||
                measure.key === sm + ".month" ||
                measure.key === sm + ".year"
              );
            });
            const onDelete = () => {
              onMeasureDelete(sm);
            };
            const onDrop = (item: DraggableMeasure, position: number) => {
              onMeasureDrop(item.id, position);
            };
            return (
              <DraggableMeasureItem
                key={sm}
                id={sm}
                zone={zone}
                index={i}
                onDrop={onDrop}
                onRemove={onDelete}
                type={getMeasureType(measure)}
                isDeleted={!!!measure}
                description={measure?.description}
                name={measure?.label}
                onDelete={onDelete}
                onTimeAgg={
                  props.showTimeAgg && (measure as any)?.allowTimeAgg
                    ? (agg) => onTimeAggChange(agg, measure.key)
                    : undefined
                }
              />
            );
          })}
        </Space>
      </div>
      {value.length > 0 && <div style={{ height: 8 }} />}
      <div>
        <MeasurePicker
          availableMeasures={availableMeasures}
          selectedMeasureKeys={value}
          onMeasureChange={(i) => onMeasureAdd(i)}
          disabled={disabled}
          block
        >
          Add
        </MeasurePicker>
      </div>
    </DroppableMeasureZone>
  );
};

export default MeasureListSelector;
