import { useState } from "react";
import { useDeepCompareEffect } from "react-use";

/**
 * This hook is used to cache the value of an object and update it only when one of his property change (This prevent rerendering when only the object reference change).
 */
export const useRefCachedValue = <T extends object>(value: T) => {
  const [cachedValue, setCachedValue] = useState<T>(value);

  useDeepCompareEffect(() => setCachedValue(value), [value]);

  return cachedValue;
};
