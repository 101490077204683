import { Tag, Typography } from "antd";
import ActivityChartCellRenderer from "../../../../../../../../components/ag-grid/object-table/cells/ActivityChartCellRenderer";
import DateCellRenderer from "../../../../../../../../components/ag-grid/object-table/cells/DateCellRenderer";
import LabelCellRenderer from "../../../../../../../../components/ag-grid/object-table/cells/LabelCellRenderer";
import ProgressCellRenderer from "../../../../../../../../components/ag-grid/object-table/cells/ProgressCellRenderer";
import type { IScoreValue } from "../../../../../../../../components/ag-grid/object-table/cells/ScoreCellRenderer";
import ScoreCellRenderer from "../../../../../../../../components/ag-grid/object-table/cells/ScoreCellRenderer";
import TagCellRenderer from "../../../../../../../../components/ag-grid/object-table/cells/TagCellRenderer";
import UserInfoCellRenderer from "../../../../../../../../components/ag-grid/object-table/cells/UserInfoCellRenderer";
import { customFormatter } from "../../../../../../../../components/chart/utils/optionsHelper";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type {
  IObject,
  IObjectPropertyActivitiesFormatterConfig,
  IObjectPropertyPillsFormatterConfig,
  IObjectPropertyScoreFormatterConfig,
} from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import type { AvailableColumn } from "../../../../../object/domain";
import { parseObjectPropertyFormatter } from "../../../../../object/viewer/domain";

interface IPropertyRendererProps {
  column: AvailableColumn;
  object: IObject;
  value: string | number | boolean;
  displayImage?: boolean;
  displayNull?: boolean;
  extra?: React.ReactElement;
  timeAgg?: string;
  inline?: boolean;
  renderMetricAsTag?: boolean;
}

type Props = IPropertyRendererProps & InjectedOrgProps;

function PropertyRenderer(props: Props) {
  const {
    column,
    object,
    value,
    displayImage,
    displayNull,
    extra,
    timeAgg,
    user: { locale },
    inline,
    renderMetricAsTag,
  } = props;

  if (column.type === "metric") {
    if (
      column.data.formatter.format === "PERCENT" &&
      column.data.formatter?.customFormatting
    ) {
      return (
        <ProgressCellRenderer
          value={value as string}
          formatterConfig={column.data.formatter?.customFormatting as any}
          forceMinWidth={inline ? 250 : undefined}
        />
      );
    }
    if (renderMetricAsTag) {
      return (
        <Tag style={{ marginRight: 0 }} bordered={false}>
          <Typography.Text strong>
            {customFormatter(value, locale, column.data.formatter)}
          </Typography.Text>
        </Tag>
      );
    }
    return <>{customFormatter(value, locale, column.data.formatter)}</>;
  } else if (column.type === "property") {
    const findRenderer = () => {
      if (
        displayNull &&
        (value === null || value === undefined || value === "")
      ) {
        return <>(Blanks)</>;
      }
      if (
        !displayNull &&
        (value === null || value === undefined || value === "")
      ) {
        return null;
      }
      if (column.data.formatter && column.data.formatter === "pills") {
        const config = parseObjectPropertyFormatter(
          "pills",
          column.data.formatterConfig || ""
        ) as IObjectPropertyPillsFormatterConfig;
        return <TagCellRenderer formatterConfig={config} value={value} />;
      }
      if (column.data.formatter && column.data.formatter === "score") {
        const config = parseObjectPropertyFormatter(
          "score",
          column.data.formatterConfig || ""
        ) as IObjectPropertyScoreFormatterConfig;

        const getParsedValue = (): IScoreValue | undefined => {
          try {
            const d = JSON.parse(value as string);

            return d;
          } catch (err) {
            return undefined;
          }
        };

        return <ScoreCellRenderer value={getParsedValue()} config={config} />;
      }
      if (column.data.formatter && column.data.formatter === "activities") {
        const getParsedValue = (): {
          [key: string]: Array<{ value: number; linkTo?: string }>;
        } => {
          try {
            const d = JSON.parse(value as string);

            return d;
          } catch (err) {
            return {};
          }
        };

        const config = parseObjectPropertyFormatter(
          "activities",
          column.data.formatterConfig || ""
        ) as IObjectPropertyActivitiesFormatterConfig;

        return (
          <ActivityChartCellRenderer
            height={36}
            config={config}
            values={getParsedValue()}
          />
        );
      }
      if (column.data.domain === "TIME") {
        return <DateCellRenderer value={value as string} timeAgg={timeAgg} />;
      }
      if (
        column.data.type === "foreignKey" ||
        column.data.type === "primaryKey"
      ) {
        return (
          <LabelCellRenderer
            value={value as string}
            object={column.data.object}
            displayImage={displayImage}
          />
        );
      }
      if (column.data.type === "userKey") {
        return (
          <UserInfoCellRenderer
            value={value as string}
            object={column.data.object}
          />
        );
      }
      return <>{value}</>;
    };

    return (
      <>
        {findRenderer()}
        {extra ? extra : null}
      </>
    );
  }
  return (
    <>
      {value} {extra ? extra : null}
    </>
  );
}

export default compose<Props, IPropertyRendererProps>(WithOrg)(
  PropertyRenderer
);
