import * as React from "react";

export interface IScrollToProps {
  id: string;
  to: string;
}

export function ScrollTo(props: React.PropsWithChildren<IScrollToProps>) {
  const { children, id, to } = props;

  const scrollTo = (id: string) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return <a onClick={() => (to ? scrollTo(to) : undefined)}>{children}</a>;
}
