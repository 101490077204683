import {
  ApiOutlined,
  CodeOutlined,
  CompassOutlined,
  DatabaseOutlined,
  DeploymentUnitOutlined,
  FileMarkdownOutlined,
  LayoutOutlined,
  QuestionCircleFilled,
  ScheduleOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import * as _ from "lodash";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import dbtCloud from "../../../assets/dbt.webp";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import ExplorationCreation from "../../../components/explorations/modal/ExplorationCreation";
import { ExplorationSectionCreation } from "../../../components/explorations/section/creation/ExplorationSectionCreation";
import { Emoji } from "../../../components/form/emoji-picker/Emoji";
import WlyGlobalSearch from "../../../components/global-search/WlyGlobalSearch";
import Feednack from "../../../components/layout/feedback/feedback";
import { SplitView } from "../../../components/resizable/SplitView";
import { SourceImageRenderer } from "../../../components/sources/SourceImageRenderer";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import type { DeepPartial } from "../../../helpers/typescriptHelpers";
import type {
  IExploration,
  IExplorationSection,
} from "../../../interfaces/explorations";
import type {
  IJobExecution,
  IRunResult,
  JobExecutionRunStatus,
} from "../../../interfaces/jobExecutions";
import { JobType } from "../../../interfaces/jobExecutions";
import type { IModelFolder } from "../../../interfaces/modelFolder";
import type { IObject, IObjectLayout } from "../../../interfaces/object";
import { IOrgFeatureType } from "../../../interfaces/org";
import type { IDataset, ISource } from "../../../interfaces/sources";
import type { ITable } from "../../../interfaces/table";
import type {
  SchemaResult,
  Transformation,
} from "../../../interfaces/transformations";
import type { IView } from "../../../interfaces/view";
import type { IWorksheet } from "../../../interfaces/worksheet";
import { track } from "../../../services/AnalyticsService";
import type { WrappedTransformationResult } from "../../../services/BrizoService";
import {
  computeTransformations,
  getUserCreatedColumn,
} from "../../../services/BrizoService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { EXPLORATION_EXTRACT_INFO_FRAGMENT } from "../../../store/dataStores/workspace/WorkspaceDatastoreDomain";
import type { WorkbenchUIStoreProps } from "../../../store/workbenchUIStore";
import { generateUniqueId } from "../../../utils/uniqueId";
import { CatalogModal } from "../../catalog/CatalogModal";
import { EXPLORATION_FRAGMENT } from "../../exploration/single/domain";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";
import SourceCreation from "../../sources/creation/SourceCreation";
import type { TableTabItem } from "../../spreadsheet/domain";
import type { ViewTabItem } from "../../spreadsheet/views/ViewSelector";
import { getSourceStatus } from "../../workspace/domain";
import type { IObjectLayoutFragment } from "../single/domain";

import { gql } from "@apollo/client";
import { WlyRadarIcon } from "../../../components/icons/custom-icons/WlyRadarIcon";
import { WlyDynamicIcon } from "../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import { IconHolder } from "../../../components/icons/holder/IconHolder";
import {
  LeftMenu,
  type ILeftMenuItem,
} from "../../../components/menu/LeftMenu";
import { OBJECT_LAYOUT_FRAGMENT } from "../../../fragments/object";
import type { IRadar } from "../../../interfaces/radar";
import "./Workbench.scss";
import type {
  ExplorationStore,
  FetchedDestination,
  IActiveObject,
  IDatasetLineageGraph,
  IExplorationMeasureUsage,
  IViewData,
  ModifyQuery,
  ObjectData,
  ObjectStore,
  Store,
  TabData,
  TableData,
  UpdateDestination,
  WorksheetStore,
} from "./domain";
import {
  DEFAULT_WORKBENCH_RECORD_NUMBER,
  generateForeignKeys,
  generateJobExecutionName,
  generatePrimaryKey,
  generateRelationships,
} from "./domain";
import type { ModelFolderEditInitialData } from "./model/folder/ModelFolderEdition";
import { ModelFolderEditForm } from "./model/folder/ModelFolderEdition";
import SelectorRenderer from "./selector/SelectorRenderer";
import type {
  IOnDeleteConnector,
  IOnUpdateConnector,
} from "./selector/connectors/ConnectorsTable";
import TriggerJobModal from "./selector/jobs/TriggerJobModal";
import type { UpdateObjectLayoutFunction } from "./selector/object-layouts/domain";
import type {
  CreateObjectFunction,
  UpdateObjectFunction,
} from "./selector/object/domain";
import type {
  CreateRadarFunction,
  UpdateRadarFunction,
} from "./selector/radar/domain";
import type { DatasetSavedData } from "./selector/tables/models/AddModelModal";
import AddModelModal from "./selector/tables/models/AddModelModal";
import { generateWorksheetName } from "./selector/worksheet/WorksheetTable";
import { TabBar } from "./tabs/TabBar";
import { ViewerRenderer } from "./viewer/ViewerRenderer";
import { JobExecutionStatusRenderer } from "./viewer/job/JobStatusRenderer";
import { OBJECT_COMPLETE_FRAGMENT } from "./viewer/object/domain";
import CreateObjectModal from "./viewer/object/modals/CreateObjectModal";
import CreateRadarModal from "./viewer/radar/modals/CreateRadarModal";
import type { UpdateWorksheetData } from "./viewer/worksheet/WorksheetViewer";

interface IWorkBenchProps {
  explorationId?: string;
  datasets: IDataset[];
  destinationJobExecutions: IJobExecution[];
  sources: ISource[];
  connectors: ISource[];
  modelFolders: IModelFolder[];
  saving: (b: boolean) => void;
  onClose: () => void;
  updateQueries: (
    viewQuery: QueryItemUpdateInput,
    datasetQuery: QueryItemUpdateInput
  ) => Promise<ITable | void>;
  onCreateView?: (datasetId: string, viewName: string) => Promise<any>;
  onRenameView?: (viewId: string, viewName: string) => Promise<any>;
  onDeleteView?: (viewId: string, nextViewSlug: string) => Promise<any>;
  onCreateRelationship?: (relationshipQuery: any) => Promise<any>;
  onUpdateRelationship?: (id: string, relationshipQuery: any) => Promise<any>;
  onDeleteRelationship?: (id: string) => Promise<any>;
  onCreateDataset?: (
    datasetData: DatasetSavedData | DatasetSavedData[]
  ) => Promise<any>;

  onDeleteDataset?: (datasetId: string) => Promise<any>;
  onUpdateDataset?: (
    datasetId: string,
    a: {
      name?: string;
      description?: string;
      sql?: string;
      primaryKey?: string;
      folder?: {
        connect: {
          id: string;
        };
      };
    }
  ) => Promise<any>;
  onUpdateDrills?: (viewId: string, drills: string[]) => Promise<any>;
  onCreateSource?: (
    sourceName: string,
    sourceImage: string
  ) => Promise<{ id: string }>;
  onUpdateSource?: (
    sourceId: string,
    data: DeepPartial<ISource>
  ) => Promise<void>;
  routeRender: (params: object, query?: object) => string;
  cancelJobExecutionRun: (exec: IJobExecution) => Promise<void>;
  onRefreshJobExecutions: () => Promise<void>;
  height?: number;
  destination?: FetchedDestination;
  onDestinationUpdate?: UpdateDestination;
  explorations: IExploration[];
  explorationSections: IExplorationSection[];
  onRefreshSections: (id?: string[]) => Promise<void>;
  onRefreshExplorations: (id?: string[]) => Promise<void>;
  onRefreshDatasets: (id?: string[]) => Promise<void>;
  onCreateModelFolder: (data: ModelFolderEditInitialData) => Promise<void>;
  onUpdateModelFolder: (data: ModelFolderEditInitialData) => Promise<void>;
  onDeleteModelFolder: (id: string) => Promise<any>;
  onRefreshModelFolderTree: () => Promise<any>;
  onCreateWorksheet: () => Promise<string>;
  worksheets: IWorksheet[];
  updateWorksheetStore: UpdateWorksheetData;
  onDeleteConnector: IOnDeleteConnector;
  onUpdateConnector: IOnUpdateConnector;
  objects: IObject[];
  updateObject: UpdateObjectFunction;
  createObject: CreateObjectFunction;
  allObjectLayouts: IObjectLayoutFragment[];
  createObjectLayout: (object: IObjectLayoutFragment) => string;
  onUpdateObjectLayout: UpdateObjectLayoutFunction;
  radars: IRadar[];
  onUpdateRadar: UpdateRadarFunction;
  onCreateRadar: CreateRadarFunction;
}

export interface QueryItemUpdateInput {
  id?: string;
  data: { queryText: string };
  objectId?: string;
}

interface ISplitQueryItem {
  dataset: Transformation[];
  datasetResolver: Transformation[];
  viewResolver: Transformation[];
}

interface IState {
  data: {
    [tableKey: string]: TabData;
  };
  executions: {
    [identifier: string]: IJobExecution;
  };
  explorations: ExplorationStore;
  datasetDependecyGraph: IDatasetLineageGraph;
  tourOpen: boolean;
  scrollToColumn?: number;
  worksheetStore: WorksheetStore;
  objectStore: ObjectStore;
}

type Props = IWorkBenchProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  WorkbenchUIStoreProps &
  RouteComponentProps<{
    organizationSlug: string;
    explorationSlug: string;
    tableSlug: string;
    dependencySlug?: string;
    warehouseSlug?: string;
  }>;

class WorkBench extends React.Component<Props, IState> {
  concurrentFetch: number = 0;

  constructor(props: Props) {
    super(props);
    track("Workbench Viewed", {});
    this.state = {
      datasetDependecyGraph: this.computeGraph(props.datasets),
      data: this.computeState(props.datasets, true),
      executions: this.computeExecution(props.destinationJobExecutions),
      explorations: this.computeExplorations(
        props.explorations,
        props.explorationSections
      ),
      worksheetStore: this.computeWorksheets(props.worksheets),
      objectStore: this.computeObjects(props.objects, props.allObjectLayouts),
      tourOpen: true, // change to false
    };
  }

  computeObjects = (
    objects: IObject[],
    layouts: IObjectLayoutFragment[]
  ): { [key: string]: ObjectData } => {
    return objects.reduce<{ [key: string]: ObjectData }>((acc, object, i) => {
      const objectLayouts = (layouts || []).filter(
        (l) => l.object?.id === object.id
      );
      const existingObjectEntry = (this.state || {}).objectStore?.[object.id]
        ?.object;
      const objectData: ObjectData = {
        id: object.id,
        name: object.name,
        object:
          existingObjectEntry?.status === "success"
            ? existingObjectEntry
            : {
                status: "initial",
              },
        layouts: objectLayouts.reduce((a, l) => {
          const existingLayoutEntry = (this.state || {}).objectStore?.[
            object.id
          ]?.layouts[l.id];
          return {
            ...a,
            [l.id]: {
              id: l.id,
              name: l.name ? l.name : "Untitled",
              type: l.type,
              data:
                existingLayoutEntry?.data?.status === "success"
                  ? existingLayoutEntry?.data
                  : {
                      status: "initial",
                    },
            },
          };
        }, {}),
      };
      return {
        ...acc,
        [object.id]: objectData,
      };
    }, {} as { [key: string]: ObjectData });
  };

  componentWillUnmount(): void {
    this.setState({});
  }

  computeWorksheets = (worksheets: IWorksheet[]) => {
    return worksheets.reduce((acc, worksheet, i) => {
      return {
        ...acc,
        [worksheet.id]: {
          id: worksheet.id,
          worksheet: worksheet,
        },
      };
    }, {});
  };

  computeExplorations = (
    explorations: IExploration[],
    explorationSections: IExplorationSection[]
  ): ExplorationStore => {
    return explorations.reduce<ExplorationStore>((acc, exploration, i) => {
      return {
        ...acc,
        [exploration.id]: {
          id: exploration.id,
          name: exploration.name,
          sectionEmoji: explorationSections.find(
            (es) => es.id === exploration.section?.id
          )?.image,
          exploration: { status: "initial" },
          usage: { status: "initial" },
          tableSchema: (exploration.tables || []).reduce((acc, table) => {
            return {
              ...acc,
              [table.id]: {
                status: "initial",
              },
            };
          }, {}),
        },
      };
    }, {});
  };

  computeGraph = (
    datasets: IDataset[],
    datasetId?: string
  ): IDatasetLineageGraph => {
    const computeChildForDataset = (
      chosenDataset: IDataset,
      visitedParents: string[]
    ): IDatasetLineageGraph => {
      const allChilds = chosenDataset.dependsOn
        .filter((dep) => {
          // In some case, due to ACL checks, the current user doesn't have access to all the dependencies of the current model
          // So we filter them out from the graph for now, waiting for a better solution
          if (!dep.child?.id) {
            console.warn(
              "we have a dependsOn child dependency for datasetId=%s for which the child is either undefined or the current user doesn't have access. Skipping it.",
              chosenDataset.id
            );
          }
          return !!dep.child?.id;
        })
        .map((dep) => dep.child.id);

      return datasets
        .filter(
          (dataset) =>
            allChilds.includes(dataset.id) &&
            !visitedParents.includes(dataset.id)
        )
        .reduce<IDatasetLineageGraph>((acc, v) => {
          return {
            ...acc,
            [v.id]: computeChildForDataset(v, [...visitedParents, v.id]),
          };
        }, {});
    };
    const results = datasets
      .filter((d) => (datasetId ? d.id === datasetId : true))
      .reduce<IDatasetLineageGraph>((acc, v) => {
        return {
          ...acc,
          [v.id]: computeChildForDataset(v, [v.id]),
        };
      }, {});

    if (datasetId) {
      return results[datasetId];
    }
    return results;
  };

  computeExecution = (jobExecutions: IJobExecution[]) => {
    return jobExecutions.reduce<{ [key: string]: IJobExecution }>((acc, v) => {
      return {
        ...acc,
        [v.identifier]: v,
      };
    }, {});
  };

  computeState = (datasets: IDataset[], initial?: boolean) => {
    const state: { [tableKey: string]: TabData } = datasets.reduce<{
      [tableKey: string]: TabData;
    }>((acc, dataset, i) => {
      const datasetQuery: Transformation[] = dataset.rawQuery
        ? JSON.parse(dataset.rawQuery)
        : [];
      const currentDatasetsOperations = datasetQuery.filter(
        (q) =>
          q.domain === "datasetResolver" && q.operation.type !== "Table.Ref"
      );
      const currentDatasetResolverCursor = currentDatasetsOperations.length
        ? currentDatasetsOperations[currentDatasetsOperations.length - 1].var
        : dataset.id;
      const userDefinedColumns = getUserCreatedColumn(datasetQuery);
      const primaryKey = generatePrimaryKey(dataset);
      const currentRunResults: IRunResult[] = [];

      if (primaryKey.length === 0) {
        currentRunResults.push({
          message:
            dataset.managedBy === "WHALY"
              ? "This dataset doesn't have primary keys"
              : "Please add a uniq test in one of the column to create a primary key",
          operationName: "primary_key",
          type: "configuration",
          status: "error",
        });
      }

      const runResults = dataset.runResults
        ? JSON.parse(dataset.runResults)
        : [];
      runResults.forEach((rr) => currentRunResults.push(rr));

      const dependencyGraph = this.computeGraph(datasets, dataset.id);
      const extractedkeys: string[] = [];
      const extractDeps = (dep: IDatasetLineageGraph) => {
        const keys = Object.keys(dep);
        if (keys.length) {
          keys.map((k) => {
            extractedkeys.push(k);
            return extractDeps(dep[k]);
          });
        }
      };
      extractDeps(dependencyGraph);

      const errors = runResults.filter((rr) => rr.status !== "success");

      return {
        ...acc,
        [dataset.id]: {
          id: dataset.id,
          folder: dataset.folder,
          warehouseSchemaId: dataset.warehouseSchemaId,
          warehouseTableId: dataset.warehouseTableId,
          name: dataset.name,
          slug: dataset.slug,
          prevName: dataset.name,
          type: dataset.type,
          sql: dataset.sql,
          deleted: false,
          isModel: dataset.isModel,
          isDependency: false,
          dependencyGraph: this.computeGraph(datasets, dataset.id),
          hasUpstreamErrors: errors.length > 0,
          dataset: dataset,
          runResults: currentRunResults,
          hideFromInterface: dataset.hideFromInterface,
          head: dataset.head,
          currentStack: datasetQuery,
          currentDatasetResolverCursor: currentDatasetResolverCursor,
          datasetQueryId: dataset.query ? dataset.query.id : undefined,
          views: dataset.views ? dataset.views : [],
          userDefinedColumns: userDefinedColumns,
          foreignKeys: generateForeignKeys(dataset),
          relationships: generateRelationships(dataset, datasets),
          primaryKey: primaryKey,
          data: dataset.views.reduce<{ [key: string]: IViewData }>(
            (acc, view) => {
              return {
                ...acc,
                [view.id]: {
                  store:
                    initial ||
                    !(
                      this.state.data[dataset.id] &&
                      this.state.data[dataset.id].data[view.id] &&
                      this.state.data[dataset.id].data[view.id].store.cache
                    )
                      ? {
                          raw: {
                            status: "initial",
                          },
                        }
                      : { ...this.state.data[dataset.id].data[view.id].store },
                  viewQueryId:
                    view.query && view.query.id ? view.query.id : undefined,
                  currentStack: {
                    status: "initial",
                  },
                  currentViewResolverCursor: {
                    status: "initial",
                  },
                  name: view.name,
                  editable: view.default,
                  cubeName: view.cubeName,
                  schema:
                    initial ||
                    !(
                      this.state.data[dataset.id] &&
                      this.state.data[dataset.id].data[view.id] &&
                      this.state.data[dataset.id].data[view.id].store.cache &&
                      this.state.data[dataset.id].data[view.id].store.cache
                        ?.schema
                    )
                      ? {
                          status: "initial",
                        }
                      : {
                          status: "success",
                          data: this.state.data[dataset.id].data[view.id].store
                            .cache?.schema,
                        },
                  usedInExplorations: view.table
                    .flatMap((t) => {
                      return {
                        name: t.exploration?.name,
                        slug: t.exploration?.slug,
                      };
                    })
                    .filter((e) => !!e.name),
                  updatedBy: view.updatedBy,
                  updatedAt: view.updatedAt,
                  drills: (view.drills || dataset.primaryKey)
                    .split(",")
                    .filter((f) => f.length),
                } as IViewData,
              };
            },
            {}
          ),
        } as TabData,
      };
    }, {});

    // check if dataset has upstream error
    const updatedStateWithUpstreamError = Object.keys(state).reduce<{
      [tableKey: string]: TabData;
    }>((acc, v) => {
      return {
        ...acc,
        [v]: {
          ...state[v],
        },
      };
    }, {});

    return updatedStateWithUpstreamError;
  };

  componentDidUpdate(prevProps: Props, prevState) {
    const {
      datasets,
      destinationJobExecutions,
      explorations,
      explorationSections,
      worksheets,
      objects,
      allObjectLayouts,
    } = this.props;
    const {
      datasets: prevDatasets,
      destinationJobExecutions: prevDestinationJobExecutions,
      explorations: prevExplorations,
      explorationSections: prevExplorationSections,
      worksheets: prevWorksheets,
      objects: prevObjects,
      allObjectLayouts: prevObjectLayouts,
    } = prevProps;

    if (!_.isEqual(datasets, prevDatasets)) {
      this.setState({
        data: {
          ...this.computeState(datasets),
        },
      });
    }

    if (!_.isEqual(destinationJobExecutions, prevDestinationJobExecutions)) {
      this.setState({
        executions: {
          ...this.computeExecution(destinationJobExecutions),
        },
      });
    }

    if (!_.isEqual(explorations, prevExplorations)) {
      this.setState({
        explorations: {
          ...this.computeExplorations(explorations, explorationSections),
        },
      });
    }

    if (!_.isEqual(datasets, prevDatasets)) {
      this.setState({
        datasetDependecyGraph: this.computeGraph(datasets),
      });
    }

    if (!_.isEqual(worksheets, prevWorksheets)) {
      this.setState({
        worksheetStore: this.computeWorksheets(worksheets),
      });
    }

    if (
      !_.isEqual(objects, prevObjects) ||
      !_.isEqual(prevObjectLayouts, allObjectLayouts)
    ) {
      this.setState({
        objectStore: this.computeObjects(objects, allObjectLayouts),
      });
    }
  }

  resetDatasetCache = (datasetId: string) => {
    this.setState({
      data: {
        ...this.state.data,
        [datasetId]: {
          ...this.state.data[datasetId],
          data: Object.keys(this.state.data[datasetId].data).reduce<{
            [viewId: string]: IViewData;
          }>((acc, v) => {
            return {
              ...acc,
              [v]: {
                ...this.state.data[datasetId].data[v],
                store: {
                  ...this.state.data[datasetId].data[v].store,
                  raw: { status: "initial" },
                  cache: undefined,
                },
              },
            };
          }, {}),
        },
      },
    });
  };

  getTables = () => {
    return Object.keys(this.state.data).filter(
      (k) => this.state.data[k].deleted === false
    );
  };

  public fetchData = async (
    warehouseId: string,
    activeDatasetId: string,
    activeViewId: string,
    increment?: boolean,
    fullReload?: boolean
  ) => {
    if (increment) {
      this.concurrentFetch += 1;
    }

    const result = await GraphQLService<{
      View: IView;
    }>(
      `
    query GetViewRawQuery($viewId:ID!) {
      View(where: {id: $viewId}) {
        rawQuery
      }
    }
    `,
      { viewId: activeViewId }
    );
    const query = result.View.rawQuery ? JSON.parse(result.View.rawQuery) : [];
    const currentViewsOperation = query.filter(
      (q) => q.domain === "viewResolver"
    );
    const currentViewResolverCursor = currentViewsOperation.length
      ? currentViewsOperation[currentViewsOperation.length - 1].var
      : "head";

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [activeDatasetId]: {
          ...this.state.data[activeDatasetId],
          data: {
            ...this.state.data[activeDatasetId].data,
            [activeViewId]: {
              ...this.state.data[activeDatasetId].data[activeViewId],
              currentStack: { status: "success", data: query },
              currentViewResolverCursor: {
                status: "success",
                data: currentViewResolverCursor,
              },
              store: {
                ...this.state.data[activeDatasetId].data[activeViewId].store,
                raw: {
                  status: "loading",
                },
                cache: fullReload
                  ? undefined
                  : {
                      ...this.state.data[activeDatasetId].data[activeViewId]
                        .store.cache,
                    },
              },
              schema: {
                status: "loading",
              },
            },
          },
        },
      },
    });

    const head = this.state.data[activeDatasetId]?.head;
    return computeTransformations(warehouseId, {
      records: [
        ...query,
        {
          var: generateUniqueId(),
          operation: {
            type: "Table.FirstN",
            args: {
              table: head ? head : query[query.length - 1].var,
              countOrCondition: DEFAULT_WORKBENCH_RECORD_NUMBER,
            },
          },
          domain: "viewResolver",
        } as any,
      ],
      count: [
        ...query,
        {
          operation: {
            type: "Table.RowCount",
            args: {
              table: head ? head : query[query.length - 1].var,
            },
          },
          var: generateUniqueId(),
          domain: "viewResolver",
        },
      ],
      schema: [
        ...query,
        {
          var: generateUniqueId(),
          operation: {
            type: "Table.Schema",
            args: {
              table: head ? head : query[query.length - 1].var,
            },
          },
          domain: "viewResolver",
        },
      ],
    })
      .then((r) => {
        if (increment && this.concurrentFetch > 1) {
          this.concurrentFetch -= 1;
          return;
        }
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            [activeDatasetId]: {
              ...this.state.data[activeDatasetId],
              data: {
                ...this.state.data[activeDatasetId].data,
                [activeViewId]: {
                  ...this.state.data[activeDatasetId].data[activeViewId],
                  currentStack: { status: "success", data: query },
                  currentViewResolverCursor: {
                    status: "success",
                    data: currentViewResolverCursor,
                  },
                  store: {
                    ...this.state.data[activeDatasetId].data[activeViewId]
                      .store,
                    raw: {
                      status: "success",
                      data: {
                        schema: r.data.schema as SchemaResult,
                        list: r.data.records as any,
                        rowCount:
                          r.data.count &&
                          (r.data.count as number) &&
                          typeof r.data.count === "number"
                            ? r.data.count
                            : (0 as any),
                      },
                    },
                    cache: {
                      schema: r.data.schema as SchemaResult,
                      list: r.data.records as any,
                      rowCount:
                        r.data.count &&
                        (r.data.count as number) &&
                        typeof r.data.count === "number"
                          ? r.data.count
                          : (0 as any),
                    },
                  },
                  schema: {
                    status: "success",
                    data: r.data.schema as SchemaResult,
                  },
                },
              },
            } as TabData,
          },
        });
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            [activeDatasetId]: {
              ...this.state.data[activeDatasetId],
              data: {
                ...this.state.data[activeDatasetId].data,
                [activeViewId]: {
                  ...this.state.data[activeDatasetId].data[activeViewId],
                  currentStack: { status: "success", data: query },
                  currentViewResolverCursor: {
                    status: "success",
                    data: currentViewResolverCursor,
                  },
                  store: {
                    ...this.state.data[activeDatasetId].data[activeViewId]
                      .store,
                    raw: {
                      status: "error",
                      error: new Error(err),
                    },
                  },
                  schema: {
                    status: "error",
                    error: err,
                  },
                },
              },
            },
          },
        });
      });
  };

  public onModifyTransformation =
    (activeDataset: string, activeView: string): ModifyQuery =>
    async (
      prev: Transformation[],
      curr: Transformation[],
      reloadView?: boolean,
      scrollTo?: string,
      isFlow?: boolean
    ) => {
      if (_.isEqual(prev, curr)) {
        return;
      }

      // when is flow we trust the mashup as it needs to be loaded in order to validate it so we can save it right away

      // step 0 we take the current stack split it into chunks from their origin, then modify the head value with the latest operation
      // for datasetResolver, finally we merge the three chunks together as they will be in the backend
      const updatedChunks = this.rebaseHead(curr);
      const newCurrentQuery = this.mergeHead(updatedChunks);

      // if we modify the underlying dataset we should refresh all views cache
      const shouldDumpCache = !_.isEqual(
        prev.filter((p) => p.domain === "datasetResolver"),
        curr.filter((c) => c.domain === "datasetResolver")
      );

      // step 1 - infer what next schema is and update the cache
      const newSchema = await this.predictNextSchema(
        activeDataset,
        activeView,
        prev,
        newCurrentQuery,
        reloadView,
        scrollTo
      );

      // should we scroll to the newly created column ? yes if it is a roll up or a add column operation
      if (newSchema && scrollTo) {
        const columnIndex = Object.keys(newSchema).findIndex(
          (k) => scrollTo === k
        );
        this.setState(
          {
            scrollToColumn: columnIndex,
          },
          () =>
            setTimeout(() => {
              return this.setState({ scrollToColumn: undefined });
            }, 10)
        );
      }

      const currentWarehouse = getCurrentWarehouse(
        this.props.org,
        this.props.match.params.warehouseSlug
      );

      // step 2 - update the cache with the newly defined schema
      // when the raw data is loaded we copy the raw data in the cache and apply the modified schema
      // when the raw data is loading we assume that the cache is up to date and modify only the schema
      // when the raw data is in error we don't update the cache

      const updateCacheFromStore = (
        d: Store,
        ps: SchemaResult | undefined
      ): TableData | undefined => {
        if (d.raw.status === "success") {
          return { ...d.raw.data, schema: ps ? ps : d.raw.data.schema };
        } else if (d.raw.status === "initial") {
          return undefined;
        } else if (d.raw.status === "loading") {
          return d.cache
            ? { ...d.cache, schema: ps ? ps : d.cache.schema }
            : undefined;
        } else if (d.raw.status === "error") {
          return undefined;
        }
      };

      const getNewCache = (): { [key: string]: IViewData } => {
        if (shouldDumpCache) {
          return {
            ...Object.keys(this.state.data[activeDataset].data).reduce<{
              [key: string]: IViewData;
            }>((acc, viewId) => {
              return {
                ...acc,
                [viewId]: {
                  ...this.state.data[activeDataset].data[viewId],
                  currentStack:
                    viewId === activeView
                      ? { status: "success", data: newCurrentQuery }
                      : { status: "initial" },
                  store:
                    viewId === activeView
                      ? {
                          ...this.state.data[activeDataset].data[activeView]
                            .store,
                          cache: updateCacheFromStore(
                            this.state.data[activeDataset].data[activeView]
                              .store,
                            newSchema
                          ),
                          raw: this.state.data[activeDataset]
                            ? this.state.data[activeDataset].data[activeView]
                                .store.raw
                            : { status: "initial" },
                        }
                      : {
                          cache: undefined,
                          raw: {
                            status: "initial",
                          },
                        },
                },
              };
            }, {}),
          };
        } else {
          return {
            ...this.state.data[activeDataset].data,
            [activeView]: {
              ...this.state.data[activeDataset].data[activeView],
              currentStack: { status: "success", data: newCurrentQuery },
              store: {
                ...this.state.data[activeDataset].data[activeView].store,
                cache: updateCacheFromStore(
                  this.state.data[activeDataset].data[activeView].store,
                  newSchema
                ),
                raw: this.state.data[activeDataset]
                  ? this.state.data[activeDataset].data[activeView].store.raw
                  : { status: "initial" },
              },
            },
          };
        }
      };
      const newCache = getNewCache();

      this.setState(
        {
          ...this.state,
          data: {
            ...this.state.data,
            [activeDataset!]: {
              ...this.state.data[activeDataset],
              data: newCache,
            },
          },
        },
        () => {
          // we update the user that has changed the query before fetching the whole data
          // step 3 - defer fetch data to update the raw source from the server and update the cache
          GraphQLService(
            `
      mutation updateViewTimestamp($id: ID!) {
        updateView(id: $id, data: {}) {
          id
        }
      }
      `,
            {
              id: activeView,
            }
          ).then(() => {
            return this.fetchData(
              currentWarehouse.id,
              activeDataset,
              activeView
            );
          });
          // step 4 - save
          this.props.updateQueries(
            {
              id: this.state.data[activeDataset].data[activeView].viewQueryId,
              data: {
                queryText: isFlow
                  ? JSON.stringify([])
                  : JSON.stringify(updatedChunks.viewResolver),
              },
              objectId: activeView,
            },
            {
              id: this.state.data[activeDataset].datasetQueryId,
              data: {
                queryText: isFlow
                  ? JSON.stringify(curr)
                  : JSON.stringify(updatedChunks.datasetResolver),
              },
              objectId: activeDataset,
            }
          );
        }
      );
    };

  public splitTransformation = (curr: Transformation[]): ISplitQueryItem => {
    const datasetBaseQuery = curr.filter((c) => c.domain === "dataset");
    const viewQuery = curr.filter((c) => c.domain === "viewResolver");
    const datasetQuery = curr.filter(
      (c) => c.domain === "datasetResolver" && c.var !== "head"
    );

    return {
      dataset: datasetBaseQuery,
      datasetResolver: datasetQuery,
      viewResolver: viewQuery,
    };
  };

  public rebaseHead = (curr: Transformation[]): ISplitQueryItem => {
    const transformations = this.splitTransformation(curr);
    const datasetQuery = [...transformations.datasetResolver];

    if (
      transformations.dataset.length &&
      transformations.datasetResolver.length === 0
    ) {
      // if no dataset query add head reference
      datasetQuery.push({
        var: "head",
        operation: {
          type: "Table.Ref",
          args: {
            table:
              transformations.dataset[transformations.dataset.length - 1].var,
          },
        },
        domain: "datasetResolver",
      });
    } else if (datasetQuery.length > 0) {
      // if dataset query remove head and update
      datasetQuery.push({
        var: "head",
        operation: {
          type: "Table.Ref",
          args: {
            table: datasetQuery[datasetQuery.length - 1].var,
          },
        },
        domain: "datasetResolver",
      });
    }

    return {
      dataset: transformations.dataset,
      datasetResolver: datasetQuery,
      viewResolver: transformations.viewResolver,
    };
  };

  public mergeHead = (transformations: ISplitQueryItem): Transformation[] => {
    return [
      ...transformations.dataset,
      ...transformations.datasetResolver,
      ...transformations.viewResolver,
    ];
  };

  public predictNextSchema = async (
    activeDataset: string,
    activeView: string,
    prev: Transformation[],
    curr: Transformation[],
    reloadView?: boolean,
    reloadColumn?: string
  ): Promise<SchemaResult | undefined> => {
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );

    const currSchemaData =
      this.state.data[activeDataset].data[activeView].store;

    if (
      currentWarehouse &&
      currSchemaData &&
      currSchemaData.cache &&
      currSchemaData.cache.schema
    ) {
      const schemaRes = await computeTransformations(currentWarehouse.id, {
        predictedSchema: [
          ...curr,
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table: curr[curr.length - 1].var,
              },
            },
            domain: "viewResolver",
          },
        ],
      });

      const nextSchema = schemaRes.data.predictedSchema as SchemaResult;

      if (reloadColumn) {
        nextSchema[reloadColumn].loading = true;
      }

      Object.keys(nextSchema).forEach((nk) => {
        if (
          reloadView ||
          (currSchemaData &&
            currSchemaData.cache &&
            currSchemaData.cache.schema &&
            !currSchemaData.cache.schema[nk])
        ) {
          nextSchema[nk].loading = true;
        }
      });

      return nextSchema;
    }
    return;
  };

  setCurrentObject = (activeObject: IActiveObject) => {
    this.props.workbenchUIStore.setActiveObjectFocused(activeObject);
  };

  updateExploration = async (
    explorationId: string,
    data: DeepPartial<IExploration>
  ) => {
    this.setState({
      explorations: {
        ...this.state.explorations,
        [explorationId]: {
          ...this.state.explorations[explorationId],
          exploration: {
            ...this.state.explorations[explorationId].exploration,
            status: "loading",
          },
        },
      },
    });
    try {
      const explorationContent = await GraphQLService<{
        updateExploration: IExploration;
      }>(
        `
        ${EXPLORATION_FRAGMENT}
        ${EXPLORATION_EXTRACT_INFO_FRAGMENT}

        mutation updateCurrentExploration($id: ID!, $data: ExplorationUpdateInput!) {
          updateExploration(id: $id, data: $data) {
            ...ExplorationQuery
            ...ExplorationExtractInfo
          }
        }
      `,
        {
          id: explorationId,
          data,
        }
      );
      this.setState({
        explorations: {
          ...this.state.explorations,
          [explorationId]: {
            ...this.state.explorations[explorationId],
            exploration: {
              ...this.state.explorations[explorationId].exploration,
              status: "success",
              data: explorationContent.updateExploration,
              cache: explorationContent.updateExploration,
            },
          },
        },
      });
    } catch (err) {
      console.error(err);
      this.setState({
        explorations: {
          ...this.state.explorations,
          [explorationId]: {
            ...this.state.explorations[explorationId],
            exploration: {
              ...this.state.explorations[explorationId].exploration,
              status: "error",
              error: err,
            },
          },
        },
      });
    }
  };

  runDataExtract = async (explorationId: string) => {
    try {
      const { onRefreshJobExecutions } = this.props;
      const dataExtractRes = await GraphQLService<{
        extractExplorationDataNow: boolean;
      }>(
        `
        mutation runExplorationDataExtractNow($explorationId: ID!) {
          extractExplorationDataNow(explorationId: $explorationId)
        }
      `,
        {
          explorationId,
        }
      );
      if (dataExtractRes.extractExplorationDataNow === false) {
        message.error(
          `Couldn't launch the extract, please retry or reach out to support if it persists.`
        );
        return;
      }
      // Refresh the exploration to get Schedule status updated
      await this.fetchExploration(explorationId);
      await onRefreshJobExecutions();
    } catch (err) {
      console.error(err);
    }
  };

  fetchObject = async (objectId: string) => {
    this.setState({
      objectStore: {
        ...this.state.objectStore,
        [objectId]: {
          ...this.state.objectStore[objectId],
          object: {
            ...this.state.objectStore[objectId].object,
            status: "loading",
          },
        },
      },
    });
    try {
      const objectContent = await GraphQLService<{
        Object: IObject;
      }>(
        gql`
          ${OBJECT_COMPLETE_FRAGMENT}

          query getCurrentObject($objectId: ID!) {
            Object(where: { id: $objectId }) {
              ...ObjectQuery
            }
          }
        `,
        {
          objectId,
        }
      );

      this.setState({
        objectStore: {
          ...this.state.objectStore,
          [objectId]: {
            ...this.state.objectStore[objectId],
            object: {
              ...this.state.objectStore[objectId].object,
              status: "success",
              data: objectContent.Object,
              cache: objectContent.Object,
            },
          },
        },
      });
    } catch (err) {
      console.error(err);
      this.setState({
        objectStore: {
          ...this.state.objectStore,
          [objectId]: {
            ...this.state.objectStore[objectId],
            object: {
              ...this.state.objectStore[objectId].object,
              status: "error",
              error: err,
            },
          },
        },
      });
    }
  };

  fetchObjectLayout = async (objectId: string, layoutId: string) => {
    this.setState({
      objectStore: {
        ...this.state.objectStore,
        [objectId]: {
          ...this.state.objectStore[objectId],
          layouts: {
            ...this.state.objectStore[objectId].layouts,
            [layoutId]: {
              ...this.state.objectStore[objectId].layouts[layoutId],
              data: {
                status: "loading",
              },
            },
          },
        },
      },
    });
    try {
      const objectContent = await GraphQLService<{
        ObjectLayout: IObjectLayout;
      }>(
        gql`
          ${OBJECT_LAYOUT_FRAGMENT}

          query getCurrentObjectLayout($layoutId: ID!) {
            ObjectLayout(where: { id: $layoutId }) {
              ...ObjectLayoutFragment
            }
          }
        `,
        {
          layoutId,
        }
      );
      this.setState({
        objectStore: {
          ...this.state.objectStore,
          [objectId]: {
            ...this.state.objectStore[objectId],
            layouts: {
              ...this.state.objectStore[objectId].layouts,
              [layoutId]: {
                ...this.state.objectStore[objectId].layouts[layoutId],
                data: {
                  status: "success",
                  data: objectContent.ObjectLayout,
                },
              },
            },
          },
        },
      });
    } catch (err) {
      console.error(err);
      this.setState({
        objectStore: {
          ...this.state.objectStore,
          [objectId]: {
            ...this.state.objectStore[objectId],
            layouts: {
              ...this.state.objectStore[objectId].layouts,
              [layoutId]: {
                ...this.state.objectStore[objectId].layouts[layoutId],
                data: {
                  status: "error",
                  error: err,
                },
              },
            },
          },
        },
      });
    }
  };

  fetchExploration = async (explorationId: string) => {
    this.setState({
      explorations: {
        ...this.state.explorations,
        [explorationId]: {
          ...this.state.explorations[explorationId],
          exploration: {
            ...this.state.explorations[explorationId].exploration,
            status: "loading",
          },
          usage: {
            ...this.state.explorations[explorationId].usage,
            status: "loading",
          },
        },
      },
    });
    try {
      const explorationContent = await GraphQLService<{
        Exploration: IExploration;
        getExplorationMeasuresUsage: IExplorationMeasureUsage;
      }>(
        `
        ${EXPLORATION_FRAGMENT}
        ${EXPLORATION_EXTRACT_INFO_FRAGMENT}

        query getCurrentExploration($explorationId: ID!) {
          Exploration(where: {id: $explorationId}) {
            ...ExplorationQuery
            ...ExplorationExtractInfo
          }
        
          getExplorationMeasuresUsage(explorationId: $explorationId) {
            dimensions {
              resourceId
              foreignResourceId
              reportSlug
              reportName
              reportType
              usageType
            }
            metrics {
              resourceId
              foreignResourceId
              reportSlug
              reportName
              reportType
              usageType
            }
          }
        }
      `,
        {
          explorationId,
        }
      );
      this.setState({
        explorations: {
          ...this.state.explorations,
          [explorationId]: {
            ...this.state.explorations[explorationId],
            exploration: {
              ...this.state.explorations[explorationId].exploration,
              status: "success",
              data: explorationContent.Exploration,
              cache: explorationContent.Exploration,
            },
            usage: {
              ...this.state.explorations[explorationId].usage,
              status: "success",
              data: explorationContent.getExplorationMeasuresUsage,
            },
          },
        },
      });
    } catch (err) {
      console.error(err);
      this.setState({
        explorations: {
          ...this.state.explorations,
          [explorationId]: {
            ...this.state.explorations[explorationId],
            exploration: {
              ...this.state.explorations[explorationId].exploration,
              status: "error",
              error: err,
            },
            usage: {
              ...this.state.explorations[explorationId].usage,
              status: "error",
              error: err,
            },
          },
        },
      });
    }
  };

  fetchTableSchema = async (
    tablesSchemas: [
      {
        datasetId: string;
        viewId: string;
        blendedDatasetId?: string;
      }
    ]
  ): Promise<SchemaResult[]> => {
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );

    this.setState({
      data: {
        ...this.state.data,
        ...tablesSchemas.reduce((obj, item) => {
          return {
            ...obj,
            [item.datasetId]: {
              ...this.state.data[item.datasetId],
              data: {
                ...this.state.data[item.datasetId].data,
                [item.viewId]: {
                  ...this.state.data[item.datasetId].data[item.viewId],
                  schema: {
                    status: "loading",
                  },
                },
              },
            },
          };
        }, {}),
      },
    });
    try {
      const schemaResults = await Promise.allSettled(
        tablesSchemas.map((ts) => {
          const getMashupTransformation = (): Transformation[] => {
            if (ts.blendedDatasetId) {
              return [
                {
                  var: "base",
                  domain: "datasetResolver",
                  operation: {
                    type: "Table.FromWhalyDataset",
                    args: {
                      datasetId: ts.datasetId,
                      viewId: ts.viewId,
                    },
                  },
                },
                {
                  var: "blended",
                  domain: "datasetResolver",
                  operation: {
                    type: "Table.FromWhalyDataset",
                    args: {
                      datasetId: ts.blendedDatasetId,
                    },
                  },
                },
                {
                  var: "combined",
                  domain: "datasetResolver",
                  operation: {
                    type: "Table.Combine",
                    args: {
                      table1: "base",
                      table2: "blended",
                      columns: "*",
                    },
                  },
                },
                {
                  var: "schema",
                  domain: "datasetResolver",
                  operation: {
                    type: "Table.Schema",
                    args: {
                      table: "combined",
                    },
                  },
                },
              ];
            } else {
              return [
                {
                  var: "base",
                  domain: "datasetResolver",
                  operation: {
                    type: "Table.FromWhalyDataset",
                    args: {
                      datasetId: ts.datasetId,
                      viewId: ts.viewId,
                    },
                  },
                },
                {
                  var: "schema",
                  domain: "datasetResolver",
                  operation: {
                    type: "Table.Schema",
                    args: {
                      table: "base",
                    },
                  },
                },
              ];
            }
          };
          return computeTransformations(currentWarehouse.id, {
            tableSchema: getMashupTransformation(),
          });
        })
      );

      this.setState({
        data: {
          ...this.state.data,
          ...tablesSchemas.reduce((obj, item, index) => {
            return {
              ...obj,
              [item.datasetId]: {
                ...this.state.data[item.datasetId],
                data: {
                  ...this.state.data[item.datasetId].data,
                  [item.viewId]: {
                    ...this.state.data[item.datasetId].data[item.viewId],
                    schema:
                      schemaResults[index].status === "fulfilled"
                        ? {
                            status: "success",
                            data: (
                              schemaResults[
                                index
                              ] as PromiseFulfilledResult<WrappedTransformationResult>
                            ).value.data.tableSchema,
                          }
                        : {
                            status: "error",
                          },
                  },
                },
              },
            };
          }, {}),
        },
      });
      return schemaResults.map((ts) => {
        if (ts.status === "fulfilled") {
          return ts.value.data.tableSchema;
        } else {
          return null;
        }
      }) as SchemaResult[];
    } catch (err) {
      console.error(err);
      this.setState({
        data: {
          ...this.state.data,
          ...tablesSchemas.reduce((obj, item, index) => {
            return {
              ...obj,
              [item.datasetId]: {
                ...this.state.data[item.datasetId],
                data: {
                  ...this.state.data[item.datasetId].data,
                  [item.viewId]: {
                    ...this.state.data[item.datasetId].data[item.viewId],
                    schema: {
                      status: "error",
                      error: err,
                    },
                  },
                },
              },
            };
          }, {}),
        },
      });
    }
  };

  public render() {
    const {
      onCreateView,
      onDeleteView,
      onRenameView,
      onCreateRelationship,
      onUpdateRelationship,
      onDeleteRelationship,
      onUpdateDrills,
      onCreateDataset,
      onDeleteDataset,
      onUpdateDataset,
      onCreateSource,
      sources,
      connectors,
      onUpdateSource,
      routeRender,
      destinationJobExecutions,
      datasets,
      orgFeatures,
      workbenchUIStore,
      destination,
      onRefreshJobExecutions,
      cancelJobExecutionRun,
      onDestinationUpdate,
      onRefreshExplorations,
      onRefreshSections,
      antUtils,
      onRefreshDatasets,
      onCreateModelFolder,
      onUpdateModelFolder,
      modelFolders,
      onDeleteModelFolder,
      onRefreshModelFolderTree,
      onCreateWorksheet,
      worksheets,
      updateWorksheetStore,
      onDeleteConnector,
      onUpdateConnector,
      objects,
      createObject,
      updateObject,
      org,
      createObjectLayout,
      onUpdateObjectLayout,
      radars,
      onUpdateRadar,
      onCreateRadar,
    } = this.props;

    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );

    if (!destination) {
      return <Feednack>You should have a warehouse connected</Feednack>;
    }

    const tables = this.getTables();

    const mainTabs: TableTabItem[] = tables
      .filter((k) => {
        return !this.state.data[k].isDependency;
      })
      .map((k) => ({
        key: k,
        folder: this.state.data[k].folder,
        label: this.state.data[k].name,
        query: this.state.data[k].currentStack,
        primaryKey: this.state.data[k].primaryKey,
        foreignKeys: this.state.data[k].foreignKeys,
        isModel: this.state.data[k].isModel,
        relationships: this.state.data[k].relationships,
        baseDatasetId: this.state.data[k].id,
        userDefinedColumns: this.state.data[k].userDefinedColumns,
        runResults: this.state.data[k].runResults,
        hideFromInterface: this.state.data[k].hideFromInterface,
        hasUpstreamError: this.state.data[k].hasUpstreamErrors,
        baseSourceId: this.state.data[k].dataset.source
          ? this.state.data[k].dataset.source.id
          : undefined,
        managedBy: this.state.data[k].dataset.managedBy,
        datasetQueryCursor: this.state.data[k].currentDatasetResolverCursor,
        isSQL: this.state.data[k].dataset.type === "SQL",
        dataset: this.state.data[k].dataset,
        views: Object.keys(this.state.data[k].data).map<ViewTabItem>((vk) => {
          return {
            key: vk,
            label: this.state.data[k].data[vk].name,
            editable: this.state.data[k].data[vk].editable,
            viewCubeName: this.state.data[k].data[vk].cubeName,
            usedInExplorations: this.state.data[k].data[vk].usedInExplorations,
            updatedBy: this.state.data[k].data[vk].updatedBy,
            updatedAt: this.state.data[k].data[vk].updatedAt,
            drills: this.state.data[k].data[vk].drills,
          };
        }),
      }));

    const getBadgeStatus = (
      jobStatus?: JobExecutionRunStatus
    ): "success" | "error" | "warning" | "processing" | "default" => {
      switch (jobStatus) {
        case "ERROR":
          return "error";
        case "FAILED":
          return "warning";
        case "SUCCESSED":
          return "success";
        case "RUNNING":
          return "success";
        default:
          return "default";
      }
    };

    const leftMenuItems: ILeftMenuItem[] = [
      {
        key: "explorations",
        label: "Explorations",
        icon: <CompassOutlined />,
        active: workbenchUIStore.selector === "exploration",
        onClick: () => {
          const isActive = workbenchUIStore.selector === "exploration";
          workbenchUIStore.setSelector(isActive ? undefined : "exploration");
        },
      },
      {
        hidden: !orgFeatures.includes(IOrgFeatureType.OBJECTS),
        key: "objects",
        label: "Objects",
        icon: <DeploymentUnitOutlined />,
        active: workbenchUIStore.selector === "object",
        onClick: () => {
          const isActive = workbenchUIStore.selector === "object";
          workbenchUIStore.setSelector(isActive ? undefined : "object");
        },
        hasDivider: true,
      },
      {
        hidden:
          !orgFeatures.includes(IOrgFeatureType.OBJECTS) ||
          !destination?.isSignalEngineEnabled,
        key: "radars",
        label: "Radars",
        icon: (
          <>
            <WlyRadarIcon />
          </>
        ),
        active: workbenchUIStore.selector === "radar",
        onClick: () => {
          const isActive = workbenchUIStore.selector === "radar";
          workbenchUIStore.setSelector(isActive ? undefined : "radar");
        },
      },
      {
        key: "data",
        label: "Datasets",
        icon: <DatabaseOutlined />,
        active: workbenchUIStore.selector === "data",
        onClick: () => {
          const isActive = workbenchUIStore.selector === "data";
          workbenchUIStore.setSelector(isActive ? undefined : "data");
        },
        hasDivider: true,
      },
      {
        key: "worksheet",
        label: "Worksheet",
        icon: <CodeOutlined />,
        active: workbenchUIStore.selector === "worksheet",
        onClick: () => {
          const isActive = workbenchUIStore.selector === "worksheet";
          workbenchUIStore.setSelector(isActive ? undefined : "worksheet");
        },
      },
      {
        hidden: !currentWarehouse?.isDataLoadingEnabled,
        key: "connector",
        label: "Connectors",
        icon: <ApiOutlined />,
        active: workbenchUIStore.selector === "connector",
        onClick: () => {
          const isActive = workbenchUIStore.selector === "connector";
          workbenchUIStore.setSelector(isActive ? undefined : "connector");
        },
        hasDivider: true,
        badge: {
          dot: connectors.some((source) =>
            ["late", "error"].includes(getSourceStatus(source))
          ),
        },
      },
      {
        hidden: !destination.isPersistenceEngineEnabled,
        key: "persistenceEngine",
        label: "Persistence Engine",
        icon: <ScheduleOutlined />,
        active: workbenchUIStore.selector === "persistenceEngine",
        onClick: () => {
          const isActive = workbenchUIStore.selector === "persistenceEngine";
          workbenchUIStore.setSelector(
            isActive ? undefined : "persistenceEngine"
          );
        },
        badge: {
          status: getBadgeStatus(
            destination.persistenceEngineLastJobExecution?.status
          ),
          dot: true,
        },
      },
      {
        hidden: !destination.isDbtCloudSyncEnabled,
        key: "dbtCloudSync",
        label: "dbt Cloud Sync",
        icon: (
          <img
            alt=""
            src="https://cdn.whaly.io/modelling_engines/dbt.png"
            style={{
              height: 16,
              width: 16,
              filter: "grayscale(1)",
            }}
          />
        ),
        active: workbenchUIStore.selector === "dbtCloudSync",
        onClick: () => {
          const isActive = workbenchUIStore.selector === "dbtCloudSync";
          workbenchUIStore.setSelector(isActive ? undefined : "dbtCloudSync");
        },
        badge: {
          status: getBadgeStatus(
            destination.dbtCloudSyncLastJobExecution?.status
          ),
          dot: true,
        },
      },
    ];

    const leftMenuBottomItems: ILeftMenuItem[] = [
      {
        key: "searchWorkbench",
        label: "Search the workbench",
        icon: <SearchOutlined />,
        onClick: () => workbenchUIStore.setGlobalSearchOpen(true),
      },
      {
        key: "warehouseSettings",
        label: "Warehouse Settings",
        icon: <SettingOutlined />,
        onClick: () =>
          workbenchUIStore.pushActiveObject({
            type: "settings",
            value: "",
          }),
      },
    ];

    return (
      <div
        className="workbench"
        style={
          this.props.height
            ? {
                height: this.props.height,
              }
            : undefined
        }
      >
        <div className="workbench-inner">
          <LeftMenu
            items={leftMenuItems}
            bottomItems={leftMenuBottomItems}
            data-tour-wb-toolbar
          />

          <SplitView
            compact={true}
            collapsed={!workbenchUIStore.selector}
            collapsedWidth={0}
            left={
              <SelectorRenderer
                radars={radars}
                onUpdateRadar={onUpdateRadar}
                onUpdateObjectLayout={onUpdateObjectLayout}
                onDeleteConnector={onDeleteConnector}
                onUpdateConnector={onUpdateConnector}
                selected={workbenchUIStore.selector}
                tables={mainTabs}
                modelFolders={modelFolders}
                currentWarehouse={currentWarehouse}
                sources={sources}
                connectors={connectors}
                onActiveObjectChange={workbenchUIStore.pushActiveObject}
                activeObject={workbenchUIStore.getActiveObject()}
                onDeleteDataset={onDeleteDataset}
                onCreateDataset={(d) =>
                  onCreateDataset ? onCreateDataset(d) : undefined
                }
                onCreateSource={() =>
                  workbenchUIStore.setSourceEditionOpen(true)
                }
                onDeleteSource={(sourceId: string) => {
                  return onUpdateSource(sourceId, {
                    isDeleted: true,
                  });
                }}
                onUpdateSource={onUpdateSource}
                destinationJobExecutions={destinationJobExecutions}
                destination={destination}
                onRefreshJobExecutions={onRefreshJobExecutions}
                cancelJobExecutionRun={cancelJobExecutionRun}
                explorationSections={this.props.explorationSections}
                explorations={(this.props.explorations || []).map((ex) => {
                  const currentStateItem = this.state.explorations[ex.id];
                  if (
                    currentStateItem &&
                    currentStateItem.exploration.status === "success"
                  ) {
                    return currentStateItem.exploration.data;
                  }
                  return ex;
                })}
                onExplorationUpdate={onRefreshExplorations}
                onSectionUpdate={onRefreshSections}
                onDeleteModelFolder={onDeleteModelFolder}
                onRefreshModelFolderTree={onRefreshModelFolderTree}
                worksheets={worksheets}
                onCreateWorksheet={onCreateWorksheet}
                onUpdateWorksheet={async (id: string, data: any) => {
                  try {
                    updateWorksheetStore(id, data);
                    await GraphQLService(
                      `
                    mutation updateWorksheet($id: ID!, $data: WorksheetUpdateInput!) {
                      updateWorksheet(id: $id, data: $data) {
                        id
                      }
                    }
                    `,
                      {
                        id: id,
                        data: {
                          ...data,
                        },
                      }
                    );
                  } catch (err) {
                    console.error(err);
                    antUtils.message.error(
                      "An unexpected error happened, please try again."
                    );
                  }
                }}
                objects={objects}
                objectStore={this.state.objectStore}
                onUpdateObject={updateObject}
                createObjectLayout={createObjectLayout}
              />
            }
            leftClassName={"workbench-tree"}
            rightClassName={"workbench-spreadsheet"}
            mainClassName="workbench-content"
            right={
              <div className="workbench-content-inner">
                {workbenchUIStore.activeObjects.length ? (
                  <TabBar
                    onReorder={workbenchUIStore.reorderActiveObjects}
                    activeObjects={workbenchUIStore.activeObjects}
                    onObjectSelect={this.setCurrentObject}
                    onRemoveObject={(activeObject) => {
                      const { workbenchUIStore } = this.props;
                      const remove = () => {
                        workbenchUIStore.removeActiveObject(activeObject);
                      };
                      if (activeObject.stale) {
                        return antUtils.modal.confirm({
                          title: "Do you want to proceed?",
                          content:
                            "You have unsaved changes, by continuing your work will be lost. Do you want to proceed?",
                          onOk: async () => {
                            remove();
                          },
                          onCancel: () => false,
                          okButtonProps: {
                            danger: true,
                          },
                        });
                      }

                      return remove();
                    }}
                    nameRenderer={(activeObject) => {
                      if (
                        activeObject.type === "dataset" &&
                        this.state.data[activeObject.value]
                      ) {
                        return this.state.data[activeObject.value]?.name;
                      } else if (
                        activeObject.type === "job" &&
                        this.state.executions[activeObject.value]
                      ) {
                        return generateJobExecutionName(
                          this.state.executions[activeObject.value]
                        );
                      } else if (
                        activeObject.type === "exploration" &&
                        this.state.explorations[activeObject.value]
                      ) {
                        return this.state.explorations[activeObject.value].name;
                      } else if (activeObject.type === "connector") {
                        return connectors.find(
                          (s) => s.id === activeObject.value
                        )?.name;
                      } else if (activeObject.type === "settings") {
                        return "Warehouse Settings";
                      } else if (activeObject.type === "worksheet") {
                        return generateWorksheetName(
                          this.state.worksheetStore[activeObject.value]
                            ?.worksheet
                        );
                      } else if (activeObject.type === "radar") {
                        return (
                          this.props.radars.find(
                            (radar) => radar.id === activeObject.value
                          )?.name ?? "unknown"
                        );
                      } else if (activeObject.type === "object") {
                        return this.state.objectStore[activeObject.value]?.name;
                      } else if (activeObject.type === "object-layout") {
                        const [oId, lId] = activeObject.value.split("::");
                        const name =
                          this.state.objectStore[oId]?.layouts[lId]?.name;
                        return name ? name : "Untitled";
                      } else {
                        return "unkown";
                      }
                    }}
                    iconRenderer={(activeObject) => {
                      if (activeObject.type === "dataset") {
                        const icon =
                          this.state.data[activeObject.value]?.dataset?.source
                            ?.sourceMeta?.publicInfo?.logo;

                        if (icon) {
                          return (
                            <SourceImageRenderer
                              size={20}
                              className={"workbench-item-name-inner-image"}
                              alt={activeObject.value}
                              img={icon}
                            />
                          );
                        }
                        if (
                          this.state.data[activeObject.value]?.dataset
                            ?.managedBy === "DBT_CLOUD"
                        ) {
                          return (
                            <img
                              src={dbtCloud}
                              alt="dbt"
                              style={{ height: 20 }}
                            />
                          );
                        }
                        if (
                          this.state.data[activeObject.value]?.dataset?.isModel
                        ) {
                          return <FileMarkdownOutlined />;
                        }
                      } else if (activeObject.type === "job") {
                        const exec = this.state.executions[activeObject.value];
                        if (exec) {
                          return (
                            <JobExecutionStatusRenderer jobExecution={exec} />
                          );
                        }
                      } else if (activeObject.type === "connector") {
                        return <ApiOutlined />;
                      } else if (activeObject.type === "object-layout") {
                        return <LayoutOutlined />;
                      } else if (activeObject.type === "exploration") {
                        const explo =
                          this.state.explorations[activeObject.value];
                        let emoji = ":hammer_and_wrench:";
                        if (explo && explo.sectionEmoji) {
                          emoji = explo.sectionEmoji;
                        }
                        return (
                          <SourceImageRenderer
                            size={20}
                            className={"workbench-item-name-inner-image"}
                            alt={activeObject.value}
                            img={emoji}
                          />
                        );
                      } else if (activeObject.type === "settings") {
                        return <SettingOutlined />;
                      } else if (activeObject.type === "worksheet") {
                        return <CodeOutlined />;
                      } else if (activeObject.type === "object") {
                        const object = objects.find(
                          (o) => activeObject.value === o.id
                        );
                        return (
                          <IconHolder
                            icon={
                              object?.icon ? (
                                <WlyDynamicIcon
                                  name={object.icon}
                                  fallback={<LayoutOutlined />}
                                />
                              ) : (
                                <LayoutOutlined />
                              )
                            }
                            size={20}
                            background={object?.color ?? "#F1BD6C"}
                            color={"#F9F8F8"}
                          />
                        );
                      } else if (activeObject.type === "radar") {
                        return <WlyRadarIcon />;
                      }
                      return <QuestionCircleFilled />;
                    }}
                  />
                ) : null}
                <ViewerRenderer
                  activeObject={workbenchUIStore.getActiveObject()}
                  updateActiveObject={workbenchUIStore.updateActiveObject}
                  dataStore={this.state.data}
                  datasets={datasets}
                  sources={sources}
                  objects={objects}
                  routeRender={routeRender}
                  currentWarehouse={currentWarehouse}
                  mainTabs={mainTabs}
                  fetchData={this.fetchData}
                  resetDatasetCache={this.resetDatasetCache}
                  onModifyTransformation={this.onModifyTransformation}
                  datasetLineageGraph={this.state.datasetDependecyGraph}
                  onFullscreenChange={(b) => {
                    if (b) {
                      workbenchUIStore.setSelector(null);
                    } else {
                      workbenchUIStore.setSelector("data");
                    }
                  }}
                  onCreateView={onCreateView}
                  onDeleteView={onDeleteView}
                  onRenameView={onRenameView}
                  onCreateRelationship={onCreateRelationship}
                  onUpdateRelationship={onUpdateRelationship}
                  onDeleteRelationship={onDeleteRelationship}
                  onUpdateDrills={onUpdateDrills}
                  onCreateDataset={onCreateDataset}
                  onDeleteDataset={onDeleteDataset}
                  onUpdateDataset={onUpdateDataset}
                  onCreateSource={onCreateSource}
                  executionStore={this.state.executions}
                  destination={destination}
                  onDestinationUpdate={onDestinationUpdate}
                  explorationStore={this.state.explorations}
                  fetchExploration={this.fetchExploration}
                  updateExploration={this.updateExploration}
                  runDataExtract={this.runDataExtract}
                  fetchTableSchema={this.fetchTableSchema}
                  onRefreshDatasets={onRefreshDatasets}
                  onRefreshExplorations={onRefreshExplorations}
                  worksheetStore={this.state.worksheetStore}
                  updateWorksheetData={updateWorksheetStore}
                  objectStore={this.state.objectStore}
                  onUpdateObject={async (id, d) => {
                    await updateObject(id, d);
                    await this.fetchObject(id);
                  }}
                  fetchObject={this.fetchObject}
                  fetchObjectLayout={this.fetchObjectLayout}
                  onUpdateObjectLayout={onUpdateObjectLayout}
                  createObjectLayout={createObjectLayout}
                  radars={radars}
                  onUpdateRadar={onUpdateRadar}
                />
              </div>
            }
            minWidth={200}
            maxWidth={"50%"}
            startWidth={250}
            alignement={"LTR"}
          />
          {onCreateSource && workbenchUIStore.sourceEditionOpen && (
            <SourceCreation
              tables={mainTabs}
              visible={workbenchUIStore.sourceEditionOpen}
              onCancel={() => workbenchUIStore.setSourceEditionOpen(false)}
              currentWarehouse={currentWarehouse}
              onSave={async (data) => {
                try {
                  const source = await onCreateSource(
                    data.source.name,
                    data.source.emoji
                  );
                  if (data.importTables?.length > 0) {
                    const formattedDatasets = data.importTables.map((it) => {
                      return {
                        ...it,
                        sourceId: source.id,
                      };
                    });
                    await onCreateDataset?.(formattedDatasets);
                  }
                  workbenchUIStore.setSourceEditionOpen(false);
                } catch (error) {
                  handleGQLErrors(error);
                }
              }}
            />
          )}
          {createObject && workbenchUIStore.objectAdditionOpen && (
            <CreateObjectModal
              open={!!workbenchUIStore.objectAdditionOpen}
              datasets={this.props.datasets}
              onCreate={async (options) => {
                try {
                  const id = await createObject(options);
                  const ao = {
                    type: "object",
                    value: id,
                  } as IActiveObject;
                  workbenchUIStore.pushActiveObject(ao);
                  workbenchUIStore.setActiveObjectFocused(ao);
                  this.props.antUtils.message.success("Object created");
                  return id;
                } catch (error) {
                  this.props.antUtils.message.error(
                    "Error while creating object. Please try again"
                  );
                  return "";
                }
              }}
              onClose={() => workbenchUIStore.setObjectAdditionOpen(false)}
            />
          )}
          {onCreateRadar && workbenchUIStore.radarAdditionOpen && (
            <CreateRadarModal
              open={!!workbenchUIStore.radarAdditionOpen}
              onCreate={async (options) => {
                try {
                  const id = await onCreateRadar(options);
                  const ao = {
                    type: "radar",
                    value: id,
                  } as IActiveObject;
                  workbenchUIStore.pushActiveObject(ao);
                  workbenchUIStore.setActiveObjectFocused(ao);
                  this.props.antUtils.message.success("Radar created");
                  return id;
                } catch (error) {
                  this.props.antUtils.message.error(
                    "Error while creating radar. Please try again"
                  );
                  return "";
                }
              }}
              datasets={datasets}
              objects={objects}
              onClose={() => workbenchUIStore.setRadarAdditionOpen(false)}
            />
          )}
          {onCreateModelFolder &&
            onUpdateModelFolder &&
            workbenchUIStore.modelFolderEditionOpen && (
              <ModelFolderEditForm
                visible={!!workbenchUIStore.modelFolderEditionOpen}
                initialData={
                  typeof workbenchUIStore.modelFolderEditionOpen !== "boolean"
                    ? workbenchUIStore.modelFolderEditionOpen
                    : undefined
                }
                onCancel={() =>
                  workbenchUIStore.setModelFolderEditionOpen(false)
                }
                onCreate={onCreateModelFolder}
                onEdit={onUpdateModelFolder}
              />
            )}
          {onCreateDataset &&
            onUpdateDataset &&
            workbenchUIStore.datasetEditionOpen && (
              <AddModelModal
                currentWarehouse={currentWarehouse}
                visible={!!workbenchUIStore.datasetEditionOpen}
                initialData={
                  typeof workbenchUIStore.datasetEditionOpen !== "boolean"
                    ? workbenchUIStore.datasetEditionOpen
                    : undefined
                }
                onCancel={() => {
                  workbenchUIStore.setDatasetEditionOpen(false);
                }}
                onSave={(datasetSavedData) => {
                  const datasetEditionOpen =
                    workbenchUIStore.datasetEditionOpen;
                  if (
                    typeof datasetEditionOpen !== "boolean" &&
                    datasetEditionOpen?.id
                  ) {
                    return onUpdateDataset(datasetEditionOpen?.id, {
                      name: datasetSavedData.name,
                    })
                      .then(() => {
                        workbenchUIStore.setDatasetEditionOpen(false);
                      })
                      .catch(handleGQLErrors());
                  }
                  return onCreateDataset({
                    ...datasetSavedData,
                    isModel: true,
                  })
                    .then(() => {
                      workbenchUIStore.setDatasetEditionOpen(false);
                    })
                    .catch(handleGQLErrors());
                }}
              />
            )}
          <TriggerJobModal
            visible={workbenchUIStore.dbtCloudSyncExecutionEditionOpen}
            jobType={JobType.DBT_CLOUD_SYNC}
            onCancel={() =>
              workbenchUIStore.setDbtCloudSyncExecutionEditionOpen(false)
            }
            currentWarehouse={currentWarehouse}
            onDone={async () => {
              await onRefreshJobExecutions();
              workbenchUIStore.setDbtCloudSyncExecutionEditionOpen(false);
            }}
          />
          <TriggerJobModal
            visible={workbenchUIStore.persistenceEngineExecutionEditionOpen}
            jobType={JobType.PERSISTENCE_ENGINE_RUN}
            onCancel={() =>
              workbenchUIStore.setPersistenceEngineExecutionEditionOpen(false)
            }
            onDone={async () => {
              await onRefreshJobExecutions();
              workbenchUIStore.setPersistenceEngineExecutionEditionOpen(false);
            }}
            currentWarehouse={currentWarehouse}
          />
          <ExplorationCreation
            visible={workbenchUIStore.explorationEditionOpen}
            onCancel={() => workbenchUIStore.setExplorationEditionOpen(false)}
            currentWarehouse={currentWarehouse}
            onCreate={async (id) => {
              if (id && id.length) {
                await onRefreshExplorations(id);
                workbenchUIStore.setExplorationEditionOpen(false);
                workbenchUIStore.pushActiveObject({
                  type: "exploration",
                  value: id[0],
                });
              }
            }}
          />
          <CatalogModal
            open={workbenchUIStore.warehouseAdditionOpen}
            onClose={() => {
              workbenchUIStore.setWarehouseAdditionOpen(false);
            }}
            type={"warehouse"}
          />
          <CatalogModal
            type="source"
            selectedWarehouseSlug={currentWarehouse?.slug}
            open={workbenchUIStore.connectorCatalogOpen}
            onClose={() => {
              workbenchUIStore.setConnectorCatalogOpen(false);
            }}
          />
          <ExplorationSectionCreation
            onCancel={() => {
              workbenchUIStore.setExplorationSectionCreationOpen(false);
            }}
            onEdit={async () => null}
            onCreate={async (v) => {
              const data = await GraphQLService(
                `
                mutation createExplorationSection($data: ExplorationSectionCreateInput!) {
                  createExplorationSection(data: $data) {
                    id
                    name
                    description
                    order
                    image
                  }
                }
                `,
                {
                  data: {
                    name: v.name,
                    image: v.image,
                    order: v.order ? v.order : 0,
                    org: {
                      connect: {
                        id: org.id,
                      },
                    },
                  },
                }
              );
              return onRefreshSections([data.createExplorationSection.id]);
            }}
            visible={workbenchUIStore.explorationSectionCreationOpen}
            initialData={undefined}
          />
          <WlyGlobalSearch
            open={this.props.workbenchUIStore.globalSearchOpen}
            context="WORKBENCH"
            setOpen={(open) =>
              this.props.workbenchUIStore.setGlobalSearchOpen(open)
            }
            placeholder="Search in the workbench..."
            items={[
              ...this.props.explorations.map((e) => {
                const section = this.props.explorationSections?.find(
                  (s) => s.id === e.section?.id
                );
                return {
                  key: `explo-${e.id}`,
                  icon: section?.image ? (
                    <Emoji size={18} emoji={section.image} />
                  ) : (
                    <Emoji size={18} emoji={":hammer_and_wrench:"} />
                  ),
                  label: e.name,
                  value: `explo-${e.id}`,
                  category: "Exploration",
                  details: section ? section.name : "Work in progress",
                  onSelect: () =>
                    workbenchUIStore.pushActiveObject({
                      type: "exploration",
                      value: e.id,
                    }),
                };
              }),
              ...[...this.props.datasets]
                .filter((d) => !d.isModel)
                .map((d) => {
                  const img = d.source.sourceMeta?.publicInfo?.logo;
                  return {
                    key: `dataset-${d.id}`,
                    icon: <SourceImageRenderer img={img} size={18} alt="h" />,
                    details: d.source?.name,
                    label: d.name,
                    value: `dataset-${d.id}`,
                    category: "Dataset",
                    onSelect: () =>
                      workbenchUIStore.pushActiveObject({
                        type: "dataset",
                        value: d.id,
                      }),
                  };
                }),
              ...[...this.props.datasets]
                .filter((d) => d.isModel)
                .map((d) => {
                  const modelForder = this.props.modelFolders?.find(
                    (mf) => mf.id === d.folder?.id
                  );
                  return {
                    key: `dataset-${d.id}`,
                    icon: <FileMarkdownOutlined />,
                    label: d.name,
                    value: `dataset-${d.id}`,
                    category: "Model",
                    details: modelForder ? modelForder.name : null,
                    onSelect: () =>
                      workbenchUIStore.pushActiveObject({
                        type: "dataset",
                        value: d.id,
                      }),
                  };
                }),
              ...[...this.props.worksheets].map((ws) => {
                return {
                  key: `worksheet-${ws.id}`,
                  icon: <CodeOutlined />,
                  label: ws.name ?? generateWorksheetName(ws),
                  value: `worksheet-${ws.id}`,
                  category: "Worksheet",
                  onSelect: () =>
                    workbenchUIStore.pushActiveObject({
                      type: "worksheet",
                      value: ws.id,
                    }),
                };
              }),
              ...[...this.props.objects].map((object) => {
                return {
                  key: `object-${object.id}`,
                  icon: <DeploymentUnitOutlined />,
                  label: object.name,
                  value: `object-${object.id}`,
                  category: "Object",
                  onSelect: () =>
                    workbenchUIStore.pushActiveObject({
                      type: "object",
                      value: object.id,
                    }),
                };
              }),
              ...[...this.props.radars].map((radar) => {
                return {
                  key: `radar-${radar.id}`,
                  icon: <WlyRadarIcon />,
                  label: radar.name,
                  value: `radar-${radar.id}`,
                  category: "Radar",
                  onSelect: () =>
                    workbenchUIStore.pushActiveObject({
                      type: "radar",
                      value: radar.id,
                    }),
                };
              }),
            ]}
          />
        </div>
      </div>
    );
  }
}

export default compose<Props, IWorkBenchProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(inject("workbenchUIStore")(observer(WorkBench)));
