import {
  CloseOutlined,
  HomeOutlined,
  LayoutOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Divider, Flex, Space, Typography } from "antd";
import _, { sortBy } from "lodash";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import type { RouteComponentProps } from "react-router";
import { matchPath, withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import { WlyDynamicIcon } from "../../../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import { IconHolder } from "../../../../../components/icons/holder/IconHolder";
import SubMenuItem from "../../../../../components/menu/SubMenuItem";
import type { IReportFolder } from "../../../../../interfaces/folder";
import type { IObject } from "../../../../../interfaces/object";
import { IOrgFeatureType } from "../../../../../interfaces/org";
import { IUserFeatureType } from "../../../../../interfaces/user";
import { routeDescriptor } from "../../../../../routes/routes";
import type { WorkspaceUIStoreProps } from "../../../../../store/workspaceUIStore";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { ClosableContent } from "../../../component/layout/ClosableContent";
import FolderMenu from "../../folders/menu/FolderMenu";

interface IWorkspaceMenuProps {
  isDraggingReport: boolean;
  allReportFolders: IReportFolder[];
  allObjects?: IObject[];
  personalFolder?: IReportFolder;
}

type Props = IWorkspaceMenuProps &
  RouteComponentProps &
  WorkspaceUIStoreProps &
  InjectedOrgProps;

const WorkspaceMenu = (props: Props) => {
  const { userFeatures } = props;

  if (userFeatures.includes(IUserFeatureType.TMP_NEW_WORKSPACE_MENU)) {
    return <NewWorkspaceMenu {...props} />;
  } else {
    return <OldWorkspaceMenu {...props} />;
  }
};

const NewWorkspaceMenu = compose(
  inject("workspaceUIStore"),
  observer
)(
  ({
    history,
    match: { params },
    workspaceUIStore,
    isDraggingReport,
    allReportFolders,
    allObjects,
    personalFolder,
  }: Props) => {
    const {
      drawerType,
      drawerTitleActionsDomNode,
      setSelectedFolder,
      setDrawerType,
    } = workspaceUIStore;

    const [additionalObjects, setAdditionalObjects] = useState<Array<IObject>>(
      []
    );

    useEffect(() => {
      if ("objectSlug" in params && params.objectSlug && allObjects) {
        const current = allObjects.find(
          ({ slug }) => slug === params.objectSlug
        );

        if (current && !current.canBeListed) {
          setAdditionalObjects((v) =>
            [...v, current].filter((v, i, s) => s.indexOf(v) === i)
          );
        }
      }
    }, [allObjects, params]);

    const renderObjects = () => {
      if (allObjects) {
        const renderItems = (): {
          key: string;
          onClick: () => void;
          selected?: boolean;
          label: React.ReactNode;
        }[] => {
          const allListedObjects = sortBy(allObjects, "position")
            .filter(({ canBeListed }) => canBeListed)
            .map(({ slug, icon, color, name, defaultView }) => {
              return {
                key: slug,
                onClick: () => {
                  setSelectedFolder();
                  setDrawerType(undefined);
                  history.push(
                    routeDescriptor["object"].renderRoute(
                      {
                        ...params,
                        objectSlug: slug,
                      },
                      {
                        view: !defaultView?.isDeleted
                          ? defaultView?.slug
                          : undefined,
                      }
                    )
                  );
                },
                selected: "objectSlug" in params && slug === params.objectSlug,
                label: (
                  <ListedObjectLabel icon={icon} color={color} name={name} />
                ),
              };
            });

          const ao = sortBy(additionalObjects, "position")
            .filter(({ id }) => additionalObjects.map((i) => i.id).includes(id))
            .map(({ slug, icon, color, name, defaultView }) => {
              return {
                key: slug,
                onClick: () => {
                  setSelectedFolder();
                  setDrawerType(undefined);
                  history.push(
                    routeDescriptor["object"].renderRoute(
                      {
                        ...params,
                        objectSlug: slug,
                      },
                      {
                        view: !defaultView?.isDeleted
                          ? defaultView?.slug
                          : undefined,
                      }
                    )
                  );
                },
                selected: "objectSlug" in params && slug === params.objectSlug,
                label: (
                  <ListedObjectLabel
                    icon={icon}
                    color={color}
                    name={name}
                    onClick={() =>
                      setAdditionalObjects((v) =>
                        v.filter((a) => a.slug !== slug)
                      )
                    }
                  />
                ),
              };
            });

          return [...allListedObjects, ...ao];
        };

        if (allObjects.length === 0) {
          return (
            <div>
              <Typography.Text type="secondary">
                No objects available
              </Typography.Text>
            </div>
          );
        }

        return (
          <Flex gap="small" vertical>
            {renderItems().map(({ key, selected, label, onClick }) => (
              <Button
                key={key}
                onClick={onClick}
                type="text"
                style={{
                  padding: "4px 8px",
                  cursor: "pointer",
                  backgroundColor: selected ? "#f0f0f0" : undefined,
                }}
              >
                {label}
              </Button>
            ))}
          </Flex>
        );
      }
    };

    return (
      <div
        style={{
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {/* {orgFeatures.includes(IOrgFeatureType.RCA_DEMO_DATA) && (
          <ClosableContent
            name={"Analyses"}
            items={[
              {
                slug: "missing-references",
                name: "Analyse de la PDL",
              },
            ].map((p) => {
              return {
                key: p.slug,
                selected: p.slug === (params as any).analysisSlug,
                onClick: () => {
                  setSelectedFolder();
                  history.push(
                    routeDescriptor["rootCause"].renderRoute({
                      ...params,
                    })
                  );
                },
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <Typography.Text>{p.name}</Typography.Text>
                    </div>
                  </div>
                ),
              };
            })}
            button={{
              name: "Browse Analyses",
            }}
          />
        )} */}
          {drawerType === "lists" &&
            drawerTitleActionsDomNode &&
            createPortal(
              <Button
                style={{
                  alignSelf: "flex-end",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() =>
                  history.push(
                    routeDescriptor["object-list"].renderRoute({
                      ...params,
                    })
                  )
                }
                type="text"
                icon={<SettingOutlined />}
                shape="circle"
              />,
              drawerTitleActionsDomNode
            )}
          {drawerType === "lists" && renderObjects()}
          {drawerType === "dashboards" && (
            <FolderMenu
              isDragging={isDraggingReport}
              folders={allReportFolders}
              personalFolder={personalFolder}
            />
          )}
        </Space>
      </div>
    );
  }
);

const OldWorkspaceMenu = compose(
  inject("workspaceUIStore"),
  observer
)((props: Props) => {
  const {
    location: { pathname },
    history,
    match: { params },
    workspaceUIStore,
    isDraggingReport,
    allReportFolders,
    orgFeatures,
    allObjects,
    personalFolder,
  } = props;

  const [additionalObjects, setAdditionalObjects] = React.useState<
    Array<IObject>
  >([]);

  React.useEffect(() => {
    if ((params as any).objectSlug && allObjects) {
      const current = allObjects.find(
        (o) => o.slug === (params as any).objectSlug
      );
      if (current && !current.canBeListed) {
        setAdditionalObjects((v) =>
          [...v, current].filter((v, i, s) => s.indexOf(v) === i)
        );
      }
    }
  }, [(params as any).objectSlug]);

  const renderObjects = () => {
    if (allObjects) {
      const renderEmpty = () => {
        return (
          <div style={{ padding: `6px 20px` }}>
            <Typography.Text type="secondary">
              No objects available
            </Typography.Text>
          </div>
        );
      };

      const renderItems = (): {
        key: string;
        onClick: () => void;
        selected?: boolean;
        label: React.ReactNode;
        style?: React.CSSProperties;
      }[] => {
        const allListedObjects = _.sortBy(
          (allObjects || []).filter((o) => o.canBeListed),
          "position"
        ).map((o) => {
          return {
            key: o.slug,
            onClick: () => {
              workspaceUIStore.setSelectedFolder();
              history.push(
                routeDescriptor["object"].renderRoute(
                  {
                    ...params,
                    objectSlug: o.slug,
                  },
                  {
                    view:
                      !o.defaultView?.isDeleted && o.defaultView?.slug
                        ? o.defaultView.slug
                        : undefined,
                  }
                )
              );
            },
            selected: o.slug === (params as any).objectSlug,
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    gap: 8,
                    minWidth: 0,
                  }}
                >
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <IconHolder
                      icon={
                        o.icon ? (
                          <WlyDynamicIcon
                            name={o.icon}
                            fallback={<LayoutOutlined />}
                          />
                        ) : (
                          <LayoutOutlined />
                        )
                      }
                      size={20}
                      background={o.color ?? "#F1BD6C"}
                      color={"#F9F8F8"}
                    />
                  </div>
                  <Typography.Text ellipsis>{o.name}</Typography.Text>
                </div>
              </div>
            ),
          };
        });

        const ao = _.sortBy(
          (allObjects || []).filter((o) =>
            additionalObjects.map((i) => i.id).includes(o.id)
          ),
          "position"
        ).map((o) => {
          const selected = o.slug === (params as any).objectSlug;
          return {
            key: o.slug,
            onClick: () => {
              workspaceUIStore.setSelectedFolder();
              history.push(
                routeDescriptor["object"].renderRoute(
                  {
                    ...params,
                    objectSlug: o.slug,
                  },
                  {
                    view:
                      !o.defaultView?.isDeleted && o.defaultView?.slug
                        ? o.defaultView.slug
                        : undefined,
                  }
                )
              );
            },
            selected: selected,
            style: { paddingRight: 8 },
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    gap: 8,
                    minWidth: 0,
                  }}
                >
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <IconHolder
                      icon={
                        o.icon ? (
                          <WlyDynamicIcon
                            name={o.icon}
                            fallback={<LayoutOutlined />}
                          />
                        ) : (
                          <LayoutOutlined />
                        )
                      }
                      size={20}
                      background={o.color ?? "#F1BD6C"}
                      color={"#F9F8F8"}
                    />
                  </div>
                  <Typography.Text ellipsis italic>
                    {o.name}
                  </Typography.Text>
                </div>

                <div style={{ flex: `0 24px`, position: "relative" }}>
                  <Button
                    type="text"
                    size="small"
                    icon={<CloseOutlined style={{ fontSize: "0.9em" }} />}
                    style={{ position: "absolute", top: -12 }}
                    shape="circle"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAdditionalObjects((v) =>
                        v.filter((a) => a.slug !== o.slug)
                      );
                    }}
                  />
                </div>
              </div>
            ),
          };
        });

        return [...allListedObjects, ...ao];
      };

      return (
        <ClosableContent
          name={"Lists"}
          button={
            allObjects?.length
              ? {
                  name: "Browse Lists",
                  onClick: () =>
                    history.push(
                      routeDescriptor["object-list"].renderRoute({
                        ...params,
                      })
                    ),
                }
              : undefined
          }
          items={allObjects?.length ? renderItems() : renderEmpty()}
        />
      );
    }
  };

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "100%",
        padding: "0 8px",
      }}
    >
      <SubMenuItem
        selected={!!matchPath(pathname, routeDescriptor.home.path)?.isExact}
        onClick={() => {
          workspaceUIStore.setSelectedFolder();
          history.push(routeDescriptor.home.renderRoute(params));
        }}
        displayName={
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: 6,
                flex: 0,
              }}
            >
              <HomeOutlined style={{ fontSize: 16, color: "gray" }} />
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              <Typography.Text>Home</Typography.Text>
            </div>
          </div>
        }
      />
      <SubMenuItem
        selected={workspaceUIStore.globalSearchOpen}
        onClick={() => {
          workspaceUIStore.setGlobalSearchOpen(true);
        }}
        displayName={
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: 6,
                flex: 0,
              }}
            >
              <SearchOutlined style={{ fontSize: 16, color: "gray" }} />
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              <Typography.Text>Search</Typography.Text>
            </div>
          </div>
        }
      />

      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      <Space direction="vertical" style={{ width: "100%" }}>
        {orgFeatures.includes(IOrgFeatureType.RCA_DEMO_DATA) && (
          <ClosableContent
            name={"Analyses"}
            items={[
              {
                slug: "missing-references",
                name: "Analyse de la PDL",
              },
            ].map((p) => {
              return {
                key: p.slug,
                selected: p.slug === (params as any).analysisSlug,
                onClick: () => {
                  workspaceUIStore.setSelectedFolder();
                  history.push(
                    routeDescriptor["rootCause"].renderRoute({
                      ...params,
                    })
                  );
                },
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <Typography.Text>{p.name}</Typography.Text>
                    </div>
                  </div>
                ),
              };
            })}
            button={{
              name: "Browse Analyses",
            }}
          />
        )}
        {renderObjects()}
        <FolderMenu
          isDragging={isDraggingReport}
          folders={allReportFolders}
          personalFolder={personalFolder}
        />
      </Space>
    </div>
  );
});

export default compose<Props, IWorkspaceMenuProps>(
  withRouter,
  WithOrg,
  inject("workspaceUIStore"),
  observer
)(WorkspaceMenu);

type ListedObjectLabelProps = {
  icon?: string;
  color?: string;
  name: string;
  onClick?: () => void;
};
const ListedObjectLabel = ({
  icon,
  color,
  name,
  onClick,
}: ListedObjectLabelProps) => {
  return (
    <Flex align="center">
      <Flex gap="small" align="center" style={{ minWidth: 0, flex: 1 }}>
        <IconHolder
          icon={
            icon ? (
              <WlyDynamicIcon name={icon} fallback={<LayoutOutlined />} />
            ) : (
              <LayoutOutlined />
            )
          }
          size={20}
          background={color ?? "#F1BD6C"}
          color={"#F9F8F8"}
        />
        <Typography.Text ellipsis italic={!!onClick}>
          {name}
        </Typography.Text>
      </Flex>

      {onClick && (
        <Button
          type="text"
          size="small"
          icon={<CloseOutlined style={{ fontSize: "0.9em" }} />}
          shape="circle"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        />
      )}
    </Flex>
  );
};
