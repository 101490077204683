import { Space, Typography } from "antd";
import { inject, observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { WlyCard } from "../../../../../components/cards/WlyCard";
import { compose } from "../../../../../components/compose/WlyCompose";
import { useRefCachedValue } from "../../../../../components/hooks/useRefCachedValue";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { ISource, ISourceValue } from "../../../../../interfaces/sources";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import SourceExecutions from "../../../../sources/single/executions/SourceExecutions";
import SourceConnectionSettings from "../../../../sources/single/views/SourceConnectionSettings";
import SourceOverview from "../../../../sources/single/views/SourceOverview";
import SourceSchema from "../../../../sources/single/views/SourceSchema";
import SourceSyncSettingsForm from "../../../../sources/single/views/SourceSyncSettings";
import SourceUsage from "../../../../sources/single/views/SourceUsage";
import * as Toolbar from "../../../../spreadsheet/toolbar/Toolbar";
import type { IActiveObject } from "../../domain";
import NoAccess from "../empty/NoAccess";
import "./ConnectorViewer.scss";

interface IConnectorViewerProps {
  activeObject: IActiveObject;
  sources: ISource[];
  currentWarehouse: IDestination;
  refetchSources: () => Promise<void>;
}

type Props = IConnectorViewerProps & WorkbenchUIStoreProps & InjectedOrgProps;

const GQL = `mutation saveValue(
  $id: ID!
  $value: String!
) {
  updateSourceValue(
    id: $id
    data: {
      value: $value
    }
  ) {
    id
    value
  }
}`;

function ConnectorViewer(props: Props) {
  const {
    activeObject,
    sources,
    workbenchUIStore: {
      getActiveObject,
      setActiveObjectUrlParams,
      getActiveObjectUrlParams,
    },
    currentWarehouse,
    refetchSources,
  } = props;

  const activeSource = sources.find(
    (source) => source.id === getActiveObject()?.value
  );

  const [source, setSource] = useState<ISource | undefined>(activeSource);

  useEffect(() => {
    setSource(activeSource);
  }, [sources, activeSource]);

  const [sourceValue, setSourceValue] = useState<ISourceValue>(null);

  const onValueSelection = (optId: string) => {
    const value = source && source.values.find((v) => v.option.id === optId);
    value && setSourceValue(value);
  };

  const onSourceValueUpdate = (value: ISourceValue) => {
    if (source) {
      const newSource: ISource = {
        ...source,
        values: source.values.map((v) => {
          return v.id === value.id ? { ...v, ...value } : v;
        }),
      };
      setSource(newSource);
    }
  };

  const onSourceValueSave = (
    sourceValueId: string,
    value: string | number | Date
  ) =>
    GraphQLService(GQL, {
      id: sourceValueId,
      value: value,
    }).then((s) => {
      onSourceValueUpdate(s.updateSourceValue);
    });

  const selectedTab = useMemo(() => {
    const activeObjectUrlParams = getActiveObjectUrlParams(activeObject) ?? {};
    switch ("tab" in activeObjectUrlParams && activeObjectUrlParams.tab) {
      case "overview":
        return "overview";
      case "usage":
        return "usage";
      case "sync":
        return "sync";
      case "settings":
        return "settings";
      default:
        return "overview";
    }
  }, [activeObject, getActiveObjectUrlParams]);

  return (
    <div className="workbench-content">
      <div className="workbench-spreadsheet">
        <div className="workbench-content-inner">
          <Toolbar.Toolbar style={{ borderTop: "none" }}>
            <Toolbar.Item
              color="green"
              active={selectedTab === "overview"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "overview",
                });
              }}
            >
              Overview
            </Toolbar.Item>
            <Toolbar.Item
              color="yellow"
              active={selectedTab === "usage"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "usage",
                });
              }}
            >
              Usage
            </Toolbar.Item>
            <Toolbar.Item
              color="pink"
              active={selectedTab === "sync"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "sync",
                });
              }}
            >
              Selective sync
            </Toolbar.Item>
            <Toolbar.Item
              color="violet"
              active={selectedTab === "settings"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "settings",
                });
              }}
            >
              Settings
            </Toolbar.Item>
          </Toolbar.Toolbar>
          <div className="workbench-content">
            <div className="source-viewer">
              <div
                style={{ maxWidth: 800, width: "100%", margin: "24px auto" }}
              >
                {activeObject && source ? (
                  <SourceTabContent
                    selectedTab={selectedTab}
                    source={source}
                    sourceValue={sourceValue}
                    currentWarehouse={currentWarehouse}
                    setSourceValue={setSourceValue}
                    onSourceValueSave={onSourceValueSave}
                    onValueSelection={onValueSelection}
                    refetchSources={refetchSources}
                  />
                ) : (
                  <NoAccess />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type SourceTabContentProps = {
  selectedTab: string;
  source: ISource;
  sourceValue: ISourceValue;
  currentWarehouse: IDestination;
  setSourceValue: (value: ISourceValue) => void;
  onSourceValueSave: (
    sourceValueId: string,
    value: string | number | Date
  ) => Promise<void>;
  onValueSelection: (optId: string) => void;
  refetchSources: () => Promise<void>;
};
const SourceTabContent = ({
  selectedTab,
  source: propSource,
  sourceValue,
  currentWarehouse,
  setSourceValue,
  onSourceValueSave,
  onValueSelection,
  refetchSources,
}: SourceTabContentProps) => {
  const source = useRefCachedValue(propSource);

  const OverviewTab = useMemo(
    () => (
      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <SourceOverview source={source} refetchSources={refetchSources} />
        <SourceExecutions source={source} />
      </Space>
    ),
    [source, refetchSources]
  );

  const UsageTab = useMemo(
    () => (
      <div style={{ height: "600px" }}>
        <SourceUsage source={source} />
      </div>
    ),
    [source]
  );

  const SyncTab = useMemo(
    () => <SourceSchema source={source} refetchSources={refetchSources} />,
    [source, refetchSources]
  );

  const SettingsTab = useMemo(
    () => (
      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <WlyCard bodyStyle={{ padding: 0 }}>
          <SourceConnectionSettings
            selectedSourceValue={sourceValue}
            source={source}
            onValueSelection={onValueSelection}
            onBackClick={() => setSourceValue(null)}
            onSourceValueUpdate={onSourceValueSave}
          />
        </WlyCard>
        <WlyCard title="Sync settings">
          <SourceSyncSettingsForm
            source={source}
            refetchSources={refetchSources}
          />
        </WlyCard>
        {source.targetSchema && (
          <WlyCard title="Warehouse">
            Location in warehouse:{" "}
            <Typography.Text code>
              {source.targetDatabase || currentWarehouse?.targetDatabase}
            </Typography.Text>{" "}
            / <Typography.Text code>{source.targetSchema}</Typography.Text>
          </WlyCard>
        )}
      </Space>
    ),
    [
      sourceValue,
      source,
      onValueSelection,
      onSourceValueSave,
      refetchSources,
      currentWarehouse?.targetDatabase,
      setSourceValue,
    ]
  );

  switch (selectedTab) {
    case "overview":
      return OverviewTab;
    case "usage":
      return UsageTab;
    case "sync":
      return SyncTab;
    case "settings":
      return SettingsTab;
    default:
      return null;
  }
};

export default compose<Props, IConnectorViewerProps>(
  inject("workbenchUIStore"),
  WithOrg,
  observer
)(ConnectorViewer);
