import { useMemo } from "react";
import { createBreakpoint } from "react-use";

export enum Breakpoints {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
}

const InternalBreakpointObj = {
  XS: Breakpoints.XS,
  SM: Breakpoints.SM,
  MD: Breakpoints.MD,
  LG: Breakpoints.LG,
  XL: Breakpoints.XL,
} as const;

const getCurrentBreakpoint = createBreakpoint(InternalBreakpointObj);

export const useBreakpoint = (breakpoint: Breakpoints) => {
  const currentBreakpoint = getCurrentBreakpoint();

  const afterBreakpoint = useMemo(
    () =>
      Object.values(InternalBreakpointObj).indexOf(breakpoint) >
      Object.keys(InternalBreakpointObj).indexOf(currentBreakpoint),
    [breakpoint, currentBreakpoint]
  );

  return afterBreakpoint;
};
