import {
  BugOutlined,
  CaretDownOutlined,
  EyeInvisibleOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { DiffEditor } from "@monaco-editor/react";
import type { MenuProps } from "antd";
import {
  Button,
  Cascader,
  Checkbox,
  ConfigProvider,
  Drawer,
  Dropdown,
  Flex,
  Space,
  Tag,
  theme,
  Tooltip,
  Tree,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import _, { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useInterval } from "react-use";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import { RouterPrompt } from "../../../../components/router-prompt/routerPrompt";
import CardTable from "../../../../components/table/CardTable";
import type { InjectedOrgProps } from "../../../../containers/orgs/WithOrg";
import WithOrg from "../../../../containers/orgs/WithOrg";
import type { ISource } from "../../../../interfaces/sources";
import type {
  CatalogFile,
  CatalogStream,
  MetadataEntry,
  ReplicationMethod,
} from "../../../../interfaces/sourcescatalog";
import { getJSONFile, updateFile } from "../../../../services/FileService";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import { arrayToTree } from "../../../../utils/arrayToTree";
import { SYNC_NOW_QUERY } from "../domain";
import { useGetSourceState } from "../hooks/useGetSourceState";
import "./SourceSchema.scss";
import SourceState from "./SourceState";
import { SourceStateModal } from "./SourceStateModal";

const { Text } = Typography;

type ISourceSchemaProps = {
  source: ISource;
  refetchSources: () => Promise<void>;
};

type Props = ISourceSchemaProps & InjectedOrgProps & InjectedAntUtilsProps;

type TableLine = {
  stream: CatalogStream;
  label: string;
  description: string;
  key: string;
  id: string;
  isHidden: boolean;
  parentStreamIds: string[];
  childrenStreamIds: string[];
};

export type ChildBookmark = {
  key: string;
  label: string;
};
export type Bookmark = ChildBookmark & {
  childrens: ChildBookmark[];
};

const getStreamMetadataProperty = <K extends keyof MetadataEntry>(
  stream: CatalogStream,
  key: K
) => {
  if (stream.metadata.length !== 1) return undefined;
  return stream.metadata[0].metadata[key];
};

const getStreamLabel = (stream: CatalogStream): string => {
  const label = getStreamMetadataProperty(stream, "whaly-display-label");

  return label ?? stream.tap_stream_id;
};

const getStreamReplicationMethod = (
  stream: CatalogStream
): ReplicationMethod => {
  const forcedReplicationMethod = getStreamMetadataProperty(
    stream,
    "forced-replication-method"
  );
  const replicationMethod = getStreamMetadataProperty(
    stream,
    "replication-method"
  );

  return forcedReplicationMethod ?? replicationMethod;
};

const isStreamSelected = (stream: CatalogStream): boolean => {
  const isSelected = getStreamMetadataProperty(stream, "selected");
  const isSelectedByDefault = getStreamMetadataProperty(
    stream,
    "selected-by-default"
  );

  return isSelected ?? isSelectedByDefault ?? true;
};

const isStreamHidden = (stream: CatalogStream): boolean => {
  const isHidden = getStreamMetadataProperty(stream, "whaly-is-hidden");

  return !!isHidden;
};

const getSelectedRowKeys = (lines: TableLine[]) =>
  lines
    .filter((line) => isStreamSelected(line.stream))
    .map((line) => line.stream.tap_stream_id);

const getStreamFromId = (streamId: string, streams: CatalogStream[]) =>
  streams?.find((s) => s.tap_stream_id === streamId);

const getParentsStreamsIds = (
  streamId: string,
  streams: CatalogStream[],
  parents: string[] = []
): string[] => {
  const stream = getStreamFromId(streamId, streams);
  const parentId = getStreamMetadataProperty(stream, "whaly-parent-stream");
  if (!parentId) return parents;
  parents.push(parentId);
  return getParentsStreamsIds(parentId, streams, parents);
};

interface StreamMapItem extends CatalogStream {
  children?: StreamMapItem[];
}

type StreamMap = StreamMapItem[];

const getChildrenStreamMap = (
  streamId: string | undefined,
  streams: CatalogStream[]
): StreamMap => {
  return streams
    .filter(
      (stream) =>
        streamId &&
        getStreamMetadataProperty(stream, "whaly-parent-stream") === streamId
    )
    .map((stream) => ({
      ...stream,
      children: getChildrenStreamMap(stream.tap_stream_id, streams),
    }));
};

const getChildrenStreamsIds = (streamsMap: StreamMap): string[] => {
  return streamsMap.reduce<string[]>((acc, r) => {
    acc.push(r.tap_stream_id);

    if (r.children && r.children.length) {
      acc = acc.concat(getChildrenStreamsIds(r.children));
    }
    return acc;
  }, []);
};

const canUpdateReplicationMethod = (stream: CatalogStream) => {
  const forcedReplicationMethod = getStreamMetadataProperty(
    stream,
    "forced-replication-method"
  );
  if (forcedReplicationMethod) return false;
  const replicationMethod = getStreamReplicationMethod(stream);
  if (!replicationMethod) return false;

  if (replicationMethod === "LOG_BASED") return false;
  const replicationKeys = getStreamAvailableReplicationKeys(stream);
  return !!replicationKeys.length;
};

const getStreamReplicationKey = (stream: CatalogStream): string => {
  return getStreamMetadataProperty(stream, "replication-key");
};

const getStreamAvailableReplicationKeys = (stream: CatalogStream): string[] => {
  const replicationKeys = getStreamMetadataProperty(
    stream,
    "valid-replication-keys"
  );
  return Array.isArray(replicationKeys) ? replicationKeys : [];
};

const compileTableLinesAsCatalog = (tableLines: TableLine[]): CatalogFile => {
  return {
    streams: tableLines.map((tableLine) => tableLine.stream),
  };
};

const parseCatalogAsTableLines = (catalogFile: CatalogFile): TableLine[] => {
  return catalogFile.streams.map<TableLine>((stream) => {
    const streamsMap = getChildrenStreamMap(
      stream.tap_stream_id,
      catalogFile.streams
    );
    return {
      stream: stream,
      label: getStreamLabel(stream),
      description: getStreamMetadataProperty(stream, "whaly-description") ?? "",
      key: stream.tap_stream_id,
      id: stream.tap_stream_id,
      isHidden: isStreamHidden(stream),
      parentStreamIds: getParentsStreamsIds(
        stream.tap_stream_id,
        catalogFile.streams
      ),
      childrenStreamIds: getChildrenStreamsIds(streamsMap),
    };
  });
};

function SourceSchema(props: Props) {
  const { antUtils, source, user, refetchSources } = props;
  const [initialCatalog, setInitialCatalog] = useState<CatalogFile>(null);
  const [newCatalog, setNewCatalog] = useState<CatalogFile>(null);
  const [tableLines, setTableLines] = useState<TableLine[]>([]);
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [catalogTreeVisible, setCatalogTreeVisible] = useState(false);
  const [catalogDebugVisible, setCatalogDebugVisible] = useState(false);
  const [sourceStateDebugVisible, setSourceStateDebugVisible] = useState(false);
  const [bookmarksToResync, setBookmarksToResync] = useState<Bookmark[]>([]);
  const [bookmarkModalOpen, setBookmarkModalOpen] = useState(false);
  const [enableSourcePoller, setEnableSourcePoller] = useState(false);

  const { sourceState, loading: stateIsLoading } = useGetSourceState(source);

  useEffect(() => {
    setNewCatalog(compileTableLinesAsCatalog(tableLines));
  }, [tableLines]);

  useInterval(() => refetchSources(), enableSourcePoller ? 2000 : undefined);
  useEffect(
    () =>
      setEnableSourcePoller(
        source.syncStatus === "syncing" || source.syncStatus === "scheduled"
      ),
    [source]
  );

  const onStreamSelection = useCallback(
    (tableLine: TableLine, checked: boolean) => {
      const parents = tableLine.parentStreamIds;
      const children = tableLine.childrenStreamIds;
      const toSelect: Array<string | number> = [];

      if (checked) {
        // on select :
        // - select item and all currently selected items
        //  - select all parents
        toSelect.push(...selectedRowKeys, tableLine.id);
        parents.forEach((parent) => {
          if (!toSelect.includes(parent)) {
            toSelect.push(parent);
          }
        });
      }

      if (!checked) {
        // on deselect
        // 1 - deselected current
        const toUnselect = [tableLine.id];
        // 2 - get all children
        //     - if selected -> unselect
        children.forEach((c) => {
          toUnselect.push(c);
        });

        // 3 - get parent
        //    - if is hidden and has no select children --> unselect
        //      - repeat
        parents
          .map((parent) => tableLines.find((tl) => tl.id === parent))
          .forEach((parent) => {
            if (
              parent?.isHidden &&
              parent?.childrenStreamIds.every(
                (s) => !selectedRowKeys.includes(s) || toUnselect.includes(s)
              )
            ) {
              toUnselect.push(parent.id);
            }
          });
        const difference = _.difference(selectedRowKeys, toUnselect);
        if (difference && difference.length > 0) {
          toSelect.push(...difference);
        }
      }
      setSelectStreams(toSelect.map((key) => key.toString()));
    },
    [selectedRowKeys, tableLines]
  );

  const onReplicationMethodChange = (streamId: string, value: string[]) => {
    const replicationMethod = value[0] as ReplicationMethod;
    const replicationKey = value[1];

    setTableLines((tableLines) => {
      return [
        ...tableLines.map((tableLine) => {
          if (tableLine.id !== streamId) {
            return {
              ...tableLine,
            };
          } else {
            return {
              ...tableLine,
              replicationKey,
              replicationMethod,
              stream: {
                ...tableLine.stream,
                metadata: tableLine.stream.metadata.map((m) => {
                  if (replicationMethod === "INCREMENTAL") {
                    return {
                      ...m,
                      metadata: {
                        ...m.metadata,
                        "replication-method": replicationMethod,
                        "replication-key": replicationKey,
                      },
                    };
                  } else {
                    return {
                      ...m,
                      metadata: {
                        ..._.omit(m.metadata, "replication-key"),
                        "replication-method": replicationMethod,
                      },
                    };
                  }
                }),
              },
            };
          }
        }),
      ];
    });
  };

  const getTableLineTree = arrayToTree(
    tableLines.map((t) => {
      return {
        title: (
          <Space>
            <Checkbox
              checked={selectedRowKeys.includes(t.id)}
              onChange={(e) => onStreamSelection(t, e.target.checked)}
            />
            <EyeInvisibleOutlined hidden={!t.isHidden} />
            <span>{t.label}</span>
          </Space>
        ),
        key: t.id,
        id: t.id,
        parentId: getStreamMetadataProperty(t.stream, "whaly-parent-stream"),
      };
    }),
    { dataField: null }
  );

  const setSelectStreams = (streamIds: string[]) => {
    setSelectedRowKeys(streamIds);
    setTableLines((tableLines) => {
      return tableLines.map((tableLine) => {
        return {
          ...tableLine,
          stream: {
            ...tableLine.stream,
            metadata: tableLine.stream.metadata.map((m) => {
              // if stream is not selected by user
              if (!streamIds.includes(tableLine.stream.tap_stream_id)) {
                // if stream is selected by default, deselect it
                // or if stream has not default selected value
                // then we deselect it
                if (
                  (typeof m.metadata["selected-by-default"] === "boolean" &&
                    m.metadata["selected-by-default"] === true) ||
                  typeof m.metadata["selected-by-default"] === "undefined"
                ) {
                  return {
                    ...m,
                    metadata: {
                      ...m.metadata,
                      selected: false,
                    },
                  };
                }
                // if stream is deselected by default, leave default
                else {
                  return {
                    ...m,
                    metadata: _.omit(m.metadata, "selected"),
                  };
                }
              }
              // if stream is selected by user
              else {
                // if stream is selected by default, leave default
                if (
                  typeof m.metadata["selected-by-default"] === "boolean" &&
                  m.metadata["selected-by-default"] === true
                ) {
                  return {
                    ...m,
                    metadata: _.omit(m.metadata, "selected"),
                  };
                }
                // if stream is not selected by default
                // or has not default
                // set as selected
                else {
                  return {
                    ...m,
                    metadata: {
                      ...m.metadata,
                      selected: true,
                    },
                  };
                }
              }
            }),
          },
        };
      });
    });
  };

  const columns: ColumnsType<TableLine> = useMemo(
    () => [
      {
        title: (
          <Checkbox
            checked={selectedRowKeys.length === tableLines?.length}
            indeterminate={
              selectedRowKeys.length !== tableLines?.length &&
              selectedRowKeys.length !== 0
            }
            onChange={(e) => {
              if (e.target.checked) {
                return setSelectStreams(
                  tableLines?.map((tableLine) => tableLine.id)
                );
              } else if (e.target.checked === false) {
                return setSelectStreams([]);
              }
            }}
          />
        ),
        key: "select-all",
        width: 50,
        render: (_, record) => (
          <Checkbox
            checked={selectedRowKeys.includes(record.id)}
            onChange={(e) => onStreamSelection(record, e.target.checked)}
          />
        ),
      },
      {
        title: "Tables to sync",
        key: "label",
        sorter: (a, b) => a.label.localeCompare(b.label),
        defaultSortOrder: "ascend",
        ellipsis: true,
        render: (_, record) => (
          <>
            <Space>
              <Text ellipsis>{record.label}</Text>
              {record.description && (
                <Tooltip title={record.description} mouseLeaveDelay={0}>
                  <InfoCircleFilled style={{ color: "lightgray" }} />
                </Tooltip>
              )}
            </Space>
            {getStreamMetadataProperty(record.stream, "row-count") && (
              <>
                <br />
                <Text type="secondary">
                  {getStreamMetadataProperty(record.stream, "row-count")} rows
                </Text>
              </>
            )}
          </>
        ),
      },
      {
        title: "Replication method",
        dataIndex: "replicationMethod",
        key: "replicationMethod",
        width: 220,
        ellipsis: true,
        render: (method: ReplicationMethod, record: TableLine) => {
          interface Option {
            value: string | number;
            label: string;
            children?: Option[];
            disabled?: boolean;
          }

          const options: Option[] = [
            {
              value: "FULL_TABLE",
              label: "Full table",
            },
          ];

          options.push({
            value: "INCREMENTAL",
            label: "Incremental",
            children: [
              ...getStreamAvailableReplicationKeys(record.stream).map(
                (k, i) => {
                  return {
                    value: k,
                    label: k,
                  };
                }
              ),
            ],
          });

          const getTagColor = (method: ReplicationMethod) => {
            switch (method) {
              case "FULL_TABLE":
                return "magenta";
              case "INCREMENTAL":
                return "blue";
            }
          };

          const getMethodLabel = (method: ReplicationMethod) => {
            switch (method) {
              case "FULL_TABLE":
                return "Full table";
              case "INCREMENTAL":
                return "Incremental";
            }
          };

          const renderTag = (editable?: boolean) => {
            const replicationMethod = getStreamReplicationMethod(record.stream);
            const replicationKey = getStreamReplicationKey(record.stream);
            return (
              <Tag
                color={getTagColor(replicationMethod)}
                style={{
                  cursor: canUpdateReplicationMethod(record.stream)
                    ? "pointer"
                    : "auto",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    {getMethodLabel(replicationMethod)}
                    {replicationMethod === "INCREMENTAL" && replicationMethod && (
                      <>
                        <br />
                        <Text type="secondary">{replicationKey}</Text>
                      </>
                    )}
                  </div>
                  {editable && (
                    <div
                      style={{
                        width: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <CaretDownOutlined />
                    </div>
                  )}
                </div>
              </Tag>
            );
          };

          return (
            <Cascader
              options={options}
              defaultValue={[method]}
              allowClear={false}
              disabled={!canUpdateReplicationMethod(record.stream)}
              onChange={(value) => onReplicationMethodChange(record.id, value)}
              style={{
                width: "100%",
              }}
            >
              {renderTag(canUpdateReplicationMethod(record.stream))}
            </Cascader>
          );
        },
      },
    ],
    [onStreamSelection, selectedRowKeys, tableLines]
  );

  const fetchCatalog = useCallback(async () => {
    try {
      if (!source.catalogFileURI) throw new Error("catalogFileURI is missing");
      setLoading(true);
      const resp = await getJSONFile<CatalogFile>(source.catalogFileURI);
      const parsed = parseCatalogAsTableLines(resp);
      const selectedRows = getSelectedRowKeys(parsed);
      setInitialCatalog(resp);
      setTableLines(parsed);
      setSelectedRowKeys(selectedRows);
    } catch (error) {
      console.warn(error);
      antUtils.message.error(
        "Error while loading the schema, please try again"
      );
    } finally {
      setLoading(false);
    }
  }, [antUtils.message, source]);

  useEffect(() => {
    fetchCatalog();
  }, [fetchCatalog]);

  const updateCatalog = async () => {
    try {
      if (!source.catalogFileURI) throw new Error("catalogFileURI is missing");
      setIsUploading(true);
      const formData = new FormData();
      const file = new File([JSON.stringify(newCatalog)], "catalog.json");
      formData.append("file", file);
      await updateFile(source.catalogFileURI, formData);
      antUtils.message.success("Source settings updated");
    } catch (error) {
      antUtils.message.error(
        "Unexpected error: Source settings failed to update"
      );
      console.error(error);
    } finally {
      setIsUploading(false);
      fetchCatalog();
    }
  };

  const renderTitle = (initialCatalog, newCatalog) => {
    const menu: MenuProps = {
      items: [
        {
          label: "View catalog tree",
          onClick: () => setCatalogTreeVisible(true),
          key: 0,
        },
        {
          label: "View catalog diff",
          onClick: () => setCatalogDebugVisible(true),
          key: 1,
        },
        {
          label: "View state",
          onClick: () => setSourceStateDebugVisible(true),
          key: 2,
        },
      ],
    };
    return (
      <>
        Schema{" "}
        {user.isAdmin && (
          <span>
            <Dropdown menu={menu} trigger={["click"]}>
              <BugOutlined />
            </Dropdown>
            <ConfigProvider
              theme={{
                algorithm: theme.darkAlgorithm,
              }}
            >
              <Drawer
                title={"Catalog tree view"}
                placement="bottom"
                width={"100%"}
                height={"100%"}
                open={catalogTreeVisible}
                onClose={() => setCatalogTreeVisible(false)}
                className="source-schema-drawer"
              >
                <div
                  style={{
                    height: "calc(var(--doc-height) - 55px)",
                  }}
                >
                  <Tree
                    expandedKeys={tableLines.map((t) => t.id)}
                    treeData={getTableLineTree}
                    switcherIcon={null}
                    showLine
                    blockNode
                    selectable={false}
                  />
                </div>
              </Drawer>
              <Drawer
                title={"State view"}
                placement="bottom"
                width={"100%"}
                height={"100%"}
                open={sourceStateDebugVisible}
                onClose={() => setSourceStateDebugVisible(false)}
                className="source-schema-drawer"
              >
                <div
                  style={{
                    height: "calc(var(--doc-height) - 55px)",
                  }}
                >
                  <SourceState source={source} />
                </div>
              </Drawer>
              <Drawer
                title={"Catalog diff view"}
                placement="bottom"
                width={"100%"}
                height={"100%"}
                open={catalogDebugVisible}
                onClose={() => setCatalogDebugVisible(false)}
                className="source-schema-drawer"
              >
                <div
                  style={{
                    height: "calc(var(--doc-height) - 55px)",
                  }}
                >
                  <DiffEditor
                    language="json"
                    theme="vs-dark"
                    original={JSON.stringify(initialCatalog, null, 2)}
                    modified={JSON.stringify(newCatalog, null, 2)}
                    options={{
                      automaticLayout: true,
                    }}
                  />
                </div>
              </Drawer>
            </ConfigProvider>
          </span>
        )}
      </>
    );
  };

  const handleStateResync = async () => {
    if (!sourceState) return;

    try {
      if (!source.stateFileURI) throw new Error("stateFileURI is missing");
      setIsUploading(true);

      const allBookmarkKeysToResync = bookmarksToResync.reduce<string[]>(
        (acc, bookmark) => {
          acc.push(bookmark.key, ...bookmark.childrens.map(({ key }) => key));
          return acc;
        },
        []
      );

      const newBookmarks = Object.entries(sourceState.bookmarks).map(
        ([key, replicationConfig]) =>
          allBookmarkKeysToResync.includes(key)
            ? [key, {}]
            : [key, replicationConfig]
      );

      const newState = {
        ...sourceState,
        bookmarks: Object.fromEntries(newBookmarks),
      };

      const formData = new FormData();
      const file = new File([JSON.stringify(newState)], "state.json");

      formData.append("file", file);
      await updateFile(source.stateFileURI, formData);
      await GraphQLService(SYNC_NOW_QUERY, {
        sourceId: source.id,
      });
      await refetchSources();
      antUtils.message.success("A sync with refreshed data has started");
    } catch (error) {
      antUtils.message.error("Unexpected error : Resync has failed");
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  const recursivelyFindChildBookmarks = (
    childrenStreamIds: string[]
  ): ChildBookmark[] => {
    const childBookmarks: ChildBookmark[] = [];

    childrenStreamIds.forEach((streamId) => {
      const tableLine = tableLines.find(
        ({ stream }) => stream.tap_stream_id === streamId
      );

      if (!tableLine) return;

      childBookmarks.push(
        {
          key: streamId,
          label: tableLine.label,
        },
        ...recursivelyFindChildBookmarks(tableLine.childrenStreamIds)
      );
    });

    return childBookmarks;
  };

  const getChildBookmarks = (childrenStreamIds: string[]) => {
    const childBookmarks = recursivelyFindChildBookmarks(childrenStreamIds);

    return childBookmarks.reduce<ChildBookmark[]>((acc, bookmark) => {
      if (!acc.some((b) => b.key === bookmark.key)) {
        return [...acc, bookmark];
      }
      return acc;
    }, []);
  };

  const noBookmarksToResync = bookmarksToResync.length === 0;
  const syncInProgress = ["scheduled", "syncing"].includes(source.syncStatus);
  const bookmarks: Bookmark[] = tableLines
    .filter(({ parentStreamIds }) => parentStreamIds.length === 0)
    .filter(
      ({ stream }) => getStreamReplicationMethod(stream) === "INCREMENTAL"
    )
    .map(({ childrenStreamIds, stream, label }) => ({
      key: stream.tap_stream_id,
      label,
      childrens: getChildBookmarks(childrenStreamIds),
    }));

  const tooltipText = syncInProgress
    ? "A synchronization is currently in progress..."
    : "Pick tables where to refresh and replace the data from the source";

  return (
    <>
      <RouterPrompt when={!isEqual(initialCatalog, newCatalog) && !loading} />
      <CardTable
        cardTitle={renderTitle(initialCatalog, newCatalog)}
        actionButtons={
          <Flex gap="small">
            <Button
              type="primary"
              loading={isUploading}
              disabled={
                isEqual(initialCatalog, newCatalog) || isUploading || loading
              }
              onClick={updateCatalog}
            >
              Save
            </Button>
            <Tooltip title={tooltipText}>
              <Button
                loading={isUploading || syncInProgress}
                disabled={syncInProgress || stateIsLoading || isUploading}
                onClick={() => setBookmarkModalOpen(true)}
              >
                Resync
              </Button>
            </Tooltip>
          </Flex>
        }
        rowClassName={(record) =>
          record.isHidden ? "table-row-is-hidden" : ""
        }
        loading={loading}
        dataSource={tableLines.filter(({ isHidden }) => !isHidden)}
        columns={columns}
        pagination={false}
        className={"source-schema-table"}
      />
      <SourceStateModal
        open={bookmarkModalOpen}
        setOpen={setBookmarkModalOpen}
        disabled={
          noBookmarksToResync || syncInProgress || isUploading || stateIsLoading
        }
        bookmarks={bookmarks}
        bookmarksToResync={bookmarksToResync}
        setBookmarksToResync={setBookmarksToResync}
        onSubmit={() => handleStateResync()}
      />
    </>
  );
}

export default compose<Props, ISourceSchemaProps>(
  WithOrg,
  withAntUtils
)(SourceSchema);
