import type { Filter, Query } from "@cubejs-client/core";
import _ from "lodash";
import { buildQuery } from "../../../../../../../../components/ag-grid/object-table/domain";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { useLagoonQuery } from "../../../../../../../../components/hooks/query/useLagoonQuery";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import type { AvailableColumn } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import { RelatedCollapseContent } from "./RelatedCollapseContent";

interface IRelatedCollapseQueryProps {
  object: IObject;
  filters?: Filter[];
  sortBy?: [string, "asc" | "desc"][];
  labelColumn: string;
  rightColumn?: string;
  availableColumns: AvailableColumn[];
  pageSize: number;
  hidePagination?: boolean;
  secondLineColumns?: string[];
  innerContent?: string;
}

type Props = IRelatedCollapseQueryProps & InjectedOrgProps;

function RelatedCollapseQuery(props: Props) {
  const {
    org,
    object,
    filters,
    sortBy,
    pageSize,
    availableColumns,
    labelColumn,
    rightColumn,
    innerContent,
    secondLineColumns,
  } = props;

  const metrics = availableColumns
    .filter((c) => c.type === "metric")
    .map((c) => c.data.key);
  const dimensions = availableColumns
    .filter((c) => c.type === "property")
    .map((c) => c.data.key);

  const query: Query = {
    measures: metrics,
    dimensions: _.uniq([...dimensions, ...(sortBy ?? []).map((s) => s[0])]),
    filters: filters,
    order: sortBy,
    limit: 1000,
  };

  const q = buildQuery({
    availableColumns: availableColumns,
    object: object,
    tableQuery: {
      groups: [],
      filters: filters ?? [],
      order: sortBy ?? [],
      columnVisibility: {
        visibleColumnIds: availableColumns.map((ac) => ac.data.key),
      },
    },
  });

  const { data, resultSet, loading, error, refetch } = useLagoonQuery(query, {
    org: org,
    enabled: true,
    objectId: object.id,
    objectType: "OBJECT",
  });

  return (
    <div
      style={{
        margin: -12,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        overflow: "hidden",
        paddingTop: 1,
      }}
    >
      <RelatedCollapseContent
        data={data as IRecord[]}
        resultSet={resultSet}
        loading={loading}
        error={error}
        pageSize={pageSize}
        labelColumn={labelColumn}
        rightColumn={rightColumn}
        availableColumns={availableColumns}
        object={object}
        innerContent={innerContent}
        secondLineColumns={secondLineColumns}
      />
    </div>
  );
}

export default compose<Props, IRelatedCollapseQueryProps>(WithOrg)(
  RelatedCollapseQuery
);
