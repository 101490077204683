import type { ResultSet, TimeDimensionGranularity } from "@cubejs-client/core";
import type Highcharts from "highcharts";
import type {
  ChartOption,
  ConfigType,
  chartOptionRenderer,
} from "../../containers/chart-options/ChartOptions";
import type {
  ExtractCustomizationDimension,
  ExtractCustomizationSeries,
  IAnalysisType,
  ILagoonQuery,
  ILagoonQueryExtraKeys,
} from "../../containers/exploration/single/domain";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import type { IMetricType } from "../../interfaces/table";
import type { UserLocale } from "../../interfaces/user";
import type { WlyResultSet } from "../../services/LagoonService";
import { LocaleService } from "../../services/localeService";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../measures/filter-item/FilterItem";
import { activityChartTimeserieDefinition } from "./activity/ActivityChart.definition";
import {
  horizontalBarChartDefinition,
  verticalBarChartDefinition,
} from "./barchart/BarChart.definition";
import { calendarChartDefinition } from "./calendar/CalendarChart.definition";
import { funnelChartDefinition } from "./funnel/Funnel.definition";
import { gaugeChartDefinition } from "./gauge/GaugeChart.definition";
import { mapChartDefinition } from "./geo-map/Map.definition";
import { heatmapChartDefinition } from "./heatmap/Heatmap.definition";
import {
  mapboxBubbleChartDefinition,
  mapboxPinChartDefinition,
} from "./interractive-map/Mapbox.definition";
import { lineChartDefinition } from "./linechart/LineChart.definition";
import { metricChartDefinition } from "./metric/MetricChart.definition";
import { pieChartDefinition } from "./pie/PieChart.definition";
import { retentionChartDefinition } from "./retention/RetentionChart.definition";
import { scatterChartDefinition } from "./scatter/Scatter.definition";
import { stackedAreaChartDefinition } from "./stacked-area/StackedAreaChart.definition";
import { stackedBarChartTimeserieDefinition } from "./stacked-barchart-timeserie/StackedBarChartTimeserie.definition";
import { sunburstChartDefinition } from "./sunburst/Sunburst.definition";
import { tableChartDefinition } from "./table/TableChart.definition";
import { treemapChartDefinition } from "./treemap/Treemap.definition";
import { waterfallChartTimeserieDefinition } from "./waterfall-timeserie/WaterfallTimeserie.definition";
import { waterfallChartDefinition } from "./waterfall/Waterfall.definition";

// A serie is a metric associated with all the dimensions values
export interface ISerieData {
  // Unique identifier for the serie
  key: string;
  // Indicate whether this serie is the "current" one or the "previous" one (in case of date comparaison)
  previous: boolean;
  // Dimensions values, if any, used in the serie
  dimensions: Array<{
    key: string;
    label?: string;
    value?: string;
  }>;
  // Information about the displayed metric
  metric: AvailableMetric;
  // Label to be displayed. Contains information about whether its a previous serie or not and the list of all dimensions values associated to it.
  label: string;
  // Data of the series (array of data points)
  data: Array<ISerieDataContent>;
  // Predictions
  prediction?: Array<IPredictionContent>;
}

export interface IPredictionContent {
  x: string;
  value: string | number;
  high: string | number;
  low: string | number;
}

// A Data content is a a Data point.
// A serie can contain multiple ones.
interface ISerieDataContent {
  // Value to be plotted on the X axis
  x: string;
  // Value of the compared date range
  x2?: string;
  // Value of the point, to be plotted on the Y axis
  value: string | number;
}

export interface Formatter {
  format: IMetricType;
  prefix?: string;
  suffix?: string;
  customFormatting?: string;
}

export interface AxisChartOptionDefinition {
  left: {
    label?: string;
    min?: number;
    max?: number;
    tickInterval?: number;
    customOrder?: string[];
  };
  right: {
    enabled?: boolean;
    label?: string;
    min?: number;
    max?: number;
    tickInterval?: number;
    customOrder?: string[];
  };
  bottom: {
    label?: string;
    timeFormat?: string;
    customOrder?: string[];
  };
}

export type ChartType =
  | "line"
  | "bar"
  | "stacked-barchart-timeserie"
  | "kpi"
  | "scatter"
  | "pie"
  | "table"
  | "bar-horizontal"
  | "stacked-area"
  | "heatmap"
  | "retention"
  | "treemap"
  | "calendar"
  | "waterfall"
  | "waterfall-timeserie"
  | "funnel"
  | "map"
  | "gauge"
  | "interractive-map"
  | "interractive-pin-map"
  | "sunburst"
  | "activity";

type IChartDefinition = {
  [key in ChartType]: SingleChartDefinition;
};

export const CHART_TYPE_WITH_SHOW_OTHERS_FEATURE: ChartType[] = [
  "line",
  "bar",
  "bar-horizontal",
  "pie",
  "table",
  "treemap",
  "waterfall",
  "stacked-barchart-timeserie",
  "stacked-area",
];

export type IChartCSVDownloadOptions = {
  resultSet: AsyncData<ResultSet | WlyResultSet<any>>;
  additionalResultSet?: AsyncData<ResultSet | WlyResultSet<any>> | undefined;
  query: ILagoonQuery;
};

export type IChartCSVDownloadFunction = (options: IChartCSVDownloadOptions) =>
  | {
      headers: string[];
      data: any;
    }
  | undefined;

export interface SingleChartDefinition {
  label: string;
  icon: JSX.Element;
  minDimensions: number;
  maxDimensions?: number;
  minMetrics: number;
  maxMetrics?: number;
  getLabelKey?: (selectedDimensions: string[]) => string;
  getPeriodKey?: (selectedDimensions: string[]) => string;
  getValueKey?: (selectedMeasures: string[]) => string;
  getSizeKey?: (selectedMeasures: string[]) => string;
  allowTimeComparison: boolean;
  allowedGranularities?: TimeDimensionGranularity[];
  pivot: (analysisType: IAnalysisType) => boolean;
  canDisplay: (resultSet: ResultSet, query: ILagoonQuery) => string | undefined;
  downloadCSV?: IChartCSVDownloadFunction;
  canPredict: (
    analysisType: IAnalysisType,
    query: Pick<
      ILagoonQuery,
      "pivotDimensions" | "selectedDimensions" | "comparison"
    >
  ) => boolean;
  queryExtra?: Array<ILagoonQueryExtraKeys>;
  config:
    | Array<ConfigType>
    | ((analysisType: IAnalysisType) => Array<ConfigType>);
  declarativeChartOptionRenderer?: (options: {
    query: ILagoonQuery;
    chartOptions: ChartOption;
  }) => chartOptionRenderer;
  axis: ConfigAxisType;
  extractCustomizationSeries: (
    serieData: ISerieData[],
    tableRows: Array<{ [key: string]: any }>,
    query: ILagoonQuery
  ) => ExtractCustomizationSeries;
  extractCustomizationDimensions: (
    tableRows: Array<{ [key: string]: any }>,
    availableDimensions: AvailableDimension[],
    query: ILagoonQuery
  ) => ExtractCustomizationDimension;
}

export const ChartDefinition: IChartDefinition = {
  kpi: metricChartDefinition,
  gauge: gaugeChartDefinition,
  line: lineChartDefinition,
  bar: verticalBarChartDefinition,
  "stacked-barchart-timeserie": stackedBarChartTimeserieDefinition,
  "bar-horizontal": horizontalBarChartDefinition,
  table: tableChartDefinition,
  funnel: funnelChartDefinition,
  pie: pieChartDefinition,
  sunburst: sunburstChartDefinition,
  heatmap: heatmapChartDefinition,
  retention: retentionChartDefinition,
  scatter: scatterChartDefinition,
  treemap: treemapChartDefinition,
  waterfall: waterfallChartDefinition,
  "waterfall-timeserie": waterfallChartTimeserieDefinition,
  map: mapChartDefinition,
  "interractive-map": mapboxBubbleChartDefinition,
  "interractive-pin-map": mapboxPinChartDefinition,
  calendar: calendarChartDefinition,
  "stacked-area": stackedAreaChartDefinition,
  activity: activityChartTimeserieDefinition,
};

interface ConfigAxisType {
  bottom: {
    editable: boolean;
    isTimeFormattable: (analysisType: IAnalysisType) => boolean;
    reorderable?: (
      serieData: ISerieData[],
      tableRows: Array<{ [key: string]: any }>,
      query: ILagoonQuery
    ) => string[] | undefined;
  };
  left?: {
    editable: boolean;
    scaleEditable?: boolean;
    reorderable?: (
      serieData: ISerieData[],
      tableRows: Array<{ [key: string]: any }>,
      query: ILagoonQuery
    ) => string[] | undefined;
  };
  right?: {
    editable: boolean;
    scaleEditable?: boolean;
    reorderable?: (
      serieData: ISerieData[],
      tableRows: Array<{ [key: string]: any }>,
      query: ILagoonQuery
    ) => string[] | undefined;
  };
}

export const localizeHighchart = (
  highcharts: typeof Highcharts,
  locale: UserLocale
) => {
  const localeService = new LocaleService(locale);
  highcharts.setOptions({
    lang: {
      months: [
        localeService.getMonth("JANUARY"),
        localeService.getMonth("FEBRUARY"),
        localeService.getMonth("MARCH"),
        localeService.getMonth("APRIL"),
        localeService.getMonth("MAY"),
        localeService.getMonth("JUNE"),
        localeService.getMonth("JULY"),
        localeService.getMonth("AUGUST"),
        localeService.getMonth("SEPTEMBER"),
        localeService.getMonth("OCTOBER"),
        localeService.getMonth("NOVEMBER"),
        localeService.getMonth("DECEMBER"),
      ],
      weekdays: [
        localeService.getWeekday("SUNDAY"),
        localeService.getWeekday("MONDAY"),
        localeService.getWeekday("TUESDAY"),
        localeService.getWeekday("WEDNESDAY"),
        localeService.getWeekday("THURSDAY"),
        localeService.getWeekday("FRIDAY"),
        localeService.getWeekday("SATURDAY"),
      ],
      shortMonths: [
        localeService.getShortMonth("JANUARY"),
        localeService.getShortMonth("FEBRUARY"),
        localeService.getShortMonth("MARCH"),
        localeService.getShortMonth("APRIL"),
        localeService.getShortMonth("MAY"),
        localeService.getShortMonth("JUNE"),
        localeService.getShortMonth("JULY"),
        localeService.getShortMonth("AUGUST"),
        localeService.getShortMonth("SEPTEMBER"),
        localeService.getShortMonth("OCTOBER"),
        localeService.getShortMonth("NOVEMBER"),
        localeService.getShortMonth("DECEMBER"),
      ],
    },
  });
};
