import { LoadingOutlined } from "@ant-design/icons";
import { ConfigProvider, Space } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/icon.svg";
import HelpDropdown from "../../../containers/help/HelpDropdown";
import ProfileDropDown from "../../../containers/menu/ProfileDropDown";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import {
  OldOrgLogoV2,
  OrgLogoV2,
} from "../../../containers/v2-demo/container/layout/OrgLogoV2";
import { IUserFeatureType } from "../../../interfaces/user";
import type { UserStoreProps } from "../../../store/userStore";
import type { WorkbenchUIStoreProps } from "../../../store/workbenchUIStore";
import { compose } from "../../compose/WlyCompose";
import UserAvatar from "../../user/avatar/UserAvatar";
import "./StudioLayout.scss";

interface IStudioLayoutProps {
  homePageLink: string;
  titleRenderer: React.ReactNode;
  saving?: boolean;
  additionalClassname?: string;
  additionalButtons?: React.ReactNode[];
  theme?: "WORKBENCH";
  leftButtons?: Array<React.ReactNode>;
  midButtons?: Array<React.ReactNode>;
  rightButtons?: Array<React.ReactNode>;
  children?: React.ReactNode;
}

interface IState {
  hasSuccessfullySaved: boolean;
  isDeleteModalOpen: boolean;
}

type Props = IStudioLayoutProps &
  UserStoreProps &
  InjectedOrgProps &
  WorkbenchUIStoreProps;

class StudioLayout extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasSuccessfullySaved: false,
      isDeleteModalOpen: false,
    };
  }

  componentWillUnmount(): void {
    const style = document.documentElement.style;
    style?.removeProperty?.("--wb-border-color");
    style?.removeProperty?.("--wb-secondary-border-color");
    style?.removeProperty?.("--wb-header-background-color");
    style?.removeProperty?.("--wb-subsider-header-background-color");
    style?.removeProperty?.("--wb-subsider-body-background-color");
    style?.removeProperty?.("--wb-content-background-color");
    style?.removeProperty?.("--wb-toolbar-background-color");
    style?.removeProperty?.("--wb-tabbar-background-color");
    style?.removeProperty?.("--wb-tab-active-background-color");
    style?.removeProperty?.("--wb-tab-inactive-background-color");
    style?.removeProperty?.("--wb-spreadsheet-border-color");
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.saving === true && this.props.saving === false) {
      this.setState(
        {
          hasSuccessfullySaved: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ hasSuccessfullySaved: false });
          }, 1000);
        }
      );
    }
  }

  public render() {
    const {
      homePageLink,
      saving,
      userStore,
      children,
      titleRenderer,
      additionalClassname,
      additionalButtons,
      theme,
      leftButtons,
      midButtons,
      rightButtons,
      userFeatures,
    } = this.props;

    const { hasSuccessfullySaved } = this.state;

    const useNewLogoComponent = userFeatures.includes(
      IUserFeatureType.TMP_NEW_WORKSPACE_MENU
    );

    const renderMenu = () => {
      if (theme === "WORKBENCH") {
        return (
          <div className="menu-header">
            <div className="menu-header-inner">
              <div className="menu-header-left">
                <div className="logo-wrapper">
                  {useNewLogoComponent ? (
                    <OrgLogoV2 logo={this.props.org.logo} />
                  ) : (
                    <OldOrgLogoV2
                      logo={this.props.org.logo}
                      open={!!this.props.workbenchUIStore.selector}
                      onClick={() => {
                        this.props.workbenchUIStore.toggleSelector();
                      }}
                    />
                  )}
                </div>
                <div className="menu-wrapper">
                  <Space size={"small"} style={{ height: 50 }}>
                    {(leftButtons || []).map((lb, i) => {
                      return <React.Fragment key={i}>{lb}</React.Fragment>;
                    })}
                  </Space>
                </div>
              </div>
              <div className="menu-header-mid">
                {(midButtons || []).map((mb, i) => {
                  return <React.Fragment key={i}>{mb}</React.Fragment>;
                })}
              </div>
              <div className="menu-header-right">
                <Space>
                  {(rightButtons || []).map((rb, i) => {
                    return <React.Fragment key={i}>{rb}</React.Fragment>;
                  })}
                </Space>
              </div>
            </div>
          </div>
        );
      }

      return (
        <>
          <div className="menu-header">
            <div className="menu-header-inner">
              <div className="menu-header-left">
                <div className="block">
                  <div className="inline">
                    <Link to={homePageLink}>
                      <img src={Logo} className="logo" alt="Whaly Logo" />
                    </Link>
                  </div>
                  {saving && (
                    <div className="inline left loading-feedback">
                      <LoadingOutlined />
                      &#8239;Saving&#8239;...
                    </div>
                  )}
                  {hasSuccessfullySaved && (
                    <div className="inline left loading-feedback">
                      All changes saved&#8239;...
                    </div>
                  )}
                </div>
              </div>
              <div className="menu-header-mid" />
              <div className="menu-header-right">
                {additionalButtons}
                <div>
                  <div className="help-button">
                    <HelpDropdown placement="bottomLeft">help</HelpDropdown>
                  </div>
                </div>
                <div>
                  <ProfileDropDown placement="bottomLeft">
                    <UserAvatar size={"small"} user={userStore.user} />
                  </ProfileDropDown>
                </div>
              </div>
            </div>
          </div>
          <div className="title-header">
            <div className="title">{titleRenderer}</div>
          </div>
        </>
      );
    };

    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3a5c83",
            colorBorderSecondary: "#d8d8d8",
            colorBgLayout: "white",
          },
          components: {
            Dropdown: {
              controlItemBgActive: "#E7ECEF",
              controlItemBgActiveHover: "rgba(0, 0, 0, 0.04)",
            },
          },
        }}
      >
        <div
          className={`studio-layout ${
            theme === "WORKBENCH" ? "workbench" : ""
          }`}
        >
          {renderMenu()}
          <div
            className={`main-content ${
              additionalClassname ? additionalClassname : ""
            }`}
          >
            {children}
          </div>
        </div>
      </ConfigProvider>
    );
  }
}

export default compose<Props, IStudioLayoutProps>(WithOrg)(
  inject("userStore", "workbenchUIStore")(observer(StudioLayout))
);
