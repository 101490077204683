import { Space } from "antd";
import * as React from "react";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../filter-item/FilterItem";
import type { DraggableMeasure } from "../measure-item/DraggableMeasureItem";
import { DraggableMeasureItem } from "../measure-item/DraggableMeasureItem";
import type { MeasureItemSortValue } from "../measure-item/MeasureItem";
import MeasurePicker from "../measure-picker/MeasurePicker";
import type { MeasureType } from "../measure-table/MeasureTable";

interface IMeasureSortProps {
  measures?: Array<AvailableMetric | AvailableDimension>;
  value?: Array<[string, MeasureItemSortValue]>;
  onChange?: (value: Array<[string, MeasureItemSortValue]>) => any;
}

const MeasureSort: React.FunctionComponent<IMeasureSortProps> = (props) => {
  const { onChange } = props;
  const measures = props.measures ? props.measures : [];
  const value = props.value ? props.value : [];

  const availableMeasures = measures
    .filter((measure) => !value.map((v) => v[0]).includes(measure?.key))
    .filter((m) => m);

  const getMeasureType = (
    m: AvailableMetric | AvailableDimension
  ): MeasureType => {
    if (m?.["type"]) return "dimension";
    if (m?.["domain"]) return "dimension";
    if (m?.["formatter"]) return "metric";
    return "metric";
  };

  const isMeasureAvailable = (key: string): boolean => {
    return !!measures.find((m) => m.key === key);
  };

  const onMeasureDelete = (key: string) => {
    if (!onChange) return;
    const newValue = [...value.filter((v) => v[0] !== key)];
    onChange(newValue);
  };

  const onMeasureAdd = (key: string, position?: number) => {
    if (!isMeasureAvailable(key) || !onChange) return;
    const newValue = [...value];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, [key, "asc"]);
    onChange(newValue);
  };

  const onMeasureDrop = (key: string, position: number) => {
    if (typeof position !== "number" || !onChange) return;
    const order = value.find((v) => v[0] === key)[1];
    const newValue = [...value.filter((v) => v[0] !== key)];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, [key, order]);
    onChange(newValue);
  };

  const onMeasureSort = (key: string, order: MeasureItemSortValue) => {
    if (!onChange) return;

    const newValue = value.map((v) => {
      if (v[0] === key) {
        const k = v[0];
        const o = order;

        const item: [string, MeasureItemSortValue] = [k, o];
        return item;
      } else {
        return v;
      }
    });
    onChange(newValue);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={2}>
      {value.map((sm, i) => {
        const measure = measures.find((measure) => measure?.key === sm[0]);
        const onDelete = () => {
          onMeasureDelete(sm[0]);
        };
        const onDrop = (item: DraggableMeasure, position: number) => {
          onMeasureDrop(item.id, position);
        };
        const onSort = (s: MeasureItemSortValue) => {
          onMeasureSort(sm[0], s);
        };
        return (
          <DraggableMeasureItem
            key={sm[0]}
            id={sm[0]}
            zone={"sort"}
            index={i}
            onDrop={onDrop}
            onRemove={onDelete}
            type={getMeasureType(measure)}
            isDeleted={!!!measure}
            description={measure?.description}
            name={measure?.label}
            onDelete={onDelete}
            sort={sm[1]}
            onSort={onSort}
            accept={["dimension", "metric"]}
          />
        );
      })}
      {value.length > 0 && <div style={{ height: 8 }} />}
      <MeasurePicker
        availableMeasures={availableMeasures}
        selectedMeasureKeys={value.map((m) => m[0])}
        onMeasureChange={(i) => onMeasureAdd(i)}
        disabled={false}
        block
      >
        Add
      </MeasurePicker>
    </Space>
  );
};

export default MeasureSort;
