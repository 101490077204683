import {
  CaretRightOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Flex, Tooltip, Tree } from "antd";
import type { DataNode, EventDataNode } from "antd/lib/tree";
import _ from "lodash";
import type { NodeDragEventParams } from "rc-tree/lib/contextTypes";
import type { Key } from "rc-tree/lib/interface";
import * as React from "react";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import type { IReport } from "../../../interfaces/reports";
import type { FolderEditInitialData } from "../edit/FolderEditForm";
import { FolderEditForm } from "../edit/FolderEditForm";

import { inject, observer } from "mobx-react";
import { createPortal } from "react-dom";
import FolderShareForm from "../../../containers/folders/share/FolderShareForm";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import ReportCreationModal from "../../../containers/reports/creation/ReportCreationModal";
import { hasRoleAccessBoolean } from "../../../containers/user-settings/HasRoleAccess";
import { ClosableContent } from "../../../containers/v2-demo/component/layout/ClosableContent";
import type { IReportFolder } from "../../../interfaces/folder";
import { IOrgFeatureType } from "../../../interfaces/org";
import { IUserFeatureType, IUserRoleType } from "../../../interfaces/user";
import type { WorkspaceUIStoreProps } from "../../../store/workspaceUIStore";
import type { InjectedAntUtilsProps } from "../../ant-utils/withAntUtils";
import { withAntUtils } from "../../ant-utils/withAntUtils";
import { compose } from "../../compose/WlyCompose";
import "./Folder.scss";
import { FolderItem } from "./FolderItem";

const UNSELECTABLE_KEY_PREFIX = `unselectable-`;

interface IFolderProps {
  name?: string;
  hideTitle?: boolean;
  setSelectedSlug: (s: string, e: MouseEvent) => void;
  folders: IReportFolder[];
  onChange?: (values: FolderChanges) => Promise<any>;
  onCreate?: (v: FolderCreateData) => Promise<IReportFolder>;
  onEdit?: (v: {
    id: string;
    name: string;
    image: string;
    color: string;
  }) => Promise<IReportFolder>;
  onShare: () => Promise<void>;
  onDelete?: (ids: string[]) => Promise<any>;
  isDragging: boolean;
  onDropReport: (r: IReport, f: IReportFolder) => Promise<any>;
  setDragging: (d: boolean) => void;
  removeDragNDrop?: boolean;
  setKeyHovered?: () => string;
  personalFolder?: IReportFolder;
}

interface FolderChanges {
  [key: string]: Partial<
    Omit<IReportFolder, "parent"> & {
      parent?: { operation: "CONNECT" | "DISCONNECT"; id: string };
    }
  >;
}

interface FolderCreateData extends FolderEditInitialData {
  order: number;
}

type Props = IFolderProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  WorkspaceUIStoreProps;

let chgs: FolderChanges = {};

const BFolder = (props: Props) => {
  const { userFeatures } = props;

  if (userFeatures.includes(IUserFeatureType.TMP_NEW_WORKSPACE_MENU)) {
    return <NewFolder {...props} />;
  } else {
    return <OldFolder {...props} />;
  }
};

const NewFolder = compose(
  inject("workspaceUIStore"),
  observer
)((props: Props) => {
  const {
    antUtils,
    onChange,
    isDragging,
    onDropReport,
    onEdit,
    user,
    org,
    orgFeatures,
    setDragging,
    removeDragNDrop,
    setSelectedSlug,
    workspaceUIStore,
    personalFolder,
  } = props;

  const {
    drawerTitleActionsDomNode,
    selectedFolderId,
    setSelectedFolder,
    setDrawerType,
  } = workspaceUIStore;

  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<Key[]>([]);
  const [folders, setFolders] = React.useState<IReportFolder[]>(props.folders);

  // todo learn how to publish changes to the central data store
  React.useEffect(() => {
    if (!_.isEqual(props.folders, folders)) {
      setFolders(props.folders);
    }
  }, [props.folders]);

  // Edit form
  const [editFormVisible, setEditFormVisible] = React.useState<
    boolean | string
  >(false);
  const [editInitialData, setEditInitialData] = React.useState<
    Partial<IReportFolder> | undefined
  >();

  // Share form
  const [shareFormState, setShareFormState] = React.useState<{
    visible: boolean;
    userCanManageFolder: boolean;
    orgHasFeature: boolean;
  }>({ visible: false, userCanManageFolder: false, orgHasFeature: false });

  const [dragKey, setDragKey] = React.useState<string | undefined>();
  const [keyHovered, setKeyHovered] = React.useState<string>("");

  const hasCreateEditDeleteRight = hasRoleAccessBoolean(
    IUserRoleType.EDITOR,
    user,
    org.id
  );

  async function onValueChange() {
    await onChange(chgs);
  }

  const getFolderFromKey = (key: Key): IReportFolder => {
    let newKey: Key = key;
    if (typeof key === `string` && key.startsWith(UNSELECTABLE_KEY_PREFIX)) {
      newKey = key.replace(UNSELECTABLE_KEY_PREFIX, ``);
    }
    const folder = folders.find((folder) => folder.id === newKey);
    if (!folder) return null;
    return folder;
  };

  const verifyKey = (folderId: string): Key => {
    const folder = folders.find((folder) => folder.id === folderId);
    if (!folder) return null;
    return folder.id;
  };

  const onSelect = (
    selectedKeysValue: React.Key[],
    info: {
      event: "select";
      selected: boolean;
      node: EventDataNode<DataNode>;
      selectedNodes: DataNode[];
      nativeEvent: MouseEvent;
    }
  ) => {
    const folder = getFolderFromKey(selectedKeysValue[0]);
    if (!selectedKeysValue.length && selectedFolderId && !folder) {
      // the user is clicking on an already selected key
      const selectedFolder = getFolderFromKey(selectedFolderId);
      setSelectedSlug(selectedFolder.slug, info.nativeEvent);
    }
    if (!folder) return;
    setSelectedSlug(folder.slug, info.nativeEvent);
    setSelectedFolder(folder.id);
    setDrawerType(undefined);
  };

  const computeOpenKeys = (s: string) => {
    const t: string[] = [];
    const folder = folders.find((f) => f.id === s);
    const loop = (reportFolder: IReportFolder) => {
      t.push(reportFolder.id);
      const parent = folders.find(
        (f) => reportFolder.parent && reportFolder.parent.id === f.id
      );
      if (parent) {
        loop(parent);
      }
    };
    if (folder) {
      loop(folder);
    }
    return t;
  };

  React.useEffect(() => {
    const key = verifyKey(selectedFolderId);
    key ? setSelectedKeys([key]) : setSelectedKeys([]);
    setOpenKeys(
      [...openKeys, ...computeOpenKeys(selectedFolderId)].filter(
        (v, i, s) => s.indexOf(v) === i
      )
    );
  }, [selectedFolderId]);

  const defaultSelectedKeys = (): Key[] => {
    const key = verifyKey(selectedFolderId);
    if (!key) return [];
    return [key];
  };

  const debouncedChange = React.useCallback(_.debounce(onValueChange, 1000), [
    chgs,
  ]);

  const onDelete = (id: string) => {
    const idsToDelete: string[] = [];
    const getAllIdsToDelete = (parentId: string) => {
      const leftFolders = folders.filter(
        (f) => f.parent && f.parent.id === parentId
      );
      leftFolders.forEach((lf) => {
        idsToDelete.push(lf.id);
        getAllIdsToDelete(lf.id);
      });
    };
    idsToDelete.push(id);
    getAllIdsToDelete(id);
    antUtils.modal.confirm({
      title: "Are you sure?",
      content: `You are about to delete ${idsToDelete.length} folders. This operation cannot be undone. Do you wish to proceed?`,
      okButtonProps: {
        danger: true,
      },
      okText: "Delete",
      onOk: () => {
        setFolders(folders.filter((f) => !idsToDelete.includes(f.id)));
        return props.onDelete(idsToDelete);
      },
      onCancel: () => {},
    });
  };

  const numberOfRoot = folders.filter((fol) => !fol.parent);

  const buildTree = (parentId?: string): DataNode[] => {
    const buildTreeNode = (reportFolder: IReportFolder): DataNode => {
      const orgHasFeature = !!orgFeatures.includes(
        IOrgFeatureType.GRANULAR_REPORT_FOLDER_SHARING
      );

      const userCanEditFolder = reportFolder.canBeEditedByCurrentUser;
      const userCanManageFolder = reportFolder.canBeManagedByCurrentUser;
      const userIsFromOrgRealm = !!org.realm;

      const id = userCanManageFolder
        ? `${reportFolder.id}`
        : `${UNSELECTABLE_KEY_PREFIX}${reportFolder.id}`;

      const children = folders.filter(
        (fol) => fol.parent && fol.parent.id === reportFolder.id
      );

      const title = (
        <FolderItem
          color={reportFolder.color}
          name={reportFolder.name}
          emoji={reportFolder.image}
          id={id}
          onCreate={
            hasCreateEditDeleteRight && props.onCreate
              ? () => setEditFormVisible(reportFolder.id)
              : undefined
          }
          userCanEditFolder={userCanEditFolder}
          userCanShareFolder={userIsFromOrgRealm}
          onShare={() => {
            setShareFormState({
              visible: true,
              userCanManageFolder,
              orgHasFeature,
            });
            setEditInitialData(reportFolder);
          }}
          onEdit={
            hasCreateEditDeleteRight && props.onEdit
              ? () => {
                  setEditFormVisible(true);
                  setEditInitialData(reportFolder);
                }
              : undefined
          }
          onDelete={
            hasCreateEditDeleteRight &&
            props.onDelete &&
            !(numberOfRoot.length === 1 && !reportFolder.parent)
              ? () => onDelete(reportFolder.id)
              : undefined
          }
          setOpen={() => setOpenKeys([...openKeys, reportFolder.id])}
          onDrop={(r) => onDropReport(r, reportFolder)}
          setKeyHovered={setKeyHovered}
        />
      );
      const style: React.CSSProperties =
        dragKey === reportFolder.id ? { opacity: 0.5 } : { opacity: 1 };
      const className = keyHovered === id ? "dragging-report-inside" : "";
      if (children && children.length) {
        return {
          title: title,
          key: id,
          style: style,
          className: className,
          selectable: true,
          isLeaf: false,
          children: buildTree(reportFolder.id),
        };
      } else {
        return {
          title: title,
          key: id,
          style: style,
          selectable: true,
          className: className,
          switcherIcon: (
            <CaretRightOutlined
              style={{ color: "rgba(23,25,28,0.4)", fontSize: 10 }}
            />
          ),
          // children: [
          //   {
          //     key: `${UNSELECTABLE_KEY_PREFIX}${id}`,
          //     title: "No folders inside",
          //     disabled: true,
          //     isLeaf: true,
          //     style: style,
          //   },
          // ],
        };
      }
    };

    if (parentId) {
      const childrenFolders = folders.filter(
        (f) => f.parent && f.parent.id === parentId
      );
      const treeNodes = childrenFolders
        .sort((a, b) => a.order - b.order)
        .map(buildTreeNode);
      return treeNodes;
    }
    const initialFolders = folders.filter((f) => !f.parent);
    const treeNodes = initialFolders
      .sort((a, b) => a.order - b.order)
      .map(buildTreeNode);
    return treeNodes;
  };

  const treeData: DataNode[] = buildTree();

  const onDrop = (
    info: NodeDragEventParams<DataNode, HTMLDivElement> & {
      dragNode: EventDataNode<DataNode>;
      dragNodesKeys: Key[];
      dropPosition: number;
      dropToGap: boolean;
    }
  ) => {
    const dropNode = info.node;
    const dragNode = info.dragNode;
    const dropPos = info.node.pos.split("-");
    const dropIndex = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    let dropPosition = info.dropPosition;

    // dropKey === key on which we dropped the node
    // dragKey === key that was dragged
    // technically dropKey should be the parent but since you can only drop on a node
    // rc-tree has added another info dropPosition
    // dropIndex is supposed to give you a clue on where the mouse was
    // info.dropposition is supposed to give you the index at which the node was dropped

    const touches: FolderChanges = {};
    let droppingOn: IReportFolder | undefined;

    if (dropIndex < 0) {
      // dropIndex === -1
      // =================== mouse position
      // DROP NODE
      const droppedFolder = folders.find((d) => d.id === dropNode.key);
      const draggedFolder = folders.find((d) => d.id === dragNode.key);
      droppingOn = droppedFolder.parent?.id
        ? folders.find((d) => d.id === droppedFolder.parent.id)
        : undefined;

      // console.debug(
      //   "putting",
      //   draggedFolder.name,
      //   "above",
      //   droppedFolder.name,
      //   "we should attach",
      //   draggedFolder.name,
      //   "to",
      //   droppedFolder.parent?.id ? droppedFolder.parent.id : "root",
      //   "at position",
      //   dropPosition === -1 ? 0 : dropPosition,
      //   "with info",
      //   info
      // );
    } else if (dropIndex === 0) {
      // DROP NODE ========= mouse position
      const droppedFolder = folders.find((d) => d.id === dropNode.key);
      const draggedFolder = folders.find((d) => d.id === dragNode.key);
      droppingOn = droppedFolder;
      dropPosition = 0;

      // console.debug(
      //   "putting",
      //   draggedFolder.name,
      //   "on top of ",
      //   droppedFolder.name,
      //   "we should attach",
      //   draggedFolder.name,
      //   "to",
      //   droppedFolder.name,
      //   "at position",
      //   dropPosition,
      //   "with info",
      //   info
      // );
    } else if (dropIndex > 0) {
      // DROP NODE
      // =================== mouse position
      const droppedFolder = folders.find((d) => d.id === dropNode.key);
      const draggedFolder = folders.find((d) => d.id === dragNode.key);
      droppingOn = droppedFolder.parent?.id
        ? folders.find((d) => d.id === droppedFolder.parent.id)
        : undefined;

      // console.debug(
      //   "putting",
      //   draggedFolder.name,
      //   "bellow",
      //   droppedFolder.name,
      //   "we should attach",
      //   draggedFolder.name,
      //   "to",
      //   droppedFolder.parent?.id ? droppedFolder.parent.id : "root",
      //   "at position",
      //   dropPosition,
      //   "with info",
      //   info
      // );
    }

    // we recompute the neighbors keys in the same order as the user see them
    const neighborKeys: string[] = folders
      .filter((f) => f.parent?.id === droppingOn?.id)
      .sort((o, s) => o.order - s.order)
      .map((f) => f.id);

    if (neighborKeys.includes(dragNode.key as string)) {
      if (neighborKeys.indexOf(dragNode.key as string) < dropPosition) {
        dropPosition = dropPosition - 1;
      }
    }
    const newNeighborKeys = neighborKeys.filter((f) => f !== dragNode.key);
    newNeighborKeys.splice(dropPosition, 0, dragNode.key as string);

    folders.forEach((f) => {
      if (f.id === dragNode.key) {
        touches[f.id] = {
          order: dropPosition,
          parent: droppingOn
            ? {
                operation: "CONNECT",
                id: droppingOn.id,
              }
            : f.parent && f.parent.id
            ? {
                operation: "DISCONNECT",
                id: f.parent.id,
              }
            : undefined,
        };
      }
      if (newNeighborKeys.includes(f.id) && f.id !== dragNode.key) {
        const order = newNeighborKeys.indexOf(f.id);
        touches[f.id] = {
          order: order,
        };
      }
      return f;
    });
    setFolders(
      folders.map((f) => {
        const { parent, ...rest } = f;
        if (touches[f.id]) {
          return {
            ...rest,
            ...touches[f.id],
            parent:
              touches[f.id].parent?.operation === "CONNECT"
                ? {
                    id: touches[f.id].parent?.id,
                  }
                : touches[f.id].parent?.operation === "DISCONNECT"
                ? null
                : parent,
          } as any;
        }
        return f;
      })
    );

    const newChanges = _.cloneDeep(chgs);
    Object.keys(touches).forEach((t) => {
      newChanges[t] = {
        ...newChanges[t],
        ...touches[t],
      };
    });
    chgs = newChanges;
    debouncedChange();
  };

  const onCreate = (v: FolderEditInitialData) => {
    const order = folders.filter((f) =>
      v.parentId ? f.parent && f.parent.id === v.parentId : !f.parent
    ).length;
    return props
      .onCreate({ ...v, order: order })
      .then((r) => {
        setFolders([...folders, r]);
      })
      .catch(handleGQLErrors);
  };

  const draggable =
    !removeDragNDrop && hasCreateEditDeleteRight && !isDragging
      ? (i: DataNode) => !i.key.toString().startsWith(UNSELECTABLE_KEY_PREFIX)
      : undefined;

  return (
    <ReportCreationModal>
      {(open) => (
        <Flex gap="small" vertical>
          {hasCreateEditDeleteRight &&
            props.onCreate &&
            drawerTitleActionsDomNode &&
            createPortal(
              <Dropdown
                placement="bottomLeft"
                menu={{
                  items: [
                    {
                      key: "folder",
                      label: "Folder",
                      onClick: (e) => {
                        e.domEvent.stopPropagation();
                        setEditFormVisible(true);
                      },
                    },
                    {
                      key: "dashboard",
                      label: "Dashboard",
                      onClick: (e) => {
                        e.domEvent.stopPropagation();
                        open();
                      },
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <Button
                  shape="circle"
                  type="text"
                  style={{
                    alignSelf: "flex-end",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  icon={<PlusOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Dropdown>,
              drawerTitleActionsDomNode
            )}
          <div className={`folder-tree v2 new`} data-tour-ws-folders>
            <Tree
              onExpand={(expandedKeys) => {
                if (!dragKey) {
                  setOpenKeys(expandedKeys.map((k) => k.toString()));
                }
              }}
              expandedKeys={openKeys}
              selectedKeys={selectedKeys}
              defaultSelectedKeys={defaultSelectedKeys()}
              blockNode
              selectable
              onSelect={onSelect}
              className="folder-items new"
              draggable={draggable}
              onDragStart={
                hasCreateEditDeleteRight
                  ? (i) => {
                      setDragKey(i.node.key as string);
                      setDragging(true);
                    }
                  : undefined
              }
              onDragEnd={
                hasCreateEditDeleteRight
                  ? () => {
                      setDragKey(undefined);
                      setDragging(false);
                    }
                  : undefined
              }
              onDrop={
                hasCreateEditDeleteRight
                  ? (i) => {
                      onDrop(i);
                      setDragKey(undefined);
                      setDragging(false);
                    }
                  : undefined
              }
              allowDrop={(dropOptions) => {
                return (
                  !dropOptions.dropNode.key
                    .toString()
                    .startsWith("unselectable-") &&
                  folders
                    .map((f) => f.id)
                    .includes(dropOptions.dragNode.key.toString())
                );
              }}
              treeData={treeData}
            />

            {personalFolder && (
              <div
                className={`folder-items new personal-folder ${
                  selectedFolderId === personalFolder?.id ? "selected" : ""
                } ${
                  keyHovered === personalFolder.id
                    ? "dragging-report-inside"
                    : ""
                }`}
                onClick={(e) => {
                  setSelectedSlug(personalFolder.slug, e.nativeEvent);
                  setSelectedFolder(personalFolder.id);
                  setDrawerType(undefined);
                }}
              >
                <FolderItem
                  id={personalFolder.id}
                  emoji=":red-circle:"
                  name="Personal Folder"
                  userCanEditFolder={false}
                  userCanShareFolder={false}
                  setKeyHovered={setKeyHovered}
                  onShare={() => null}
                  isPersonal
                  setOpen={() => null}
                  onDrop={(r) => onDropReport(r, personalFolder)}
                />
              </div>
            )}

            <FolderEditForm
              onCancel={() => {
                setEditFormVisible(false);
                setEditInitialData(undefined);
              }}
              onEdit={(v) => {
                setFolders(
                  folders.map((f) => {
                    if (f.id === v.id) {
                      return {
                        ...f,
                        ...v,
                      };
                    }
                    return f;
                  })
                );
                return onEdit(v as any).then(() =>
                  setEditInitialData(undefined)
                );
              }}
              parentId={
                typeof editFormVisible === "string"
                  ? editFormVisible
                  : undefined
              }
              onCreate={onCreate}
              visible={!!editFormVisible}
              initialData={editInitialData as any}
            />

            <FolderShareForm
              reportFolder={editInitialData as any}
              onClose={() => {
                setShareFormState({
                  visible: false,
                  userCanManageFolder: false,
                  orgHasFeature: false,
                });
                setEditInitialData(undefined);
              }}
              visible={!!shareFormState.visible}
              canBeManagedByCurrentUser={shareFormState.userCanManageFolder}
              orgHasFeature={shareFormState.orgHasFeature}
            />
          </div>
        </Flex>
      )}
    </ReportCreationModal>
  );
});

const OldFolder = compose(
  inject("workspaceUIStore"),
  observer
)((props: Props) => {
  const {
    antUtils,
    onChange,
    isDragging,
    onDropReport,
    onEdit,
    user,
    org,
    orgFeatures,
    name,
    setDragging,
    removeDragNDrop,
    setSelectedSlug,
    workspaceUIStore: { selectedFolderId, setSelectedFolder },
    hideTitle,
    personalFolder,
  } = props;
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = React.useState<Key[]>([]);
  const [folders, setFolders] = React.useState<IReportFolder[]>(props.folders);

  // todo learn how to publish changes to the central data store
  React.useEffect(() => {
    if (!_.isEqual(props.folders, folders)) {
      setFolders(props.folders);
    }
  }, [props.folders]);

  // Edit form
  const [editFormVisible, setEditFormVisible] = React.useState<
    boolean | string
  >(false);
  const [editInitialData, setEditInitialData] = React.useState<
    Partial<IReportFolder> | undefined
  >();

  // Share form
  const [shareFormState, setShareFormState] = React.useState<{
    visible: boolean;
    userCanManageFolder: boolean;
    orgHasFeature: boolean;
  }>({ visible: false, userCanManageFolder: false, orgHasFeature: false });

  const [dragKey, setDragKey] = React.useState<string | undefined>();
  const [hover, setHover] = React.useState<boolean | string>(false);
  const [keyHovered, setKeyHovered] = React.useState<string>("");

  const hasCreateEditDeleteRight = hasRoleAccessBoolean(
    IUserRoleType.EDITOR,
    user,
    org.id
  );

  async function onValueChange() {
    await onChange(chgs);
  }

  const getFolderFromKey = (key: Key): IReportFolder => {
    let newKey: Key = key;
    if (typeof key === `string` && key.startsWith(UNSELECTABLE_KEY_PREFIX)) {
      newKey = key.replace(UNSELECTABLE_KEY_PREFIX, ``);
    }
    const folder = folders.find((folder) => folder.id === newKey);
    if (!folder) return null;
    return folder;
  };

  const verifyKey = (folderId: string): Key => {
    const folder = folders.find((folder) => folder.id === folderId);
    if (!folder) return null;
    return folder.id;
  };

  const onSelect = (
    selectedKeysValue: React.Key[],
    info: {
      event: "select";
      selected: boolean;
      node: EventDataNode<DataNode>;
      selectedNodes: DataNode[];
      nativeEvent: MouseEvent;
    }
  ) => {
    const folder = getFolderFromKey(selectedKeysValue[0]);
    if (!selectedKeysValue.length && selectedFolderId && !folder) {
      // the user is clicking on an already selected key
      const selectedFolder = getFolderFromKey(selectedFolderId);
      setSelectedSlug(selectedFolder.slug, info.nativeEvent);
    }
    if (!folder) return;
    setSelectedSlug(folder.slug, info.nativeEvent);
    setSelectedFolder(folder.id);
  };

  const computeOpenKeys = (s: string) => {
    const t: string[] = [];
    const folder = folders.find((f) => f.id === s);
    const loop = (reportFolder: IReportFolder) => {
      t.push(reportFolder.id);
      const parent = folders.find(
        (f) => reportFolder.parent && reportFolder.parent.id === f.id
      );
      if (parent) {
        loop(parent);
      }
    };
    if (folder) {
      loop(folder);
    }
    return t;
  };

  React.useEffect(() => {
    const key = verifyKey(selectedFolderId);
    key ? setSelectedKeys([key]) : setSelectedKeys([]);
    setOpenKeys(
      [...openKeys, ...computeOpenKeys(selectedFolderId)].filter(
        (v, i, s) => s.indexOf(v) === i
      )
    );
  }, [selectedFolderId]);

  const defaultSelectedKeys = (): Key[] => {
    const key = verifyKey(selectedFolderId);
    if (!key) return [];
    return [key];
  };

  const debouncedChange = React.useCallback(_.debounce(onValueChange, 1000), [
    chgs,
  ]);

  const onDelete = (id: string) => {
    const idsToDelete: string[] = [];
    const getAllIdsToDelete = (parentId: string) => {
      const leftFolders = folders.filter(
        (f) => f.parent && f.parent.id === parentId
      );
      leftFolders.forEach((lf) => {
        idsToDelete.push(lf.id);
        getAllIdsToDelete(lf.id);
      });
    };
    idsToDelete.push(id);
    getAllIdsToDelete(id);
    antUtils.modal.confirm({
      title: "Are you sure?",
      content: `You are about to delete ${idsToDelete.length} folders. This operation cannot be undone. Do you wish to proceed?`,
      okButtonProps: {
        danger: true,
      },
      okText: "Delete",
      onOk: () => {
        setFolders(folders.filter((f) => !idsToDelete.includes(f.id)));
        return props.onDelete(idsToDelete);
      },
      onCancel: () => {},
    });
  };

  const numberOfRoot = folders.filter((fol) => !fol.parent);

  const buildTree = (parentId?: string): DataNode[] => {
    const buildTreeNode = (reportFolder: IReportFolder): DataNode => {
      const orgHasFeature = !!orgFeatures.includes(
        IOrgFeatureType.GRANULAR_REPORT_FOLDER_SHARING
      );

      const userCanEditFolder = reportFolder.canBeEditedByCurrentUser;
      const userCanManageFolder = reportFolder.canBeManagedByCurrentUser;
      const userIsFromOrgRealm = !!org.realm;

      const id = userCanManageFolder
        ? `${reportFolder.id}`
        : `${UNSELECTABLE_KEY_PREFIX}${reportFolder.id}`;

      const children = folders.filter(
        (fol) => fol.parent && fol.parent.id === reportFolder.id
      );

      const title = (
        <FolderItem
          color={reportFolder.color}
          name={reportFolder.name}
          emoji={reportFolder.image}
          id={id}
          onCreate={
            hasCreateEditDeleteRight && props.onCreate
              ? () => setEditFormVisible(reportFolder.id)
              : undefined
          }
          userCanEditFolder={userCanEditFolder}
          userCanShareFolder={userIsFromOrgRealm}
          onShare={() => {
            setShareFormState({
              visible: true,
              userCanManageFolder,
              orgHasFeature,
            });
            setEditInitialData(reportFolder);
          }}
          onEdit={
            hasCreateEditDeleteRight && props.onEdit
              ? () => {
                  setEditFormVisible(true);
                  setEditInitialData(reportFolder);
                }
              : undefined
          }
          onDelete={
            hasCreateEditDeleteRight && props.onDelete
              ? numberOfRoot.length === 1 && !reportFolder.parent
                ? undefined
                : () => onDelete(reportFolder.id)
              : undefined
          }
          setOpen={() => setOpenKeys([...openKeys, reportFolder.id])}
          onDrop={(r) => onDropReport(r, reportFolder)}
          setKeyHovered={setKeyHovered}
        />
      );
      const style: React.CSSProperties =
        dragKey === reportFolder.id ? { opacity: 0.5 } : { opacity: 1 };
      const className = keyHovered === id ? "dragging-report-inside" : "";
      if (children && children.length) {
        return {
          title: title,
          key: id,
          style: style,
          className: className,
          selectable: true,
          isLeaf: false,
          children: buildTree(reportFolder.id),
        };
      } else {
        return {
          title: title,
          key: id,
          style: style,
          selectable: true,
          className: className,
          switcherIcon: (
            <CaretRightOutlined
              style={{ color: "rgba(23,25,28,0.4)", fontSize: 10 }}
            />
          ),
          // children: [
          //   {
          //     key: `${UNSELECTABLE_KEY_PREFIX}${id}`,
          //     title: "No folders inside",
          //     disabled: true,
          //     isLeaf: true,
          //     style: style,
          //   },
          // ],
        };
      }
    };

    if (parentId) {
      const childrenFolders = folders.filter(
        (f) => f.parent && f.parent.id === parentId
      );
      const treeNodes = childrenFolders
        .sort((a, b) => a.order - b.order)
        .map(buildTreeNode);
      return treeNodes;
    }
    const initialFolders = folders.filter((f) => !f.parent);
    const treeNodes = initialFolders
      .sort((a, b) => a.order - b.order)
      .map(buildTreeNode);
    return treeNodes;
  };

  const treeData: DataNode[] = buildTree();

  const onDrop = (
    info: NodeDragEventParams<DataNode, HTMLDivElement> & {
      dragNode: EventDataNode<DataNode>;
      dragNodesKeys: Key[];
      dropPosition: number;
      dropToGap: boolean;
    }
  ) => {
    const dropNode = info.node;
    const dragNode = info.dragNode;
    const dropPos = info.node.pos.split("-");
    const dropIndex = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    let dropPosition = info.dropPosition;

    // dropKey === key on which we dropped the node
    // dragKey === key that was dragged
    // technically dropKey should be the parent but since you can only drop on a node
    // rc-tree has added another info dropPosition
    // dropIndex is supposed to give you a clue on where the mouse was
    // info.dropposition is supposed to give you the index at which the node was dropped

    const touches: FolderChanges = {};
    let droppingOn: IReportFolder | undefined;

    if (dropIndex < 0) {
      // dropIndex === -1
      // =================== mouse position
      // DROP NODE
      const droppedFolder = folders.find((d) => d.id === dropNode.key);
      const draggedFolder = folders.find((d) => d.id === dragNode.key);
      droppingOn = droppedFolder.parent?.id
        ? folders.find((d) => d.id === droppedFolder.parent.id)
        : undefined;

      // console.debug(
      //   "putting",
      //   draggedFolder.name,
      //   "above",
      //   droppedFolder.name,
      //   "we should attach",
      //   draggedFolder.name,
      //   "to",
      //   droppedFolder.parent?.id ? droppedFolder.parent.id : "root",
      //   "at position",
      //   dropPosition === -1 ? 0 : dropPosition,
      //   "with info",
      //   info
      // );
    } else if (dropIndex === 0) {
      // DROP NODE ========= mouse position
      const droppedFolder = folders.find((d) => d.id === dropNode.key);
      const draggedFolder = folders.find((d) => d.id === dragNode.key);
      droppingOn = droppedFolder;
      dropPosition = 0;

      // console.debug(
      //   "putting",
      //   draggedFolder.name,
      //   "on top of ",
      //   droppedFolder.name,
      //   "we should attach",
      //   draggedFolder.name,
      //   "to",
      //   droppedFolder.name,
      //   "at position",
      //   dropPosition,
      //   "with info",
      //   info
      // );
    } else if (dropIndex > 0) {
      // DROP NODE
      // =================== mouse position
      const droppedFolder = folders.find((d) => d.id === dropNode.key);
      const draggedFolder = folders.find((d) => d.id === dragNode.key);
      droppingOn = droppedFolder.parent?.id
        ? folders.find((d) => d.id === droppedFolder.parent.id)
        : undefined;

      // console.debug(
      //   "putting",
      //   draggedFolder.name,
      //   "bellow",
      //   droppedFolder.name,
      //   "we should attach",
      //   draggedFolder.name,
      //   "to",
      //   droppedFolder.parent?.id ? droppedFolder.parent.id : "root",
      //   "at position",
      //   dropPosition,
      //   "with info",
      //   info
      // );
    }

    // we recompute the neighbors keys in the same order as the user see them
    const neighborKeys: string[] = folders
      .filter((f) => f.parent?.id === droppingOn?.id)
      .sort((o, s) => o.order - s.order)
      .map((f) => f.id);

    if (neighborKeys.includes(dragNode.key as string)) {
      if (neighborKeys.indexOf(dragNode.key as string) < dropPosition) {
        dropPosition = dropPosition - 1;
      }
    }
    const newNeighborKeys = neighborKeys.filter((f) => f !== dragNode.key);
    newNeighborKeys.splice(dropPosition, 0, dragNode.key as string);

    folders.forEach((f) => {
      if (f.id === dragNode.key) {
        touches[f.id] = {
          order: dropPosition,
          parent: droppingOn
            ? {
                operation: "CONNECT",
                id: droppingOn.id,
              }
            : f.parent && f.parent.id
            ? {
                operation: "DISCONNECT",
                id: f.parent.id,
              }
            : undefined,
        };
      }
      if (newNeighborKeys.includes(f.id) && f.id !== dragNode.key) {
        const order = newNeighborKeys.indexOf(f.id);
        touches[f.id] = {
          order: order,
        };
      }
      return f;
    });
    setFolders(
      folders.map((f) => {
        const { parent, ...rest } = f;
        if (touches[f.id]) {
          return {
            ...rest,
            ...touches[f.id],
            parent:
              touches[f.id].parent?.operation === "CONNECT"
                ? {
                    id: touches[f.id].parent?.id,
                  }
                : touches[f.id].parent?.operation === "DISCONNECT"
                ? null
                : parent,
          } as any;
        }
        return f;
      })
    );

    const newChanges = _.cloneDeep(chgs);
    Object.keys(touches).forEach((t) => {
      newChanges[t] = {
        ...newChanges[t],
        ...touches[t],
      };
    });
    chgs = newChanges;
    debouncedChange();
  };

  const onCreate = (v: FolderEditInitialData) => {
    const order = folders.filter((f) =>
      v.parentId ? f.parent && f.parent.id === v.parentId : !f.parent
    ).length;
    return props
      .onCreate({ ...v, order: order })
      .then((r) => {
        setFolders([...folders, r]);
      })
      .catch(handleGQLErrors);
  };

  const wrap = (n: JSX.Element) => {
    return (
      <ReportCreationModal>
        {(open) => (
          <ClosableContent
            name={"Dashboards"}
            actionButton={
              hasCreateEditDeleteRight && props.onCreate ? (
                <Dropdown
                  placement="bottomLeft"
                  menu={{
                    items: [
                      {
                        key: "folder",
                        label: "Folder",
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                          setEditFormVisible(true);
                        },
                      },
                      {
                        key: "dashboard",
                        label: "Dashboard",
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                          open();
                        },
                      },
                    ],
                  }}
                  trigger={["click"]}
                >
                  <Button
                    size="small"
                    shape="circle"
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </Dropdown>
              ) : undefined
            }
            items={n}
          />
        )}
      </ReportCreationModal>
    );
  };

  return wrap(
    <div
      className={`folder-tree v2 old`}
      data-tour-ws-folders
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!hideTitle && (
        <div className="folder-tree-title">
          <div className="folder-tree-title-main">
            {name ? name : "Workspace"}
          </div>
          {hasCreateEditDeleteRight && props.onCreate && (
            <div>
              {hover ? (
                <Tooltip placement="top" title="Add folder" mouseLeaveDelay={0}>
                  <PlusCircleOutlined
                    onClick={() => setEditFormVisible(true)}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      )}
      <Tree
        onExpand={(expandedKeys) => {
          if (!dragKey) {
            setOpenKeys(expandedKeys.map((k) => k.toString()));
          }
        }}
        expandedKeys={openKeys}
        selectedKeys={selectedKeys}
        defaultSelectedKeys={defaultSelectedKeys()}
        blockNode={true}
        selectable={true}
        onSelect={onSelect}
        className="folder-items old"
        draggable={
          !removeDragNDrop
            ? hasCreateEditDeleteRight
              ? isDragging
                ? false
                : (i) => {
                    return !(i as any as DataNode).key
                      .toString()
                      .startsWith(UNSELECTABLE_KEY_PREFIX);
                  }
              : false
            : false
        }
        onDragStart={
          hasCreateEditDeleteRight
            ? (i) => {
                setDragKey(i.node.key as string);
                setDragging(true);
              }
            : undefined
        }
        onDragEnd={
          hasCreateEditDeleteRight
            ? (i) => {
                setDragKey(undefined);
                setDragging(false);
              }
            : undefined
        }
        onDrop={
          hasCreateEditDeleteRight
            ? (i) => {
                onDrop(i);
                setDragKey(undefined);
                setDragging(false);
              }
            : undefined
        }
        allowDrop={(dropOptions) => {
          return (
            !dropOptions.dropNode.key.toString().startsWith("unselectable-") &&
            folders
              .map((f) => f.id)
              .includes(dropOptions.dragNode.key.toString())
          );
        }}
        treeData={treeData}
      />
      {personalFolder && (
        <div
          className={`folder-items old personal-folder ${
            selectedFolderId === personalFolder?.id ? "selected" : ""
          } ${
            keyHovered === personalFolder.id ? "dragging-report-inside" : ""
          }`}
          onClick={(e) => {
            setSelectedSlug(personalFolder.slug, e.nativeEvent);
            setSelectedFolder(personalFolder.id);
          }}
        >
          <FolderItem
            id={personalFolder.id}
            emoji=":red-circle:"
            name="Personal Folder"
            userCanEditFolder={false}
            userCanShareFolder={false}
            setKeyHovered={setKeyHovered}
            onShare={() => null}
            isPersonal
            setOpen={() => null}
            onDrop={(r) => onDropReport(r, personalFolder)}
          />
        </div>
      )}
      <FolderEditForm
        onCancel={() => {
          setEditFormVisible(false);
          setEditInitialData(undefined);
        }}
        onEdit={(v) => {
          setFolders(
            folders.map((f) => {
              if (f.id === v.id) {
                return {
                  ...f,
                  ...v,
                };
              }
              return f;
            })
          );
          return onEdit(v as any).then(() => setEditInitialData(undefined));
        }}
        parentId={
          typeof editFormVisible === "string" ? editFormVisible : undefined
        }
        onCreate={onCreate}
        visible={!!editFormVisible}
        initialData={editInitialData as any}
      />
      <FolderShareForm
        reportFolder={editInitialData as any}
        onClose={() => {
          setShareFormState({
            visible: false,
            userCanManageFolder: false,
            orgHasFeature: false,
          });
          setEditInitialData(undefined);
        }}
        visible={!!shareFormState.visible}
        canBeManagedByCurrentUser={shareFormState.userCanManageFolder}
        orgHasFeature={shareFormState.orgHasFeature}
      />
    </div>
  );
});

export const Folder = compose<Props, IFolderProps>(
  WithOrg,
  withAntUtils,
  inject("workspaceUIStore"),
  observer
)(BFolder);
