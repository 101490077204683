import { Button, Space } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../components/compose/WlyCompose";
import {
  ResizableDrawerBody,
  ResizableDrawerHeader,
} from "../../../../../../components/resizable/Drawer";
import type { IExploration } from "../../../../../../interfaces/explorations";
import { routeDescriptor } from "../../../../../../routes/routes";
import ExplorationEditor from "../../../../../exploration/single/ExplorationEditor";
import type { ILagoonQuery } from "../../../../../exploration/single/domain";

interface IChartCreateTileDrawerProps {
  visible: boolean;
  setVisible: (b: boolean) => void;
  explorationSlug: string;
  onSave: (q: ILagoonQuery, e: IExploration) => Promise<void>;
  onCancel?: (e: IExploration) => Promise<void>;
  embededWorkbench?: boolean;
}

type Props = IChartCreateTileDrawerProps & RouteComponentProps<{}>;

function ChartCreateTileDrawer(props: Props) {
  const {
    explorationSlug,
    setVisible,
    visible,
    match: { params },
  } = props;

  const [currentQuery, setCurrentQuery] = React.useState<
    ILagoonQuery | undefined
  >();
  const [isStale, setIsStale] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string | undefined>();
  const [exploration, setExploration] = React.useState<
    IExploration | undefined
  >();
  const [saving, setSaving] = React.useState<boolean>(false);

  const onCreate = () => {
    if (exploration && currentQuery) {
      setSaving(true);
      props
        .onSave(currentQuery, exploration)
        .then(() => {
          setVisible(false);
          setSaving(false);
        })
        .catch(() => setSaving(false));
    }
  };

  return (
    <>
      <ResizableDrawerHeader
        title={!title ? "Loading..." : title}
        extra={
          visible ? (
            <Space>
              <Button
                onClick={onCreate}
                disabled={isStale || saving}
                loading={saving}
                type="primary"
              >
                Add tile
              </Button>
            </Space>
          ) : undefined
        }
        onClose={async () => {
          if (props.onCancel) {
            await props.onCancel(exploration);
          }
          setVisible(false);
        }}
      />
      <ResizableDrawerBody>
        <ExplorationEditor
          isEmbeded={true}
          liftExploration={(e) => {
            setExploration(e);
            setTitle(`Creating a tile: ${e.name}`);
          }}
          injectedExplorationSlug={explorationSlug}
          previousRoute={routeDescriptor.reportTileExplorationCreate.renderRoute(
            {
              ...params,
            }
          )}
          getCurrentQuery={(
            isStale,
            analysisType,
            chartType,
            measures,
            dimensions,
            filters,
            filterOperator,
            dateRange,
            orderBy,
            limit,
            showOther,
            showOtherDimensionLimit,
            comparison,
            timeDimension,
            selectedGranularity,
            pivotConfig,
            chartOptions,
            forecast,
            extra
          ) => {
            setIsStale(isStale);
            setCurrentQuery({
              selectedMeasures: measures,
              selectedDimensions: dimensions,
              filters: filters as any,
              filterOperator: filterOperator,
              selectedGranularity: selectedGranularity,
              dateRange: dateRange,
              comparison: comparison,
              selectedTime: timeDimension,
              analysisType: analysisType,
              orderBy: orderBy,
              limit: limit,
              showOther: showOther,
              showOtherDimensionLimit: showOtherDimensionLimit,
              pivotDimensions: pivotConfig,
              chartOptions: chartOptions,
              chartType: chartType,
              forecast: forecast,
              extra: extra,
            });
          }}
          initialQueryLoad={true}
        />
      </ResizableDrawerBody>
    </>
  );
}

export default compose<Props, IChartCreateTileDrawerProps>(withRouter)(
  ChartCreateTileDrawer
);
