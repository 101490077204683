import type { Filter } from "@cubejs-client/core";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
} from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IRelatedCollapseConfig } from "../domain";
import RelatedCollapseQuery from "./RelatedCollapseQuery";

interface IRecordRelatedCollapseProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IRelatedCollapseConfig;
  edit?: boolean;
}

type Props = InjectedOrgProps & IRecordRelatedCollapseProps;

function RecordRelatedCollapse(props: Props) {
  const { conf, record, widget, object, edit } = props;

  if (!conf.foreignObjectPropertyId) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Missing configuration</Feednack>
      </div>
    );
  }

  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!foreignProperty) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Can't find foreign property</Feednack>
      </div>
    );
  }

  if (!conf.options?.label?.length) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Please add a label column</Feednack>
      </div>
    );
  }

  const foreignObject = foreignProperty.object;

  const property = foreignObject.properties.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!property) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Property must be defined</Feednack>
      </div>
    );
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );
  const cubeName = object.table.cubeName;
  const recordFilters = [
    {
      member: `${foreignAvailable.key}_raw`,
      operator: "equals",
      values: [record[`${cubeName}.id`] as string],
    },
  ];
  const availableColumns = getObjectColumns(foreignObject);
  const additionalFilters = conf.options.filters;
  const sortBy = conf.options?.sortBy ?? [];
  const hidePagination = conf.options?.hidePagination;
  const labelColumn = conf.options?.label[0];
  const rightColumn = conf.options?.right?.[0];

  const filters = [
    {
      and: [
        ...recordFilters,
        additionalFilters
          ? { [additionalFilters.operator]: additionalFilters.filters }
          : { and: [] },
      ],
    },
  ] as Filter[];

  return (
    <RelatedCollapseQuery
      object={foreignObject}
      availableColumns={availableColumns}
      pageSize={conf.options.pageSize || 10}
      filters={filters}
      sortBy={sortBy}
      hidePagination={hidePagination}
      labelColumn={labelColumn}
      rightColumn={rightColumn}
      secondLineColumns={conf.options?.secondLineColumns}
      innerContent={conf.options?.innerContent}
    />
  );
}

export default compose<Props, IRecordRelatedCollapseProps>(WithOrg)(
  RecordRelatedCollapse
);
