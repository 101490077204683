import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { useMount } from "react-use";
import { compose } from "../../components/compose/WlyCompose";
import { track } from "../../services/AnalyticsService";
import type { BreadcrumbStoreProps } from "../../store/breadCrumbStore";
import type { WorkspaceUIStoreProps } from "../../store/workspaceUIStore";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import "./SettingsWrapper.scss";

interface ISettingsWrapperProps {
  selectedKey?: string;
  children?: React.ReactNode;
}

type Props = ISettingsWrapperProps &
  InjectedOrgProps &
  RouteComponentProps<{}> &
  BreadcrumbStoreProps &
  WorkspaceUIStoreProps;

const SettingsWrapper = ({ children, workspaceUIStore }: Props) => {
  useMount(() => {
    track("Org Settings Viewed");
    workspaceUIStore.setSiderClosable(false);
  });

  return <>{children}</>;
};

export default compose<Props, ISettingsWrapperProps>(
  WithOrg,
  withRouter,
  inject("workspaceUIStore"),
  observer
)(SettingsWrapper);
