import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import Markdoc from "@markdoc/markdoc";
import {
  Button,
  Collapse,
  ConfigProvider,
  Divider,
  Space,
  Typography,
} from "antd";
import * as React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import type { AvailableColumn } from "../../../../../object/domain";
import { parseLabel, type IRecord } from "../../../../domain";
import {
  convertKeyToMarkdocVariable,
  getParsedDoc,
} from "../../../common/markdoc/domain";
import {
  additionalComponents,
  nodesConfig,
} from "../../markdown/widget/markdoc-react-component/config";
import PropertyRenderer from "../../properties/widget/PropertyRenderer";

export interface IRelatedCOllapseSingleItemProps {
  labelColumn: string;
  rightColumn?: string;
  secondLineColumns?: string[];
  innerContent?: string;
  data: IRecord;
  availableColumns: AvailableColumn[];
  object: IObject;
}

type Props = IRelatedCOllapseSingleItemProps & InjectedOrgProps;

function RelatedCOllapseSingleItem(props: Props) {
  const {
    labelColumn,
    rightColumn,
    data,
    availableColumns,
    object,
    secondLineColumns,
    innerContent,
    user,
    org,
  } = props;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const rightFoundColumn = availableColumns.find(
    (c) => c.data.key === rightColumn
  );

  const labelFoundColumns = availableColumns.find(
    (ac) => ac.data.key === labelColumn
  );

  const extractLabelValue = (): { img?: string; name: string } => {
    if (
      labelFoundColumns?.type === "property" &&
      (labelFoundColumns.data.type === "foreignKey" ||
        labelFoundColumns.data.type === "primaryKey")
    ) {
      const { id, name, image } = parseLabel(
        (data[labelColumn] as string) ?? ""
      );
      return {
        name: name ?? "",
        img: image,
      };
    }
    return {
      name: (data[labelColumn] as string) ?? "",
    };
  };

  const l = extractLabelValue();

  const getDocument = (source: string): React.ReactNode => {
    const formattedRecord = Object.keys(data).reduce((acc, v) => {
      return {
        ...acc,
        [convertKeyToMarkdocVariable(v)]: data[v],
      };
    }, {});

    const content = getParsedDoc(
      source,
      formattedRecord,
      user,
      availableColumns,
      nodesConfig
    );

    const html = Markdoc.renderers.react(content, React, {
      ...additionalComponents,
    });

    return html;
  };

  const secondLineContentCols = availableColumns.filter((ac) =>
    (secondLineColumns ?? []).includes(ac.data.key)
  );

  return (
    <Typography.Paragraph style={{ marginBottom: 0 }}>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              headerPadding: 0,
              contentPadding: `6px 6px`,
            },
          },
        }}
      >
        <Collapse
          ghost
          className="wly_toggle"
          style={{ marginTop: 0 }}
          onChange={() => {
            setIsOpen((r) => !r);
          }}
          items={[
            {
              key: "1",
              label: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 12,
                    padding: `6px`,
                  }}
                >
                  {l.img && (
                    <div style={{ flex: `0 48px` }}>
                      <img
                        style={{
                          width: 48,
                          height: 48,
                          borderRadius: 8,
                        }}
                        src={l.img}
                      />
                    </div>
                  )}
                  <div style={{ flex: 1 }}>
                    <div>
                      <Typography.Text
                        style={{ flex: `0 auto`, marginBottom: 0 }}
                        strong
                      >
                        {l.name}
                      </Typography.Text>
                    </div>
                    {secondLineColumns && secondLineColumns.length ? (
                      <Space>
                        {secondLineContentCols.map((s) => {
                          return (
                            <PropertyRenderer
                              key={s.data.key}
                              column={s}
                              object={object}
                              value={data[s.data.key ?? ""]}
                            />
                          );
                        })}
                      </Space>
                    ) : null}
                  </div>
                  {rightFoundColumn && (
                    <div style={{ flex: `0 auto` }}>
                      <PropertyRenderer
                        column={rightFoundColumn}
                        object={object}
                        value={data[rightColumn ?? ""]}
                      />
                    </div>
                  )}
                  <div style={{ flex: `0 auto` }}>
                    <Button
                      shape="circle"
                      icon={
                        isOpen ? <CaretDownOutlined /> : <CaretRightOutlined />
                      }
                      type="text"
                    />
                  </div>
                </div>
              ),
              showArrow: false,
              children: innerContent ? (
                <div style={{ paddingTop: 6 }} className="markdown-widget">
                  {getDocument(innerContent)}
                </div>
              ) : (
                <div style={{ paddingTop: 6 }}>
                  <i>No content</i>
                </div>
              ),
            },
          ]}
        />
        <Divider style={{ margin: `0` }} />
      </ConfigProvider>
    </Typography.Paragraph>
  );
}

export default compose<Props, IRelatedCOllapseSingleItemProps>(WithOrg)(
  RelatedCOllapseSingleItem
);
