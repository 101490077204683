import type { FormInstance } from "antd";
import { Skeleton } from "antd";
import React from "react";
import type { IObject } from "../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../interfaces/org";
import { type IDataset } from "../../../../../../interfaces/sources";
import type { IRecord } from "../../domain";
import type { IWidget, IWidgetType } from "../domain";
import { widgetSeparatorDefinition } from "./Separator/SeparatorWidget.definition";
import { widgetAlertDefinition } from "./alert/AlertWidget.definition";
import { widgetButtonsDefinition } from "./buttons/ButtonWidget.definition";
import { generatedTextDefinition } from "./generated-text/GeneratedText.definition";
import { widgetInlineObjectiveDefinition } from "./inline-objective/InlineObjectiveWidget.definition";
import { widgetInsightDefinition } from "./insights/InsightWidget.definition";
import { widgetKPIDefinition } from "./kpi/WidgetKPI.definition";
import { widgetMapDefinition } from "./map/WidgetMap.definition";
import { widgetMarkdownDefinition } from "./markdown/MarkdownWidget.definition";
import { widgetPropertyDefinition } from "./properties/WidgetProperties.definition";
import { widgetRelatedListDefinition } from "./related-lists/WidgetRelatedList.definition";
import { widgetRepeatableDefinition } from "./repeatable/RepeatableWidget.definition";
import { widgetSuggestedProductsDefinition } from "./suggested-products/WidgetSuggestedProducts.definition";
import { widgetTabsDefinition } from "./tabs/TabsWidget.definition";
import { widgetTitleDefinition } from "./title/TitleWidget.definition";
import { widgetRelatedCollapseDefinition } from "./related-collapse/WidgetRelatedCollapse.definition";

export interface WidgetStateConfig {
  key: string;
  label: string;
  values: string[];
}

export interface IRendererConfig<T extends BaseConfig> {
  parseConfig: (widget: IWidget, object: IObject) => T;
  removeCard?: boolean;
  allowCardRemoval?: boolean;
  renderEditor: (
    widget: IWidget,
    object: IObject,
    record: IRecord,
    conf: T,
    form: FormInstance,
    org: IOrg,
    datasets: IDataset[]
  ) => React.ReactElement;
  renderComponent: (
    widget: IWidget,
    object: IObject,
    record: IRecord,
    conf: T,
    edit: boolean,
    state: string | undefined,
    setState: (value: string) => void
  ) => React.ReactElement;
  renderLoadingComponent: (widget: IWidget, conf: T) => React.ReactElement;
  extractStateConfig?: (
    widget: IWidget,
    object: IObject,
    conf: T,
    edit: boolean
  ) => WidgetStateConfig;
}

export interface BaseConfig {
  _wly?: {
    removeCard?: boolean;
  };
}

const notImplemented: IRendererConfig<BaseConfig> = {
  parseConfig: (widget: IWidget, object: IObject) => ({}),
  renderEditor: (
    widget: IWidget,
    object: IObject,
    record: IRecord,
    conf: BaseConfig
  ) => {
    return <div>Not implemented</div>;
  },
  renderComponent: (
    widget: IWidget,
    object: IObject,
    record: IRecord,
    conf: BaseConfig
  ) => {
    return <div>Not implemented</div>;
  },
  renderLoadingComponent: (widget, conf) => (
    <Skeleton.Button active block shape="round" style={{ height: 250 }} />
  ),
};

export const rendererConfig: { [key in IWidgetType]: IRendererConfig<any> } = {
  properties: widgetPropertyDefinition,
  kpi: widgetKPIDefinition,
  chart: notImplemented,
  actions: notImplemented,
  map: widgetMapDefinition,
  markdown: widgetMarkdownDefinition,
  relatedList: widgetRelatedListDefinition,
  repeatable: widgetRepeatableDefinition,
  alert: widgetAlertDefinition,
  separator: widgetSeparatorDefinition,
  suggestedProducts: widgetSuggestedProductsDefinition,
  buttons: widgetButtonsDefinition,
  title: widgetTitleDefinition,
  "generated-text": generatedTextDefinition,
  tabs: widgetTabsDefinition,
  insights: widgetInsightDefinition,
  "inline-objectives": widgetInlineObjectiveDefinition,
  "relatedCollapse": widgetRelatedCollapseDefinition
};
