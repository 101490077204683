import { action, makeObservable, observable, runInAction } from "mobx";
import type { IObject } from "../interfaces/object";

// TODO: Delete all code marked with // when TMP_NEW_WORKSPACE_MENU feature flag is removed

type ShareObjectModalOpenParams = IObject | undefined;
type DrawerType = "lists" | "dashboards" | undefined;
type OverrideSiderState = "OPEN" | "CLOSED" | "USER_DEFINED"; //

class WorkspaceUIStore {
  siderOpen: boolean; //
  private overrideSiderOpen: OverrideSiderState = "USER_DEFINED"; //
  private userSiderOpen: boolean = true; //
  siderClosable: boolean = true; //
  drawerType: DrawerType = undefined;
  drawerTitleActionsDomNode: HTMLDivElement | undefined = undefined;
  drawerLabel: string = "";
  shareObjectModalOpen: ShareObjectModalOpenParams;
  siderSize: number = 240;
  selectedFolderId: string | undefined;
  hideRightHeader: boolean = false;
  globalSearchOpen: boolean = false;

  constructor() {
    makeObservable(this, {
      siderOpen: observable, //
      siderClosable: observable, //
      drawerType: observable,
      drawerTitleActionsDomNode: observable,
      drawerLabel: observable,
      shareObjectModalOpen: observable,
      globalSearchOpen: observable,
      siderSize: observable,
      selectedFolderId: observable,
      hideRightHeader: observable,
      setSiderOpen: action, //
      setSiderOverrideState: action, //
      setSiderClosable: action, //
      setDrawerType: action,
      setDrawerTitleActionsDomNode: action,
      setDrawerLabel: action,
      setRightHeaderVisible: action,
      setSiderSize: action,
      setSelectedFolder: action,
      setGlobalSearchOpen: action,
      setShareObjectModalOpen: action,
    });

    this.siderOpen = this.getSiderState(); //

    if (document.body.getBoundingClientRect()?.width <= 992) {
      this.userSiderOpen = false;
    } //
  }

  getSiderState = () => {
    if (this.overrideSiderOpen === "CLOSED") {
      return false;
    } else if (this.overrideSiderOpen === "OPEN") {
      return true;
    } else {
      return this.userSiderOpen;
    }
  }; //

  setSiderOpen = () => {
    this.userSiderOpen = !this.userSiderOpen;
    this.siderOpen = this.getSiderState();
  }; //

  setSiderOverrideState = (value: OverrideSiderState) => {
    this.overrideSiderOpen = value;
    this.siderOpen = this.getSiderState();
  }; //

  setSiderClosable = (closable: boolean) => {
    this.siderClosable = closable;
    if (!closable && !this.siderOpen) {
      this.siderOpen = true;
    }
    this.siderOpen = this.getSiderState();
  }; //

  setRightHeaderVisible = (visible: boolean) => {
    this.hideRightHeader = !visible;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 50);
  };

  setDrawerType = (drawerType: DrawerType) => {
    this.drawerType = drawerType;
    this.setDrawerLabel(drawerType);
  };

  setDrawerTitleActionsDomNode = (domNode: HTMLDivElement) => {
    this.drawerTitleActionsDomNode = domNode;
  };

  setDrawerLabel = (drawerType: DrawerType) => {
    switch (drawerType) {
      case "lists":
        this.drawerLabel = "Lists";
        break;
      case "dashboards":
        this.drawerLabel = "Dashboards";
        break;
    }
  };

  setGlobalSearchOpen = (open: boolean) => {
    this.globalSearchOpen = open;
  };

  setSiderSize = (siderSize: number) => {
    this.siderSize = siderSize;
  };

  setSelectedFolder = (folderId?: string) => {
    this.selectedFolderId = folderId;
  };

  setShareObjectModalOpen = (params: ShareObjectModalOpenParams) => {
    runInAction(() => {
      this.shareObjectModalOpen = params;
    });
  };
}

export interface WorkspaceUIStoreProps {
  workspaceUIStore: WorkspaceUIStore;
}

export default new WorkspaceUIStore();
