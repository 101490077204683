import { historyField } from "@codemirror/commands";
import { sql } from "@codemirror/lang-sql";
import { foldState, syntaxHighlighting } from "@codemirror/language";
import { EditorView } from "@codemirror/view";
import ReactCodeMirror from "@uiw/react-codemirror";
import { debounce } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import { sqlFoldGutter } from "./codemirrorPlugins/foldGutter";
import { onScrollPlugin } from "./codemirrorPlugins/onScroll";
import { showLeadingWhitespaces } from "./codemirrorPlugins/showLeadingWhitespaces";
import type { SQLAutocompleteItem } from "./codemirrorPlugins/sqlAutocomplete";
import { sqlAutocomplete } from "./codemirrorPlugins/sqlAutocomplete";
import { sqlErrorGutter } from "./codemirrorPlugins/sqlErrorGutter";
import { sqlFoldingService } from "./codemirrorPlugins/sqlFoldingService";
import { sqlFunctionsTooltip } from "./codemirrorPlugins/sqlFunctionsTooltip";
import { sqlIndentService } from "./codemirrorPlugins/sqlIndentService";
import { sqlHighlightStyle } from "./codemirrorPlugins/sqlSyntaxHighlighting";
import { SQLEditorTheme } from "./codemirrorPlugins/sqlTheme";
import { sqlVariablesHighlight } from "./codemirrorPlugins/sqlVariablesHighlight";

interface ISQLEditorProps {
  value: string;
  overrideValue: string | undefined;
  height: number;
  sqlVariables?: SQLAutocompleteItem[];
  autocompleteValues?: SQLAutocompleteItem[];
  errorData?: AsyncData<{
    records: any;
    count: number;
    schema: SchemaResult;
  }>;
  initialState?: any;
  initialScrollLeft?: number;
  initialScrollTop?: number;
  readOnly?: boolean;
  warehouseType?: string;
  onEditorStateChange?: (stateUpdate?: any) => void;
  onChange?: (value: string) => void;
  onDatasetClick?: (datasetId: string) => void;
  onEditorScroll?: (scrollTop: number, scrollLeft: number) => void;
}

const stateFields = { history: historyField, foldState: foldState };

const MemoizedCodeMirror = memo(ReactCodeMirror);

export const SQLEditor = ({
  value,
  overrideValue,
  height,
  sqlVariables,
  autocompleteValues,
  errorData,
  initialState,
  initialScrollLeft,
  initialScrollTop,
  readOnly,
  warehouseType,
  onChange,
  onDatasetClick,
  onEditorScroll,
  onEditorStateChange,
}: ISQLEditorProps) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (overrideValue !== undefined) {
      setInternalValue(overrideValue);
    }
  }, [overrideValue]);

  const debouncedOnChange = useMemo(
    () => debounce(onChange ?? (() => {}), 200),
    [onChange]
  );
  const debouncedStateUpdate = useMemo(
    () => debounce(onEditorStateChange ?? (() => {}), 200),
    [onEditorStateChange]
  );

  const extensions = useMemo(() => {
    return [
      sql(),
      sqlFoldGutter,
      ...(warehouseType
        ? [sqlAutocomplete(autocompleteValues ?? [], warehouseType)]
        : []),
      sqlErrorGutter(errorData ?? { status: "initial" }),
      sqlVariablesHighlight(sqlVariables ?? [], onDatasetClick),
      onScrollPlugin(onEditorScroll ?? (() => {})),
      syntaxHighlighting(sqlHighlightStyle),
      ...(warehouseType ? [sqlFunctionsTooltip(warehouseType)] : []),
      showLeadingWhitespaces,
      SQLEditorTheme,
      sqlFoldingService,
      sqlIndentService,
      EditorView.updateListener.of((update) => {
        if (
          update.transactions?.some(
            (t) =>
              t.isUserEvent("input") ||
              t.isUserEvent("delete") ||
              t.isUserEvent("move") ||
              t.isUserEvent("select") ||
              t.isUserEvent("undo") ||
              t.isUserEvent("redo") ||
              t.state.changes // detects folding state changes
          ) ||
          update.selectionSet
        ) {
          debouncedStateUpdate(update.state.toJSON(stateFields));
        }
      }),
    ];
  }, [
    autocompleteValues,
    warehouseType,
    errorData,
    sqlVariables,
    onDatasetClick,
    onEditorScroll,
    debouncedStateUpdate,
  ]);

  return (
    <MemoizedCodeMirror
      ref={(editorRef) => {
        if (editorRef?.view && !hasScrolled) {
          setHasScrolled(true);
          editorRef.view.requestMeasure({
            read: (view) => {
              const el = view.scrollDOM;
              return [el.scrollHeight, el.offsetHeight];
            },
            write: (_, view) => {
              view.scrollDOM.scrollTop = initialScrollTop ?? 0;
              view.scrollDOM.scrollLeft = initialScrollLeft ?? 0;
            },
          });
        }
      }}
      basicSetup={{
        foldGutter: false,
      }}
      extensions={extensions}
      readOnly={readOnly}
      height={`${height}px`}
      value={internalValue}
      onChange={(value) => debouncedOnChange(value)}
      autoFocus={!readOnly}
      initialState={
        initialState
          ? {
              json: initialState,
              fields: stateFields,
            }
          : undefined
      }
    />
  );
};
