import { Tree } from "antd";
import type { DataNode, EventDataNode } from "antd/lib/tree";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import type { NodeDragEventParams } from "rc-tree/lib/contextTypes";
import type { Key } from "rc-tree/lib/interface";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { ExplorationSectionInitialData } from "../../../../../components/explorations/section/creation/ExplorationSectionCreation";
import { ExplorationSectionCreation } from "../../../../../components/explorations/section/creation/ExplorationSectionCreation";
import usePrevious from "../../../../../components/hooks/usePrevious";
import type {
  IExploration,
  IExplorationSection,
} from "../../../../../interfaces/explorations";
import { IUserRoleType } from "../../../../../interfaces/user";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import ExplorationMenuItem from "../../../../exploration/menu/ExplorationMenuItem";
import { ExplorationSectionMenuItem } from "../../../../exploration/menu/ExplorationSectionMenuItem";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../../user-settings/HasRoleAccess";
import type { IActiveObject } from "../../domain";

import "./ExplorationTable.scss";

interface IExplorationTableProps {
  activeObject?: IActiveObject;
  explorations: IExploration[];
  explorationSections: IExplorationSection[];
  onActiveObjectChange: (activeObject: IActiveObject) => void;
  onExplorationUpdate: (ids?: string[]) => Promise<void>;
  onSectionUpdate: (ids?: string[]) => Promise<void>;
}

const generateSectionKey = (id: string) => {
  return `section-${id}`;
};

const generateExplorationKey = (id: string) => {
  return `exploration-${id}`;
};

const UPDATE_EXPLORATION = `
mutation updateExploration($id: ID!, $data: ExplorationUpdateInput) {
  updateExploration(id: $id, data: $data) {
    id
  }
}
`;

const UPDATE_EXPLORATION_SECTION = `
mutation updateExplorationSection($id: ID!, $data: ExplorationSectionUpdateInput) {
  updateExplorationSection(id: $id, data: $data) {
    id
  }
}
`;

const UPDATE_EXPLORATION_SECTIONS = `
mutation updateExplorationSections($data: [ExplorationSectionsUpdateInput]!) {
  updateExplorationSections(data: $data) {
    id
  }
}
`;

const getSortedSection = (s: IExplorationSection[]): IExplorationSection[] => {
  return [...s].sort((a, b) => {
    const f = a.order ? a.order : 0;
    const g = b.order ? b.order : 0;
    if (f === 0 && g === 0) {
      return 1;
    }
    return f - g;
  });
};

type Props = IExplorationTableProps &
  WorkbenchUIStoreProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function ExplorationTable(props: Props) {
  const {
    antUtils,
    workbenchUIStore,
    explorationSections: sections,
    explorations,
    user,
    org,
    onExplorationUpdate,
    onSectionUpdate,
    activeObject,
  } = props;

  const [openKeys, setOpenKeys] = React.useState<string[]>([
    ...sections.map((s) => generateSectionKey(s.id)),
    "section-wip",
  ]);
  const [shouldRepoedKeys, setShouldReopenKeys] = React.useState<
    string[] | undefined
  >();
  const [dragKey, setDragKey] = React.useState<string | undefined>();
  const [
    explorationSectionCreationVisible,
    setExplorationSectionCreationVisible,
  ] = React.useState(false);
  const [explorationSectionInitialData, setExplorationSectionInitialData] =
    React.useState<Partial<IExplorationSection> | undefined>(undefined);

  const [selectedKeys, setSelectedKey] = React.useState<string[]>([]);

  const [cachedExplorations, setCachedExplorations] =
    React.useState<IExploration[]>(explorations);
  const prevExplorations = usePrevious(explorations);
  const [cachedSections, setCachedSections] =
    React.useState<IExplorationSection[]>(sections);
  const prevSections = usePrevious(sections);

  // change selected key when active object changes
  const changeKey = `${activeObject?.type}-${activeObject?.value}`;
  React.useEffect(() => {
    const values = [];
    if (activeObject && activeObject.type === "exploration") {
      values.push(generateExplorationKey(activeObject.value));
      document
        .getElementById(activeObject.value)
        ?.scrollIntoView({ block: "center" });
    }
    setSelectedKey(values);
  }, [changeKey]);

  // reset cache when exploration or section are changing upstream
  React.useEffect(() => {
    if (!_.isEqual(prevExplorations, explorations)) {
      setCachedExplorations(explorations);
    }
    if (!_.isEqual(prevSections, sections)) {
      setCachedSections(sections);
    }
  }, [explorations, sections]);

  const hasCreateEditDeleteRight = hasRoleAccessBoolean(
    IUserRoleType.BUILDER,
    user,
    org.id
  );

  const buildTree = (): DataNode[] => {
    const renderLine = (ex: IExplorationSection, isEditable?: boolean) => {
      return (
        <ExplorationSectionMenuItem
          explorationSection={ex}
          onDelete={
            isEditable && hasCreateEditDeleteRight
              ? () => {
                  if (
                    cachedExplorations.filter(
                      (e) => e.section && e.section.id === ex.id
                    ).length > 0
                  ) {
                    return antUtils.modal.error({
                      title: "Cannot delete this section",
                      content:
                        "You cannot delete a section with explorations in it. Please move them before deleting the section.",
                      onOk: () => false,
                    });
                  }
                  return antUtils.modal.confirm({
                    title: "Are you sure?",
                    content: `You are about to delete the following section ${ex.name}. This cannot be undone. Do you wish to proceed?`,
                    okButtonProps: {
                      danger: true,
                    },
                    okText: "Delete",
                    onCancel: () => false,
                    onOk: () => {
                      return GraphQLService(UPDATE_EXPLORATION_SECTION, {
                        id: ex.id,
                        data: {
                          deleted: true,
                        },
                      }).then(() => {
                        return onSectionUpdate();
                      });
                    },
                  });
                }
              : undefined
          }
          onEdit={
            isEditable && hasCreateEditDeleteRight
              ? () => {
                  setExplorationSectionInitialData(ex);
                  setExplorationSectionCreationVisible(true);
                }
              : undefined
          }
        />
      );
    };

    const renderExplorationTitle = (e: IExploration) => {
      return (
        <ExplorationMenuItem
          exploration={e}
          onClick={() =>
            workbenchUIStore.pushActiveObject({
              type: "exploration",
              value: e.id,
            })
          }
          onDelete={() => onExplorationUpdate([e.id])}
          onEditDescription={(v) => onExplorationUpdate([e.id])}
          onRename={(v) => onExplorationUpdate([e.id])}
          showAccelerationStatus={true}
        />
      );
    };

    const buildExploration = (
      parentKey: string,
      ex: IExploration[]
    ): DataNode[] => {
      if (ex.length > 0) {
        return ex.map((e) => ({
          key: generateExplorationKey(e.id),
          title: renderExplorationTitle(e),
          isLeaf: true,
          selectable: true,
        }));
      }
      return [
        {
          key: `no-data-${parentKey}`,
          title: "No explorations...",
          selectable: false,
          isLeaf: true,
          disabled: true,
        },
      ];
    };

    return [
      ...getSortedSection(cachedSections).map((s) => {
        return {
          key: generateSectionKey(s.id),
          title: renderLine(s, true),
          selectable: false,
          children: buildExploration(
            s.id,
            cachedExplorations.filter((e) => e.section && e.section.id === s.id)
          ),
        };
      }),
      {
        key: "section-wip",
        title: renderLine({
          name: "Work in progress",
          image: ":hammer_and_wrench:",
          description:
            "Newly created exploration are put there by default. This section is only available in the Workbench, once you are done fine tuning your exploration move it to another section to make it available to anyone.",
        } as IExplorationSection),
        selectable: false,
        style: {
          opacity:
            dragKey && (dragKey as string).startsWith("section-") ? 0.3 : 1,
        },
        children: buildExploration(
          "wip",
          cachedExplorations.filter((e) => !e.section)
        ),
      },
    ];
  };

  const onDrop = (
    info: NodeDragEventParams<DataNode, HTMLDivElement> & {
      dragNode: EventDataNode<DataNode>;
      dragNodesKeys: Key[];
      dropPosition: number;
      dropToGap: boolean;
    }
  ) => {
    if (info.dragNode.key.toString().startsWith("section-")) {
      // we are moving a section around
      // console.log("dropping section", info.dragNode.key, "to", info.node.key, "at position", info.dropPosition);
      const [droppedSectionHead, ...droppedSectionTail] = info.node.key
        .toString()
        .split("-");
      const [draggedSectionHead, ...draggedSectionTail] = info.dragNode.key
        .toString()
        .split("-");
      const droppedSectionId = droppedSectionTail.join("-");
      const draggedSectionId = draggedSectionTail.join("-");
      const draggedSection = cachedSections.find(
        (s) => s.id == draggedSectionId
      );
      if (draggedSection) {
        const newOrderedSections = cachedSections
          .filter((s) => s.id !== draggedSectionId)
          .flatMap((s) => {
            if (s.id == droppedSectionId) {
              if (info.dropPosition > 0) {
                return [s, draggedSection];
              }
              return [draggedSection, s];
            }
            return s;
          })
          .map((s, i) => {
            return {
              ...s,
              order: i,
            };
          });
        setCachedSections(newOrderedSections);
        GraphQLService(UPDATE_EXPLORATION_SECTIONS, {
          data: newOrderedSections.map((es) => {
            return {
              id: es.id,
              data: {
                order: es.order,
              },
            };
          }),
        });
      }
    }
    if (info.dragNode.key.toString().startsWith("exploration-")) {
      // we are moving an exploration around
      // console.log("dropping exploration on section", info.node.key, "at position", info.dropPosition)
      // here we don't care about position as it is not implemented yet on the backend

      const [explorationHead, ...explorationTail] = info.dragNode.key
        .toString()
        .split("-");
      const [sectionHead, ...sectionTail] = info.node.key.toString().split("-");
      const explorationId = explorationTail.join("-");
      const sectionId = sectionTail.join("-");
      const newExplorations = cachedExplorations.map((e) => {
        if (e.id === explorationId) {
          return {
            ...e,
            section:
              sectionId === "wip"
                ? null
                : {
                    id: sectionId,
                  },
          } as IExploration;
        }
        return e;
      });
      const changedExploration = cachedExplorations.find(
        (e) => e.id === explorationId
      );
      if (changedExploration) {
        const getChange = (e: IExploration, newSectionId: string) => {
          if (!e.section && newSectionId !== "wip") {
            return {
              section: {
                connect: {
                  id: newSectionId,
                },
              },
            };
          }
          if (
            e.section &&
            newSectionId !== "wip" &&
            e.section.id !== newSectionId
          ) {
            return {
              section: {
                connect: {
                  id: newSectionId,
                },
              },
            };
          }
          if (e.section && e.section.id && newSectionId === "wip") {
            return {
              section: {
                disconnect: {
                  id: e.section.id,
                },
              },
            };
          }
          return null;
        };
        const hasExplorationChanged = getChange(changedExploration, sectionId);
        if (hasExplorationChanged) {
          GraphQLService(UPDATE_EXPLORATION, {
            id: changedExploration.id,
            data: hasExplorationChanged,
          });
          setCachedExplorations(newExplorations);
        }
      }
    }
  };

  const onExplorationSectionCreate = (
    v: ExplorationSectionInitialData
  ): Promise<{ id: string }> => {
    return GraphQLService(
      `
      mutation createExplorationSection($data: ExplorationSectionCreateInput!) {
        createExplorationSection(data: $data) {
          id
          name
          description
          order
          image
        }
      }
      `,
      {
        data: {
          name: v.name,
          image: v.image,
          order: v.order ? v.order : 0,
          org: {
            connect: {
              id: org.id,
            },
          },
        },
      }
    ).then((r) => {
      return r.createExplorationSection;
    });
  };

  const onExplorationSectionEdit = (v: {
    id: string;
    name: string;
    image: string;
  }) => {
    return GraphQLService(
      `
      mutation updateExplorationSection($id: ID!, $data: ExplorationSectionUpdateInput!) {
        updateExplorationSection(id: $id, data: $data) {
          id
          name
          description
          order
          image
        }
      }
      `,
      {
        id: v.id,
        data: {
          image: v.image,
          name: v.name,
        },
      }
    ).then((r) => {
      return r.updateExplorationSection;
    });
  };

  return (
    <>
      <Tree
        className="exploration-table-tree"
        onExpand={(expandedKeys) => {
          if (!dragKey) {
            setOpenKeys(expandedKeys.map((k) => k.toString()));
          }
        }}
        selectedKeys={selectedKeys}
        defaultSelectedKeys={selectedKeys}
        autoExpandParent={true}
        expandedKeys={openKeys}
        defaultExpandAll={true}
        blockNode={true}
        selectable={true}
        draggable={
          hasCreateEditDeleteRight
            ? (node) => {
                return (node as any as DataNode).key !== "section-wip";
              }
            : false
        }
        allowDrop={({ dropNode, dropPosition }) => {
          // console.log(dropPosition)
          if (
            dragKey.startsWith("section-") &&
            dragKey !== "section-wip" &&
            (dropNode.key as string).startsWith("section-") &&
            dropPosition !== 0
          ) {
            return true;
          } else if (
            dragKey.startsWith("exploration-") &&
            (dropNode.key as string).startsWith("section-") &&
            dropPosition === 0
          ) {
            return true;
          }
          return false;
        }}
        onDragStart={
          hasCreateEditDeleteRight
            ? (i) => {
                if ((i.node.key as string).startsWith("section-")) {
                  setShouldReopenKeys(openKeys);
                  setOpenKeys([]);
                } else if ((i.node.key as string).startsWith("exploration-")) {
                  setOpenKeys([
                    ...cachedSections.map((s) => generateSectionKey(s.id)),
                    "section-wip",
                  ]);
                }
                setDragKey(i.node.key as string);
              }
            : undefined
        }
        onDragEnd={
          hasCreateEditDeleteRight
            ? (i) => {
                setDragKey(undefined);
                if (shouldRepoedKeys && shouldRepoedKeys.length) {
                  setOpenKeys(shouldRepoedKeys);
                  setShouldReopenKeys(undefined);
                }
              }
            : undefined
        }
        onDrop={
          hasCreateEditDeleteRight
            ? (i) => {
                onDrop(i);
                setDragKey(undefined);
              }
            : undefined
        }
        treeData={buildTree()}
      />
      <ExplorationSectionCreation
        onCancel={() => {
          setExplorationSectionCreationVisible(false);
          setExplorationSectionInitialData(undefined);
        }}
        onEdit={(v) => {
          return onExplorationSectionEdit(v as any).then(() => {
            return onSectionUpdate([v.id]);
          });
        }}
        onCreate={(v) => {
          return onExplorationSectionCreate(v).then((a) => {
            return onSectionUpdate([a.id]);
          });
        }}
        visible={!!explorationSectionCreationVisible}
        initialData={explorationSectionInitialData as any}
      />
    </>
  );
}

export default compose<Props, IExplorationTableProps>(
  WithOrg,
  withAntUtils
)(inject("workbenchUIStore")(observer(ExplorationTable)));
