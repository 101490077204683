import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import { Button, Form, Input, Modal, Space, Switch, Typography } from "antd";
import cuid from "cuid";
import React from "react";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { IDataset } from "../../../../../../../../interfaces/sources";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import CreateEditDatasheetModal from "../../../common/markdoc/datasheet/CreateEditDatasheetModal";
import {
  convertDataSheetNameToMarkdocVariable,
  convertKeyToMarkdocVariable,
} from "../../../common/markdoc/domain";
import { MarkdocEditor } from "../../../common/markdoc/editor/MarkdocEditor";
import "../../../common/markdoc/editor/MarkdownModal.scss";
import type { ITextDataSheet } from "../../../common/markdoc/interfaces";
import type { IWidget } from "../../../domain";
import type { IWidgetMarkdownConfig } from "../domain";

interface IMarkdownWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetMarkdownConfig;
  edit?: boolean;
  form: FormInstance;
  datasets: IDataset[];
}

export function MarkdownWidgetEditor(props: IMarkdownWidgetEditorProps) {
  const { object, form, datasets } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataSheetModalOpen, setDataSheetModalOpen] = React.useState<
    | {
        type: "edit";
        name: string | number;
      }
    | {
        type: "create";
      }
    | null
  >(null);
  const columns = getObjectColumns(object);
  return (
    <>
      <ChartOptionCollapse dark title="Data sheets">
        <Form.List name={["config", "dataSheets"]}>
          {(fields, { add, remove }) => {
            return (
              <Space
                size="middle"
                style={{ width: "100%" }}
                direction="vertical"
              >
                {fields.map(({ key, name, ...restField }) => {
                  const fullPath = ["config", "dataSheets", name];
                  const value = form.getFieldValue(fullPath);
                  const inputId = `config-dataSheets-${name}`;
                  return (
                    <div style={{ display: "flex" }} key={key}>
                      <Form.Item name={fullPath} hidden noStyle>
                        <Input id={inputId} />
                      </Form.Item>
                      <div style={{ flex: 1 }}>
                        {value?.name ? (
                          <Typography.Text ellipsis>
                            {value.name}
                          </Typography.Text>
                        ) : (
                          <Typography.Text italic ellipsis>
                            Untitled
                          </Typography.Text>
                        )}
                      </div>
                      <Space style={{ flex: `0 auto` }}>
                        <Button
                          size="small"
                          shape="circle"
                          type="text"
                          icon={<EditOutlined />}
                          onClick={() =>
                            setDataSheetModalOpen({
                              type: "edit",
                              name: name,
                            })
                          }
                        />
                        <Button
                          size="small"
                          shape="circle"
                          type="text"
                          danger
                          onClick={() => remove(name)}
                          icon={<CloseOutlined />}
                        />
                      </Space>
                      <Form.Item noStyle shouldUpdate>
                        {() => {
                          return (
                            <CreateEditDatasheetModal
                              open={
                                dataSheetModalOpen?.type === "edit" &&
                                dataSheetModalOpen.name === name
                              }
                              initialValue={value}
                              onCancel={() => {
                                setDataSheetModalOpen(null);
                              }}
                              object={object}
                              datasets={datasets}
                              onSave={(v) => {
                                form.setFieldValue([...fullPath], v);
                                // we need to do this on trigger onValuesChang on form
                                // https://github.com/ant-design/ant-design/issues/23782#issuecomment-1770840035
                                var input = document.getElementById(inputId);
                                if (
                                  input &&
                                  Object &&
                                  window.HTMLInputElement.prototype
                                ) {
                                  const nativeInputValueSetter =
                                    Object.getOwnPropertyDescriptor(
                                      window.HTMLInputElement.prototype,
                                      "value"
                                    )?.set;

                                  nativeInputValueSetter?.call(input, value);

                                  // dispatch change event
                                  var inputEvent = new Event("change", {
                                    bubbles: true,
                                  });
                                  input.dispatchEvent(inputEvent);
                                }

                                setDataSheetModalOpen(null);
                              }}
                            />
                          );
                        }}
                      </Form.Item>
                    </div>
                  );
                })}
                <Button
                  onClick={() => {
                    setDataSheetModalOpen({ type: "create" });
                  }}
                  size="small"
                  block
                >
                  Add a data sheet
                </Button>

                <CreateEditDatasheetModal
                  open={dataSheetModalOpen?.type === "create"}
                  initialValue={{
                    id: cuid(),
                    type: "MODEL",
                    name: "",
                    select: [],
                    from: "",
                    additionalFilters: { operator: "and", filters: [] },
                    limit: { type: "value", value: "" },
                  }}
                  onCancel={() => {
                    setDataSheetModalOpen(null);
                  }}
                  object={object}
                  datasets={datasets}
                  onSave={(v) => {
                    setDataSheetModalOpen(null);
                    add(v);
                  }}
                />
              </Space>
            );
          }}
        </Form.List>
      </ChartOptionCollapse>
      <ChartOptionCollapse dark title="Markdown Configuration">
        <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
          <ChartOptionLine
            items={[
              {
                content: (
                  <div>
                    Content
                    <Form.Item hidden noStyle name={["config", "text"]} />
                  </div>
                ),
                flex: `1`,
              },
              {
                content: (
                  <>
                    <Button
                      onClick={() => setOpen(true)}
                      size="small"
                      icon={<EditOutlined />}
                    >
                      Edit
                    </Button>
                    <Modal
                      open={open}
                      onCancel={() => setOpen(false)}
                      destroyOnClose
                      maskClosable={false}
                      className="markdown-modal"
                      title={"Edit markdown"}
                      width={"50%"}
                      okText={"Save"}
                      onOk={() => {
                        setOpen(false);
                      }}
                    >
                      <Form.Item noStyle shouldUpdate>
                        {() => {
                          const datasheets = form.getFieldValue([
                            "config",
                            "dataSheets",
                          ]) as ITextDataSheet[];
                          return (
                            <Form.Item noStyle name={["config", "text"]}>
                              <MarkdocEditor
                                columnsSuggestions={columns.map((c) => ({
                                  key: `$${convertKeyToMarkdocVariable(
                                    c.data.key
                                  )}`,
                                  label: c.data.label,
                                }))}
                                datasheetsSuggestions={(datasheets || []).map(
                                  (d) => ({
                                    key: `$${convertDataSheetNameToMarkdocVariable(
                                      d.id
                                    )}`,
                                    label: d.name,
                                  })
                                )}
                                datasheetColumnsSuggestions={(
                                  datasheets || []
                                ).flatMap((d) => {
                                  const datasheetName =
                                    convertDataSheetNameToMarkdocVariable(d.id);
                                  if (d.type === "OBJECT") {
                                    const foreignObject =
                                      object.foreignKeys.find(
                                        (fk) => fk.id === d.from
                                      );
                                    if (!foreignObject) {
                                      return [];
                                    }
                                    const foreignColumns = getObjectColumns(
                                      foreignObject.object
                                    );
                                    return foreignColumns
                                      .filter((c) => {
                                        return d.select.includes(c.data.key);
                                      })
                                      .map((c) => {
                                        return {
                                          key: `$${datasheetName}_${convertKeyToMarkdocVariable(
                                            c.data.key
                                          )}`,
                                          label: c.data.label,
                                          dataSheetName: d.name,
                                        };
                                      });
                                  } else {
                                    return d.select.map((s) => {
                                      return {
                                        key: `$${datasheetName}_${convertKeyToMarkdocVariable(
                                          s
                                        )}`,
                                        label: s,
                                        dataSheetName: d.name,
                                      };
                                    });
                                  }
                                })}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Modal>
                  </>
                ),
                flex: "0 auto",
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Sticky</div>,
                flex: `1`,
              },
              {
                flex: `0 auto`,
                content: (
                  <Form.Item
                    valuePropName="checked"
                    noStyle
                    name={["config", "sticky"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
