import {
  BulbFilled,
  CheckOutlined,
  FallOutlined,
  FlagFilled,
  RiseOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { Space, theme, Tooltip, Typography } from "antd";
import React from "react";
import type { IObjectPropertyScoreFormatterConfig } from "../../../../interfaces/object";

interface IScoreCellRendererProps {
  config: IObjectPropertyScoreFormatterConfig;
  value?: IScoreValue;
}

export interface IScoreValue {
  icon?: string;
  priority?: string;
  reasons?: string[];
  score?: string;
}

const { useToken } = theme;

const ScoreCellRenderer = (props: IScoreCellRendererProps) => {
  const { config, value } = props;

  const { token } = useToken();

  if (!value) {
    return null;
  }

  const renderIcon = () => {
    if (value?.icon === "falling") {
      return <FallOutlined />;
    }
    if (value?.icon === "rising") {
      return <RiseOutlined />;
    }
    if (value?.icon === "insight") {
      return <BulbFilled />;
    }
    if (value?.icon === "warning") {
      return <WarningFilled />;
    }
    if (value?.icon === "check") {
      return <CheckOutlined />;
    }
    if (value?.icon === "none") {
      return null;
    }
    return <FlagFilled />;
  };

  const renderColor = (): {
    background: string;
    color: string;
    border: string;
  } => {
    if (value?.priority === "high") {
      return {
        background: "rgba(255, 188, 206, 0.34)", // "#FFBCCE", // token["volcano-2"],
        color: "#FE779D", //token["volcano-8"],
        border: "#FE779D", // token["volcano-4"],
      };
    }
    if (value?.priority === "medium") {
      return {
        background: "rgb(246 220 154 / 18%)", // "#F6DC9A",
        color: "rgb(237 187 56)", // "#FDD15E",
        border: "#FDD15E",
      };
    }
    if (value?.priority === "low") {
      return {
        background: "rgb(173 232 179 / 31%)", // "#ADE8B3",
        color: "#7EE786",
        border: "#7EE786",
      };
    }
    return {
      background: token.colorBgBase,
      color: token.colorTextBase,
      border: token.colorBorder,
    };
  };

  const color = renderColor();
  const icon = renderIcon();

  const wrapInTooltip = (n: React.ReactElement): React.ReactElement => {
    if (value.reasons && Array.isArray(value.reasons) && value.reasons.length) {
      return (
        <Tooltip
          title={
            <div style={{ maxWidth: 300 }}>
              <Space direction="vertical">
                {value.reasons.map((r) => {
                  return <div key={r}>• {r}</div>;
                })}
              </Space>
            </div>
          }
        >
          {n}
        </Tooltip>
      );
    }
    return n;
  };

  return wrapInTooltip(
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        height: "100%",
      }}
    >
      <div
        style={{
          height: 26,
          backgroundColor: color.background,
          display: "flex",
          gap: 8,
          padding: `0 12px`,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 16,
          // border: `1px solid ${color.border}`,
        }}
      >
        {icon && (
          <div style={{ fontSize: `1.1em`, color: color.color }}>{icon}</div>
        )}

        <div>
          <Typography.Text>{value?.score}</Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default ScoreCellRenderer;
