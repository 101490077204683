import { Tag } from "@markdoc/markdoc";
import _ from "lodash";

// Or replace this with your own function
function generateID(children, attributes) {
  if (attributes.id && typeof attributes.id === "string") {
    return attributes.id;
  }
  return children
    .filter((child) => typeof child === "string")
    .join(" ")
    .replace(/[?]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase();
}

export const list = {
  children: ["inline"],
  attributes: {
    id: { type: String },
  },
  transform(node, config) {
    const attributes = node.transformAttributes(config);
    const childNodes = node.transformChildren(config);

    const id = generateID(childNodes, attributes);

    // we sanitze the children to remove orphan list item
    // this is happening when using {% if %} tags and no content
    // is outputed
    const sanitize = (c: Array<Tag>): Array<Tag> => {
      return c.flatMap((a) => {
        if (a.name === "li" && a.children.length === 0) {
          return [];
        }
        const b = _.cloneDeep(a);
        if (Array.isArray(b.children)) {
          b.children = sanitize((b.children as any) ?? []);
        }

        return [b];
      });
    };

    const children: Array<Tag> = sanitize(childNodes);

    return new Tag(`List`, { ...attributes, id }, children);
  },
};
