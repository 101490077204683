import type {
  BinaryFilter,
  TimeDimensionGranularity,
  UnaryFilter,
} from "@cubejs-client/core";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import type { ChartType } from "../../../components/chart/domain";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import Loading from "../../../components/layout/feedback/loading";
import type { IComparisonPeriod } from "../../../components/measures/comparison-selector/ComparisonSelector";
import type { MeasureItemSortValue } from "../../../components/measures/measure-item/MeasureItem";
import {
  LOADQUERY_SEARCH_SETTINGS,
  QUERY_SEARCH_SETTINGS,
  parseSearch,
  updateSearch,
} from "../../../helpers/queryStringHelpers";
import type { AsyncCachedData } from "../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../interfaces/explorations";
import type { IDataset } from "../../../interfaces/sources";
import { track } from "../../../services/AnalyticsService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { ChartOption } from "../../chart-options/ChartOptions";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import type { IWlyDatePickerInputValue } from "../../reports/view/filters/date-filter/WlyDatePicker";
import {
  convertStringToWlyDatePickerValue,
  convertWlyDatePickerValueToString,
} from "../../reports/view/filters/date-filter/WlyDatePicker";
import ExplorationContent from "./ExplorationContent";
import "./ExplorationEditor.scss";
import ExplorationVersionWatcher from "./ExplorationVersionWatcher";
import type {
  FilterOperator,
  GetCurrentQuery,
  IAnalysisType,
  IForecastConfig,
  ILagoonQuery,
  ILagoonQueryExtra,
} from "./domain";
import { GET_EXPLORATION_QUERY } from "./domain";

interface IExplorationEditorProps {
  isEmbeded?: boolean;
  liftExploration?: (e: IExploration) => void;
  getCurrentQuery?: GetCurrentQuery;
  initialQuery?: ILagoonQuery;
  initialQueryLoad?: boolean;
  injectedExplorationSlug?: string;
  renderRoute?: (params: object, query?: object | undefined) => string;
  previousRoute?: string;
}

interface IState {
  exploration: AsyncCachedData<IExploration>;
}

type Props = InjectedOrgProps &
  IExplorationEditorProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{
    organizationSlug: string;
    explorationSlug: string;
    tableSlug?: string;
  }>;

class ExplorationEditor extends React.Component<Props, IState> {
  private ref = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      exploration: {
        status: "initial",
      },
    };
  }

  isComponentMounted = () => {
    if (this.ref.current) {
      return true;
    } else {
      return false;
    }
  };

  componentDidMount() {
    const {
      org,
      match: {
        params: { explorationSlug },
      },
      getCurrentQuery,
      location: { search, pathname },
      history,
      injectedExplorationSlug,
    } = this.props;
    if (!getCurrentQuery) {
      const s = parseSearch<{ query: ILagoonQuery; loadQuery: boolean }>(
        search,
        [...QUERY_SEARCH_SETTINGS, ...LOADQUERY_SEARCH_SETTINGS]
      );
      if (!s.query) {
        const newSearch = updateSearch(
          search,
          {
            query: {
              ...this.state,
              dateRange: convertWlyDatePickerValueToString({
                type: "preset",
                preset: "LAST_7_DAYS",
              }),
            },
          },
          [...QUERY_SEARCH_SETTINGS]
        );
        if (this.isComponentMounted()) {
          history.replace({
            pathname: pathname,
            search: newSearch,
          });
        }
      }
    }
    this.fetchInitialData(
      org.id,
      injectedExplorationSlug ? injectedExplorationSlug : explorationSlug
    );
  }

  getInitialQuery = () => {
    const {
      getCurrentQuery,
      location: { search },
      initialQuery,
    } = this.props;
    if (!getCurrentQuery) {
      const s = parseSearch<{ query: ILagoonQuery; loadQuery: boolean }>(
        search,
        [...QUERY_SEARCH_SETTINGS, ...LOADQUERY_SEARCH_SETTINGS]
      );
      return {
        ...s.query,
        dateRange: convertStringToWlyDatePickerValue(
          s?.query?.dateRange as unknown as string
        ),
      };
    } else if (initialQuery) {
      return {
        ...initialQuery,
        dateRange: convertStringToWlyDatePickerValue(
          initialQuery?.dateRange as unknown as string
        ),
      };
    }
  };

  getInitialQueryLoad = () => {
    const {
      getCurrentQuery,
      location: { search },
      initialQueryLoad,
    } = this.props;
    if (!getCurrentQuery) {
      const s = parseSearch<{ query: ILagoonQuery; loadQuery: boolean }>(
        search,
        [...QUERY_SEARCH_SETTINGS, ...LOADQUERY_SEARCH_SETTINGS]
      );
      return s.loadQuery;
    } else {
      return initialQueryLoad;
    }
  };

  componentDidUpdate(prevProps: Props) {
    const {
      org,
      match: {
        params: { explorationSlug },
      },
      injectedExplorationSlug,
    } = this.props;
    const {
      org: prevOrg,
      match: {
        params: { explorationSlug: prevExplorationSlug },
      },
      injectedExplorationSlug: prevInjectedExplorationSlug,
    } = prevProps;
    if (
      prevOrg.id !== org.id ||
      explorationSlug !== prevExplorationSlug ||
      prevInjectedExplorationSlug !== injectedExplorationSlug
    ) {
      this.fetchInitialData(
        org.id,
        injectedExplorationSlug ? injectedExplorationSlug : explorationSlug
      );
    }
  }

  fetchInitialData = (orgId: string, explorationSlug: string) => {
    this.setState({ exploration: { status: "initial" } });
    return GraphQLService(GET_EXPLORATION_QUERY, {
      orgId,
      explorationSlug,
    })
      .then(
        (r: { allExplorations: IExploration[]; allDatasets: IDataset[] }) => {
          if (r.allExplorations.length === 1) {
            track("Exploration Viewed", {
              id: r.allExplorations[0].id,
              name: r.allExplorations[0].name,
            });
            if (this.props.liftExploration) {
              this.props.liftExploration(r.allExplorations[0]);
            }
            return this.setState({
              exploration: {
                status: "success",
                cache: r.allExplorations[0],
                data: r.allExplorations[0],
              },
            });
          }
          this.setState({
            exploration: {
              ...this.state.exploration,
              status: "error",
              error: new Error("NOT_FOUND"),
            },
          });
        }
      )
      .catch((err) => {
        this.setState({
          exploration: {
            ...this.state.exploration,
            status: "error",
            error: err,
          },
        });
      });
  };

  reloadExploration = (orgId: string, explorationSlug: string) => {
    this.setState({
      exploration: {
        ...this.state.exploration,
        status: "loading",
      },
    });
    return GraphQLService(GET_EXPLORATION_QUERY, {
      orgId,
      explorationSlug,
    })
      .then(
        (r: { allExplorations: IExploration[]; allDatasets: IDataset[] }) => {
          if (r.allExplorations.length === 1) {
            track("Exploration Viewed", {
              id: r.allExplorations[0].id,
              name: r.allExplorations[0].name,
            });
            if (this.props.liftExploration) {
              this.props.liftExploration(r.allExplorations[0]);
            }
            return this.setState({
              exploration: {
                status: "success",
                cache: r.allExplorations[0],
                data: r.allExplorations[0],
              },
            });
          }
          this.setState({
            exploration: {
              ...this.state.exploration,
              status: "error",
              error: new Error("NOT_FOUND"),
            },
          });
        }
      )
      .catch((err) => {
        this.setState({
          exploration: {
            ...this.state.exploration,
            status: "error",
            error: err,
          },
        });
      });
  };

  public renderContent = () => {
    const { exploration } = this.state;

    const persistQuery: GetCurrentQuery = (
      isStale: boolean,
      analysisType: IAnalysisType,
      chartType: ChartType,
      measures: string[],
      dimensions: string[],
      filters: (UnaryFilter | BinaryFilter)[],
      filterOperator: FilterOperator,
      dateRange: IWlyDatePickerInputValue,
      orderBy: Array<[string, MeasureItemSortValue]>,
      limit: number,
      showOther?: boolean,
      showOtherDimensionLimit?: number,
      comparison?: IComparisonPeriod,
      timeDimension?: string,
      selectedGranularity?: TimeDimensionGranularity,
      pivotConfig?: string[],
      chartOptions?: ChartOption,
      forecast?: IForecastConfig,
      extra?: ILagoonQueryExtra,
      metricFilters?: (UnaryFilter | BinaryFilter)[],
      metricFilterOperator?: FilterOperator
    ) => {
      if (this.props.getCurrentQuery) {
        // we are in embed mode so we need to pop up the query to the main component
        this.props.getCurrentQuery(
          isStale,
          analysisType,
          chartType,
          measures,
          dimensions,
          filters,
          filterOperator,
          dateRange,
          orderBy,
          limit,
          showOther,
          showOtherDimensionLimit,
          comparison,
          timeDimension,
          selectedGranularity,
          pivotConfig,
          chartOptions,
          forecast,
          extra,
          metricFilters,
          metricFilterOperator
        );
      } else {
        // we are in standalone mode and we save the query in the query string
        const {
          location: { search, pathname },
          history,
        } = this.props;
        const computedQuery: ILagoonQuery = {
          selectedMeasures: measures,
          selectedDimensions: dimensions,
          selectedTime: timeDimension,
          dateRange: convertWlyDatePickerValueToString(dateRange) as any,
          comparison: comparison,
          selectedGranularity: selectedGranularity,
          filterOperator: filterOperator,
          filters: filters,
          analysisType: analysisType,
          orderBy: orderBy,
          pivotDimensions: pivotConfig,
          limit: limit,
          showOther: showOther,
          showOtherDimensionLimit: showOtherDimensionLimit,
          chartOptions: chartOptions,
          chartType: chartType,
          forecast: forecast,
          extra: extra,
          metricFilterOperator: metricFilterOperator,
          metricFilters: metricFilters,
        };
        const newSearch = updateSearch(
          search,
          {
            query: { ...computedQuery },
          },
          QUERY_SEARCH_SETTINGS
        );
        if (this.isComponentMounted()) {
          history.replace({
            pathname: pathname,
            search: newSearch,
          });
        }
      }
    };

    switch (exploration.status) {
      case "initial":
        return (
          <Aligner className="standard-bg">
            <Loading />
          </Aligner>
        );
      case "error":
        if (exploration.error.message === "NOT_FOUND") {
          return (
            <Aligner className="standard-bg">
              This exploration doesn't exists
            </Aligner>
          );
        } else {
          return (
            <Aligner className="standard-bg">
              {exploration.error.message}
            </Aligner>
          );
        }
      case "success":
      case "loading":
        return (
          <ExplorationVersionWatcher
            loading={this.state.exploration.status === "loading"}
            initialVersion={
              exploration.status === "success"
                ? exploration.data.version.value
                : exploration.cache!.version.value
            }
            onReload={() =>
              this.reloadExploration(
                this.props.org.id,
                exploration.status === "success"
                  ? exploration.data.slug
                  : exploration.cache!.slug
              )
            }
            explorationId={
              exploration.status === "success"
                ? exploration.data.id
                : exploration.cache!.id
            }
          >
            <ExplorationContent
              exploration={
                exploration.status === "success"
                  ? exploration.data
                  : exploration.cache!
              }
              initialQuery={this.getInitialQuery()}
              getCurrentQuery={persistQuery}
              renderRoute={this.props.renderRoute}
              initialQueryLoad={this.getInitialQueryLoad()}
              isEmbeded={this.props.isEmbeded}
            />
          </ExplorationVersionWatcher>
        );
    }
  };

  public render() {
    return (
      <div ref={this.ref} className="exploration-wrapper">
        {this.renderContent()}
      </div>
    );
  }
}

export default compose<Props, IExplorationEditorProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(ExplorationEditor);
