import {
  AppstoreOutlined,
  DownOutlined,
  FileMarkdownOutlined,
  FormOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, Tree, message } from "antd";
import type { DataNode } from "antd/es/tree";
import cuid from "cuid";
import { Base64 } from "js-base64";
import _ from "lodash";
import * as React from "react";
import { ResizableBox } from "react-resizable";
import { snakeCase } from "snake-case";
import type { SqlLanguage } from "sql-formatter";
import { format } from "sql-formatter";
import "../../../../../../../node_modules/react-resizable/css/styles.css";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { HeightSizer } from "../../../../../../components/height-sizer/HeightSizer";
import { SplitView } from "../../../../../../components/resizable/SplitView";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import { IOrgFeatureType } from "../../../../../../interfaces/org";
import type { IDataset } from "../../../../../../interfaces/sources";
import type {
  SchemaResult,
  Transformation,
} from "../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../services/BrizoService";
import workbenchUIStore from "../../../../../../store/workbenchUIStore";
import { arrayToTree } from "../../../../../../utils/arrayToTree";
import { isMacintosh } from "../../../../../../utils/isMacinthosh";
import { generateUniqueId } from "../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import TypeRenderer from "../../../../../spreadsheet/renderer/TypeRenderer";
import type {
  IActiveObjectSQLConfigUIState,
  IDatasetUpdate,
} from "../../../domain";
import { DEFAULT_WORKBENCH_RECORD_NUMBER } from "../../../domain";
import type { DatasetSavedData } from "../../../selector/tables/models/AddModelModal";
import "./SQLConfiguration.scss";
import { SQLEditor } from "./SQLEditor";
import SQLResults from "./SQLResults";
import SQLToolbar from "./SQLToolbar";
import type { SQLAutocompleteItem } from "./codemirrorPlugins/sqlAutocomplete";

interface ISQLConfigurationProps {
  uniqueId: string; // used to reset the state of the sql editor (use something like <name of the object>-<id>)
  query: string;
  currentDataset?: IDataset;
  datasets: IDataset[];
  staleQuery?: string;
  initialState: IActiveObjectSQLConfigUIState;
  onUpdateDataset?: IDatasetUpdate;
  onCreateDataset?: (datasetData: DatasetSavedData) => Promise<any>;
  setStaleQuery?: (q: string) => void;
  onRefreshDatasets?: (id?: string[]) => Promise<void>;
  onRefreshExplorations?: (id?: string[]) => Promise<void>;
  saveUIState: (state: IActiveObjectSQLConfigUIState) => void;
  onDelete?: () => Promise<any>;
  currentWarehouse: IDestination;
}

interface IState {
  dataExplorerIsOpen: boolean;
  dataExplorerOpenKeys: string[];
  dataExplorerHoveredkey: string;
  highlightedAutocompleteValue: string;
  lastSavedQuery: string;
  lastSuccessfulQuery: string;
  autocompleteValues: SQLAutocompleteItem[];
  height: number;
  showMigrationModal: boolean;
  maxHeight: number;
  data: AsyncData<{
    records: any;
    count: number;
    schema: SchemaResult;
  }>;
  overrideEditorValue: string | undefined;
}

type Props = ISQLConfigurationProps & InjectedOrgProps;

interface ExplorerDataNode extends DataNode {
  parentKey?: string;
}

const SQL_LEGACY_MATCH_GLOBAL = /\$\{TABLES\["(.+?)"]\["(.+?)"]\}/g;
const SQL_SOURCE_MATCH_GLOBAL = /\$\{DATASETS\["(.+?)"]\}/g;
const SQL_RAW_MATCH_GLOBAL = /\$\{RAW\["(.+?)"]\}/g;

class SQLConfiguration extends React.Component<Props, IState> {
  id = cuid();
  dataExplorerRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.state = {
      dataExplorerHoveredkey: null,
      dataExplorerIsOpen: props.initialState?.configDataExplorerIsOpen ?? false,
      dataExplorerOpenKeys: [],
      highlightedAutocompleteValue: null,
      autocompleteValues: this.initAutocomplete(),
      data: props.initialState?.configResultsData ?? {
        status: "initial",
      },
      lastSavedQuery:
        props.initialState?.configEditorLastSavedQuery ?? props.query,
      lastSuccessfulQuery:
        props.initialState?.configEditorLastSuccessfulQuery ?? props.query,
      height: props.initialState?.configEditorHeight ?? 250,
      maxHeight: props.initialState?.configEditorMaxHeight ?? 500,
      showMigrationModal: false,
      overrideEditorValue: undefined,
    };
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.dataExplorerRef = React.createRef();
  }

  onKeyPressed(e: KeyboardEvent) {
    if (
      (isMacintosh() ? e.metaKey : e.ctrlKey) &&
      e.key?.toLowerCase() === "enter"
    ) {
      e.preventDefault();
      this.runQuery(this.props.staleQuery);
    }
    if (
      (isMacintosh() ? e.metaKey : e.ctrlKey) &&
      e.shiftKey &&
      e.key?.toLowerCase() === "f"
    ) {
      e.preventDefault();
      this.formatQuery(this.props.staleQuery);
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPressed, true);
    this.getElementSize();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPressed, true);
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState): boolean {
    const { datasets, query, currentDataset, staleQuery, uniqueId } =
      this.props;
    const {
      datasets: nextDatasets,
      query: nextQuery,
      currentDataset: nextCurrentDataset,
      staleQuery: nextStaleQuery,
      uniqueId: nextUniqueId,
    } = nextProps;
    if (
      !_.isEqual(datasets, nextDatasets) ||
      !_.isEqual(query, nextQuery) ||
      !_.isEqual(currentDataset, nextCurrentDataset) ||
      !_.isEqual(staleQuery, nextStaleQuery) ||
      !_.isEqual(this.state, nextState) ||
      !_.isEqual(uniqueId, nextUniqueId)
    ) {
      return true;
    }
    return false;
  }

  debouncedSaveUIState = _.debounce(this.props.saveUIState, 100);

  componentDidUpdate(prevProps: Readonly<Props>, prevState: IState): void {
    if (
      this.props.uniqueId !== prevProps.uniqueId ||
      this.props.currentDataset?.id !== prevProps.currentDataset?.id
    ) {
      this.setState(this.resetState());
    }
    const uiState: IActiveObjectSQLConfigUIState = {
      configDataExplorerIsOpen: this.state.dataExplorerIsOpen,
      configEditorHeight: this.state.height,
      configEditorMaxHeight: this.state.maxHeight,
      configEditorLastSavedQuery: this.state.lastSavedQuery,
      configEditorLastSuccessfulQuery: this.state.lastSuccessfulQuery,
      configResultsData: ["success", "error"].includes(this.state.data.status)
        ? this.state.data
        : { status: "initial" },
    };
    this.debouncedSaveUIState(uiState);
    this.getElementSize();
  }

  onLoadData = (dataNode: ExplorerDataNode) =>
    new Promise<void>(async (resolve, reject) => {
      if (
        (dataNode.key as string).startsWith("folder-source") ||
        (dataNode.key as string).startsWith("wly.models") ||
        dataNode.children?.length > 0
      ) {
        resolve();
        return;
      } else {
        try {
          const dataset = this.props.datasets.find(
            (d) => d.id === (dataNode.key as string).split("-").pop()
          );
          const columns = await this.getSchemaForDataset(
            dataset,
            (dataNode.key as string).startsWith("raw-") ? true : false
          );
          const columnNames = Object.keys(columns.data.schema);
          const columnsDefs = columnNames.map((cn) => ({
            name: cn,
            type: columns.data.schema[cn].domain,
          }));
          this.setState((prevState) => ({
            autocompleteValues: prevState.autocompleteValues.map((av) => {
              if (av.id === dataNode.key) {
                return {
                  ...av,
                  columns: columnsDefs?.length ? columnsDefs : null,
                };
              } else {
                return av;
              }
            }),
          }));
          resolve();
          return;
        } catch (error) {
          console.error(error);
          message.error("Error while fetching schema", 2);
          reject();
          return;
        }
      }
    });

  renderDataExplorer = () => {
    const { datasets } = this.props;
    const { autocompleteValues } = this.state;

    const treeData: ExplorerDataNode[] = [
      {
        key: "wly.models",
        icon: <FileMarkdownOutlined />,
        title: "models",
        parentKey: null,
      },
      ...autocompleteValues
        .filter((av) => av.columns?.length > 0)
        .flatMap((av) =>
          av.columns.map((c) => ({
            key: av.isModel
              ? `column-${c.name}-source-${av.datasetId}`
              : `column-${c.name}-raw-${av.datasetId}`,
            icon: <TypeRenderer domain={c.type as any} />,
            title: c.name,
            isLeaf: true,
            parentKey: av.isModel
              ? `source-${av.datasetId}`
              : `raw-${av.datasetId}`,
          }))
        ),
      ...datasets
        .filter((d) => d.id !== this.props.currentDataset?.id)
        .map((d) => ({
          key: d.isModel ? `source-${d.id}` : `raw-${d.id}`,
          title: snakeCase(d.name),
          icon: <TableOutlined />,
          parentKey: d.isModel ? "wly.models" : `folder-source-${d.source.id}`,
        })),
      ..._.uniqBy(
        datasets
          .filter((d) => !!d.source?.id && !!d.source?.name)
          .sort((a, b) => snakeCase(a.source.name).localeCompare(b.source.name))
          .map((d) => ({
            key: `folder-source-${d.source.id}`,
            title: snakeCase(d.source.name),
            icon: <AppstoreOutlined />,
            parentKey: null,
          })),
        "key"
      ),
    ];

    return (
      <HeightSizer>
        <Tree<ExplorerDataNode>
          virtual
          ref={this.dataExplorerRef}
          className="data-explorer-tree"
          expandedKeys={this.state.dataExplorerOpenKeys}
          autoExpandParent
          onExpand={(keys, info) => {
            if (info.expanded === false) {
              // auto expand parent does not allow closing a node with opened children keys
              const newKeys = keys.filter(
                (k) =>
                  treeData.find((td) => td.key === k).parentKey !==
                  info.node.key
              );
              this.setState({
                dataExplorerOpenKeys: newKeys as string[],
              });
            } else {
              this.setState({
                dataExplorerOpenKeys: keys as string[],
              });
            }
          }}
          treeData={
            arrayToTree(treeData, {
              dataField: null,
              id: "key",
              parentId: "parentKey",
            }) as ExplorerDataNode[]
          }
          loadData={this.onLoadData}
          showLine
          switcherIcon={<DownOutlined />}
          titleRender={(node) => {
            return (
              <div
                id={node.key as string}
                className="data-explorer-tree-title"
                onMouseEnter={() =>
                  this.setState({ dataExplorerHoveredkey: node.key as string })
                }
                onMouseLeave={() =>
                  this.setState({ dataExplorerHoveredkey: null })
                }
              >
                <div className="data-explorer-tree-title-item-name">
                  <div className="data-explorer-tree-title-item-name-inner">
                    <div className="data-explorer-tree-title-item-name-inner-image">
                      <>{node.icon}</>
                    </div>
                    <div className="data-explorer-tree-title-item-name-inner-text">
                      <>{node.title}</>
                    </div>
                    {((node.key as string).startsWith("raw-") ||
                      (node.key as string).startsWith("source-")) && (
                      <div
                        className="data-explorer-tree-title-item-name-inner-actions"
                        hidden={
                          this.state.dataExplorerHoveredkey !== node.key &&
                          !this.state.dataExplorerOpenKeys.includes(
                            node.key as any
                          )
                        }
                      >
                        <Tooltip title={"Open"}>
                          <Button
                            type="text"
                            shape="circle"
                            size="small"
                            icon={<FormOutlined style={{ fontSize: 10 }} />}
                            onClick={() =>
                              workbenchUIStore.pushActiveObject({
                                type: "dataset",
                                value: (node.key as string).split("-").pop(),
                              })
                            }
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }}
          selectable={false}
          rootStyle={{ width: "100%" }}
          blockNode
        />
      </HeightSizer>
    );
  };

  resetState = (): IState => {
    const { initialState } = this.props;
    return {
      dataExplorerHoveredkey: null,
      dataExplorerIsOpen: initialState?.configDataExplorerIsOpen ?? false,
      dataExplorerOpenKeys: [],
      highlightedAutocompleteValue: null,
      autocompleteValues: this.initAutocomplete(),
      lastSavedQuery:
        initialState?.configEditorLastSavedQuery ?? this.props.query,
      lastSuccessfulQuery:
        initialState?.configEditorLastSuccessfulQuery ?? this.props.query,
      data: initialState?.configResultsData ?? { status: "initial" },
      height: initialState?.configEditorHeight ?? this.state.height ?? 250,
      maxHeight:
        initialState?.configEditorMaxHeight ?? this.state.maxHeight ?? 500,
      showMigrationModal: false,
      overrideEditorValue: this.state.overrideEditorValue,
    };
  };

  getElementSize = () => {
    const el = document.getElementById(this.id);
    if (el && this.props.currentDataset?.managedBy !== "WHALY") {
      const r = el.getBoundingClientRect();
      if (this.state.maxHeight !== r.height) {
        this.setState({
          maxHeight: r.height,
        });
      }
    }
  };

  initAutocomplete = (): SQLAutocompleteItem[] => {
    const { currentDataset, datasets, orgFeatures } = this.props;
    const items = [
      ...datasets
        .filter((t) => t.id !== currentDataset?.id)
        .map((dataset) => {
          return {
            label: `${
              dataset?.name
                ? dataset.isModel
                  ? `${dataset?.name}`
                  : `${
                      dataset.source?.name ? `${dataset.source?.name} · ` : ""
                    }${dataset.name} (from source)`
                : "Not found"
            }`,
            apply: `\${DATASETS["${dataset?.id}"]}`,
            type: "table",
            icon: dataset?.source?.sourceMeta?.publicInfo?.logo,
            id: `source-${dataset?.id}`,
            datasetId: dataset.id,
            columns: [],
            isUsed: false,
            whalyType: "source" as any,
            isModel: dataset?.isModel,
            description: dataset?.description,
          };
        }),
      ...datasets
        .filter((t) => t.id !== currentDataset?.id && t.isModel !== true)
        .map((dataset) => {
          return {
            label: `${
              dataset?.name
                ? `${dataset.source?.name ? `${dataset.source?.name} · ` : ""}${
                    dataset.name
                  }`
                : "Not found"
            }`,
            apply: `\${RAW["${dataset?.id}"]}`,
            type: "table",
            icon: dataset?.source?.sourceMeta?.publicInfo?.logo,
            id: `raw-${dataset?.id}`,
            columns: [],
            isUsed: false,
            whalyType: "raw" as any,
            datasetId: dataset.id,
            isModel: dataset?.isModel,
            description: dataset?.description,
          };
        }),
    ].filter((v) => {
      // we show only raw datasets to "new workbench" customers
      if (!orgFeatures.includes(IOrgFeatureType.OLD_WORKBENCH)) {
        return v.whalyType === "raw" || v.isModel === true;
      } else {
        return true;
      }
    });
    setTimeout(() => {
      this.updateAutocomplete();
    }, 250);
    return items;
  };

  updateAutocomplete = async () => {
    // prendre la stale query
    // tagguer toutes les autocompleteValues qui sont inclus dans la staleQuery en isUsed
    // si la suggestion n'a pas de columns, aller chercher les colonnes de cette suggestion
    // mettre à jour les suggestions

    const currentQuery = this.props.staleQuery || "";

    const legacyDatasetsReferences = currentQuery.match(
      SQL_LEGACY_MATCH_GLOBAL
    );
    const souceDatasetsReferences = currentQuery.match(SQL_SOURCE_MATCH_GLOBAL);
    const rawDatasetsReferences = currentQuery.match(SQL_RAW_MATCH_GLOBAL);

    const allDatasetsReferences = _.uniq(
      _.concat(
        legacyDatasetsReferences ?? [],
        souceDatasetsReferences ?? [],
        rawDatasetsReferences ?? []
      )
    );

    const autocompleteCopy = await Promise.all([
      ...this.state.autocompleteValues.map(async (v) => {
        if (!allDatasetsReferences.includes(v.apply)) {
          return {
            ...v,
            isUsed: false,
          };
        } else if (v.columns?.length === 0) {
          const dataset = this.props.datasets.find((d) => d.id === v.datasetId);
          const columns = await this.getSchemaForDataset(
            dataset,
            v.whalyType === "raw"
          );
          const columnNames = Object.keys(columns.data.schema);
          const columnsDefs = columnNames.map((cn) => ({
            name: cn,
            type: columns.data.schema[cn].domain,
          }));
          return {
            ...v,
            isUsed: true,
            columns: columnsDefs,
          };
        } else {
          return {
            ...v,
            isUsed: true,
          };
        }
      }),
    ]);

    if (!_.isEqual(autocompleteCopy, this.state.autocompleteValues)) {
      this.setState({
        autocompleteValues: autocompleteCopy,
      });
    }
  };

  getSchemaForDataset = async (dataset: IDataset, getRaw: boolean = false) => {
    const { currentWarehouse } = this.props;
    const prevId = generateUniqueId();
    const operation: Transformation = getRaw
      ? {
          var: prevId,
          domain: "dataset",
          operation: {
            type: "Table.FromWarehouseTable",
            args: {
              databaseName: dataset.warehouseDatabaseId,
              schemaName: dataset.warehouseSchemaId,
              tableName: dataset.warehouseTableId,
            },
          },
        }
      : {
          var: prevId,
          domain: "dataset",
          operation: {
            type: "Table.FromWhalyDataset",
            args: {
              datasetId: dataset.id,
            },
          },
        };
    return computeTransformations(currentWarehouse.id, {
      schema: [
        operation,
        {
          var: generateUniqueId(),
          operation: {
            type: "Table.Schema",
            args: {
              table: prevId,
            },
          },
          domain: "viewResolver",
        },
      ],
    });
  };

  getEditorMaxHeight = () => {
    const maxHeight =
      document
        .getElementsByClassName("sql-dataset-wrapper")[0]
        .getBoundingClientRect().height - 121;
    return maxHeight;
  };

  onEditorResize = (event, { element, size, handle }) => {
    this.setState({ maxHeight: this.getEditorMaxHeight() });
    this.setState({ height: size.height });
    window.dispatchEvent(new Event("resize"));
  };

  runQuery = (query: string) => {
    const { currentWarehouse } = this.props;
    this.setState({ data: { status: "loading" } });
    const prevId = generateUniqueId();
    const currentSQL: Transformation = {
      var: prevId,
      operation: {
        type: "SQL.Database",
        args: {
          query: query,
        },
      },
      domain: "viewResolver",
    };
    return computeTransformations(currentWarehouse.id, {
      schema: [
        currentSQL,
        {
          var: generateUniqueId(),
          operation: {
            type: "Table.Schema",
            args: {
              table: prevId,
            },
          },
          domain: "viewResolver",
        },
      ],
      count: [
        currentSQL,
        {
          var: generateUniqueId(),
          operation: {
            type: "Table.RowCount",
            args: {
              table: prevId,
            },
          },
          domain: "viewResolver",
        },
      ],
      records: [
        currentSQL,
        {
          var: generateUniqueId(),
          operation: {
            type: "Table.FirstN",
            args: {
              table: prevId,
              countOrCondition: DEFAULT_WORKBENCH_RECORD_NUMBER,
            },
          } as any,
          domain: "viewResolver",
        },
      ],
    })
      .then((r) => {
        this.setState({
          data: {
            status: "success",
            data: {
              records: r.data.records,
              count: r.data.count as number,
              schema: r.data.schema as SchemaResult,
            },
          },
          lastSuccessfulQuery: query,
        });
      })
      .catch((err) => {
        this.setState({ data: { status: "error", error: err } });
      });
  };

  formatQuery = (currentQuery: string) => {
    const { currentWarehouse } = this.props;
    const warehouseType = currentWarehouse?.destinationMeta?.warehouseType;

    if (!currentQuery) return;

    // we will replace all the variables in using quoted param types
    // https://github.com/sql-formatter-org/sql-formatter/wiki/identifiers
    const encodeDecodeLanguageMap = {
      sql: {
        encode: (match) => {
          return `$\`${Base64.encode(match, true)}\``;
        },
        // matches $`123GJSJ`
        matchDecode: /\$`(.*?)`/g,
        decode: (match, p1) => {
          return Base64.decode(p1);
        },
      },
      bigquery: {
        encode: (match) => {
          return `$\`${Base64.encode(match, true)}\``;
        },
        // matches $`123GJSJ`
        matchDecode: /\$`(.*?)`/g,
        decode: (match, p1) => {
          return Base64.decode(p1);
        },
      },
      postgresql: {
        encode: (match) => {
          return `$"${Base64.encode(match, true)}"`;
        },
        // matches $"123GJSJ"
        matchDecode: /\$"(.*?)"/g,
        decode: (match, p1) => {
          return Base64.decode(p1);
        },
      },
      snowflake: {
        encode: (match) => {
          return `$"${Base64.encode(match, true)}"`;
        },
        // matches $"123GJSJ"
        matchDecode: /\$"(.*?)"/g,
        decode: (match, p1) => {
          return Base64.decode(p1);
        },
      },
    };

    try {
      let lang: SqlLanguage = "sql";
      let formattedQuery: string = currentQuery;
      if (warehouseType === "snowflake") {
        lang = "snowflake";
      } else if (warehouseType === "bigquery") {
        lang = "bigquery";
      } else if (warehouseType === "postgres") {
        lang = "postgresql";
      }

      const newQuery = currentQuery
        .replace(SQL_LEGACY_MATCH_GLOBAL, encodeDecodeLanguageMap[lang].encode)
        .replace(SQL_RAW_MATCH_GLOBAL, encodeDecodeLanguageMap[lang].encode)
        .replace(SQL_SOURCE_MATCH_GLOBAL, encodeDecodeLanguageMap[lang].encode);

      formattedQuery = format(newQuery, {
        language: lang,
        keywordCase: "preserve",
        paramTypes: {
          quoted: ["$"],
        },
      }).replace(
        encodeDecodeLanguageMap[lang].matchDecode,
        encodeDecodeLanguageMap[lang].decode
      );

      this.props.setStaleQuery?.(formattedQuery);
      this.setState({ overrideEditorValue: formattedQuery });
    } catch (error) {
      message.warning("Unable to format query", 2);
      console.warn(error);
    }
  };

  getSQLVariables = (): SQLAutocompleteItem[] => {
    const { datasets, currentDataset } = this.props;
    const { autocompleteValues } = this.state;
    return [
      ...autocompleteValues,
      ...datasets
        .filter((t) => t.id !== currentDataset?.id)
        .map((dataset) => {
          return {
            label: `${
              dataset?.name
                ? `${dataset.source?.name ? `${dataset.source?.name}.` : ""}${
                    dataset.name
                  }`
                : "Not found"
            }`,
            apply: `\${TABLES["${dataset.source?.name}"]["${dataset.name}"]}`,
            type: "table",
            icon: dataset?.source?.sourceMeta?.publicInfo?.logo,
            id: `table-${dataset?.id}`,
            datasetId: dataset.id,
            tables: [],
            isUsed: false,
            whalyType: "source" as any,
            isModel: dataset?.isModel,
          };
        }),
    ];
  };

  renderToolbar = () => {
    const {
      currentDataset,
      datasets,
      staleQuery,
      onRefreshDatasets,
      onRefreshExplorations,
      onUpdateDataset,
      onCreateDataset,
      onDelete,
      currentWarehouse,
    } = this.props;
    const { data, lastSuccessfulQuery, showMigrationModal } = this.state;

    const highlightRunQuery =
      staleQuery !== lastSuccessfulQuery && data.status !== "loading";

    const schemaDef = data.status === "success" ? data.data.schema : {};

    const setMigrationModalOpen = (open: boolean) =>
      this.setState({ showMigrationModal: open });

    const onMigrationDone = async (
      nextDatasetId: string,
      datasetIds: string[],
      explorationIds: string[]
    ) => {
      await Promise.all([
        onRefreshDatasets(datasetIds),
        onRefreshExplorations(explorationIds),
      ]);

      const activeObject = workbenchUIStore.getActiveObject();
      workbenchUIStore.removeActiveObject(activeObject);
      workbenchUIStore.pushActiveObject({
        type: "dataset",
        value: nextDatasetId,
      });
    };

    const onSaveQuery = (primaryKey: string[]) => {
      this.setState({ lastSavedQuery: this.state.lastSuccessfulQuery });
      return onUpdateDataset({
        sql: lastSuccessfulQuery,
        primaryKey: primaryKey.join(","),
      });
    };

    const onCreateQuery = (
      name: string,
      primaryKey: string[],
      shouldDelete?: boolean
    ) => {
      this.setState({ lastSavedQuery: this.state.lastSuccessfulQuery });
      return onCreateDataset({
        name: name,
        sql: lastSuccessfulQuery,
        isModel: true,
        type: "SQL",
        primaryKeys: primaryKey,
      }).then(() => {
        if (onDelete && shouldDelete) {
          return onDelete();
        }
      });
    };

    return (
      <SQLToolbar
        datasets={datasets}
        currentDataset={currentDataset}
        highlightRunQuery={highlightRunQuery}
        isMigrationModalOpen={showMigrationModal}
        onMigrationDone={onMigrationDone}
        setMigrationModalOpen={setMigrationModalOpen}
        onFormatQuery={() => this.formatQuery(staleQuery)}
        onRunQuery={() => this.runQuery(staleQuery)}
        onDelete={onDelete}
        currentWarehouse={currentWarehouse}
        canUpdateModel={
          data.status === "success" &&
          this.props.staleQuery === lastSuccessfulQuery
        }
        onCreateDataset={
          !currentDataset && onCreateDataset ? onCreateQuery : undefined
        }
        updateModel={onUpdateDataset ? onSaveQuery : undefined}
        schema={schemaDef}
        isDataExplorerOpen={this.state.dataExplorerIsOpen}
        onToggleDataExplorer={() =>
          this.setState((prevState) => ({
            dataExplorerIsOpen: !prevState.dataExplorerIsOpen,
          }))
        }
      />
    );
  };

  renderResults = () => {
    const { initialState } = this.props;
    const { data } = this.state;

    const schemaDef = data.status === "success" ? data.data.schema : {};
    const rowCount =
      data.status === "success" ? data.data.records.length : undefined;

    return (
      <SQLResults
        status={data.status}
        schema={schemaDef}
        count={rowCount}
        records={data.status === "success" ? data.data.records : undefined}
        error={
          data.status === "error"
            ? ((data.error as any).message as string)
            : undefined
        }
        loading={{
          schema: data.status === "loading",
          records: data.status === "loading",
          count: data.status === "loading",
        }}
        initialScrollLeft={initialState?.configResultsScrollLeft}
        initialScrollTop={initialState?.configResultsScrollTop}
        onScroll={(scrollLeft, scrollTop) => {
          this.debouncedSaveUIState({
            configResultsScrollLeft: scrollLeft,
            configResultsScrollTop: scrollTop,
          });
        }}
      />
    );
  };

  renderSQLEditor = () => {
    const {
      currentDataset,
      initialState,
      onUpdateDataset,
      uniqueId,
      currentWarehouse,
    } = this.props;

    const warehouseType = currentWarehouse?.destinationMeta?.warehouseType;

    const onDatasetClick = (apply) => {
      const av = this.state.autocompleteValues.find((av) => av.apply === apply);
      if (!av) return;
      this.setState(
        {
          dataExplorerIsOpen: true,
          dataExplorerOpenKeys: [av.id],
        },
        () =>
          setTimeout(() => {
            this.dataExplorerRef?.current?.scrollTo?.({
              key: av.id,
              align: "top",
            });
          }, 200)
      );
    };

    return (
      <ResizableBox
        axis="y"
        height={this.state.height}
        width={Infinity}
        minConstraints={[Infinity, 0]}
        maxConstraints={[Infinity, this.state.maxHeight]}
        handle={
          currentDataset?.managedBy === "DBT_CLOUD" ? null : (
            <div className="divider-horizontal"></div>
          )
        }
        onResize={this.onEditorResize}
      >
        <SQLEditor
          key={uniqueId} // remounts SQL editor when dataset changes
          value={this.props.staleQuery || ""}
          overrideValue={this.state.overrideEditorValue}
          height={
            currentDataset?.managedBy === "DBT_CLOUD"
              ? this.state.maxHeight
              : this.state.height
          }
          autocompleteValues={this.state.autocompleteValues}
          sqlVariables={this.getSQLVariables()}
          errorData={this.state.data}
          initialState={initialState?.configEditorInitialState}
          readOnly={
            !onUpdateDataset || currentDataset?.managedBy === "DBT_CLOUD"
          }
          onEditorStateChange={(stateUpdate) => {
            this.debouncedSaveUIState({
              configEditorInitialState: stateUpdate,
            });
          }}
          onChange={(value) => {
            this.props.setStaleQuery?.(value);
            this.updateAutocomplete();
          }}
          warehouseType={warehouseType}
          onDatasetClick={onDatasetClick}
          initialScrollLeft={initialState?.configEditorScrollLeft}
          initialScrollTop={initialState?.configEditorScrollTop}
          onEditorScroll={(scrollTop, scrollLeft) => {
            this.debouncedSaveUIState({
              configEditorScrollTop: scrollTop,
              configEditorScrollLeft: scrollLeft,
            });
          }}
        />
      </ResizableBox>
    );
  };

  public render() {
    const { currentDataset } = this.props;

    return (
      <div
        style={{ display: "flex", flex: "1", height: "100%", minHeight: 0 }}
        id={this.id}
      >
        <SplitView
          mainClassName="sql-dataset-wrapper"
          compact={true}
          collapsed={!this.state.dataExplorerIsOpen}
          collapsedWidth={0}
          minWidth={150}
          startWidth={280}
          maxWidth={500}
          alignement={"LTR"}
          leftClassName="sql-data-explorer"
          left={
            <>
              <div className="data-explorer-title">Explorer</div>
              <div className="data-explorer-content">
                {this.renderDataExplorer()}
              </div>
            </>
          }
          rightClassName="sql-editor-wrapper"
          right={
            <>
              <div
                className={`sql-editor ${
                  currentDataset?.managedBy === "DBT_CLOUD" ? "full" : ""
                }`}
                style={{
                  height:
                    currentDataset?.managedBy === "DBT_CLOUD"
                      ? "100%"
                      : undefined,
                }}
              >
                {this.renderToolbar()}
                {this.renderSQLEditor()}
              </div>
              {currentDataset?.managedBy !== "DBT_CLOUD" && (
                <div className="spreadsheet">{this.renderResults()}</div>
              )}
            </>
          }
        />
      </div>
    );
  }
}

export default compose<Props, ISQLConfigurationProps>(WithOrg)(
  SQLConfiguration
);
